import React, { useContext, useEffect, useState, useRef } from 'react';
import { CheckBox, Box, Button, Layer, Text, Header, Heading, ResponsiveContext, Grommet, FileInput, Form, FormField, CheckBoxGroup } from 'grommet';
import { useTheme } from '../contexts/ThemeContext';


const DeleteFileDialog = React.forwardRef((props, ref) => {

    const [open, setOpen] = useState(false);
    const { theme } = useTheme();

    useEffect(() => {
        setOpen(props.isDeleteOpen);
    }, [props.isDeleteOpen]);

    const onClose = () => {
        setOpen(false);
        props.setDelete()
    };

    const handleDeleteClick = () => {
        props.deleteFile();
        onClose();
    }

    const checkBoxOptions = props.userSessionFiles?.map((fileName) => fileName); // Create a configuration object for CheckBoxGroup options


    return (
        <Grommet>
            {open && (
                // Theme class can share settings pane for now.
                <Layer className={`${theme} settingPane`} onClickOutside={onClose} onEsc={onClose}>
                    <Header
                        direction="column"
                        align="start"
                        gap="xxsmall"
                        pad={{ horizontal: '14px' }}>
                        <Heading style={{ fontSize: 24 }} size="small" margin="none">
                            Manage Session Files
                        </Heading>
                        <Text style={{ fontSize: 18 }} size='xsmall'>Select files you wish to remove from this chat session</Text>
                        <Text style={{ fontSize: 16 }} size='xsmall'>Note: Deleting these file will require ChatHPE to reprocess files</Text>
                        <Text style={{ fontSize: 16 }} size='xsmall'>which may take some time.</Text>
                    </Header>
                    <Box fill align="center" justify="start" pad="small">
                        <Box width="medium">
                            <Form>
                                <FormField name="fileInput" htmlFor="fileInput">
                                    <Box pad="medium">
                                        <CheckBoxGroup options={checkBoxOptions}
                                        onChange={(selected) => {
                                            props.setFilesForDelete(selected.value)
                                        }}/>
                                    </Box>
                                </FormField>    
                                <Button onClick={handleDeleteClick} style={{ background: '#01A982', border: 'none', fontSize: '18px', fontWeight: 'Bold', color: '#fff' }} label="Delete selected file(s)" />
                                <Button onClick={onClose} style={{ border: 'none', fontSize: '16px', fontWeight: 'Bold', color: '#111' }} label="Cancel" />
                            </Form>
                        </Box>
                        <Box>
                        </Box>
                    </Box>
                </Layer>
            )}
        </Grommet>
    );
});

export default DeleteFileDialog; 