    import React, { useRef, useState } from 'react';
import { css } from 'styled-components';
import {
    DownloadOption, Moon, Hpe, Menu, Send, Down, Attachment, More, Upload, Catalog, Sidebar, Test, Cpu, Trash, System, Close, LinkBottom, Checkmark, Refresh, Update, Favorite, ShareOption, Logout, HelpOption, Secure, Support, Compliance, FastForward,
    Bookmark, StatusWarning
} from 'grommet-icons';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import { Box, Button, Tip, Collapsible, Heading, Text, Grommet, Footer, Meter, CheckBox, TextArea, PageHeader, RadioButtonGroup, Anchor, Select, Tag, Notification, Divider, Layer, DropButton, Card, CardHeader, CardBody, CardFooter } from 'grommet';
import SessionList from './SessionList';
import StatusNotification from './Notification';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import LoadingIcon from '../dialog/LoadingIcon';
import ProgressBar from '../dialog/ProgressBar';
import ProcessingIcon from '../dialog/ProcessingIcon';
import { useTheme } from '../contexts/ThemeContext';
import Agreement from '../dialog/Agreement';
import * as constClass from '../Constants';
import useRegenerateToken from '../components/RenegerateToken';
import { checkTokenExpired } from '../service/TokenService';
import { Link } from 'react-router-dom'
import FileUploadDialog from './FileUploadDialog';
import DeleteFileDialog from './DeleteFileDialog';
import EmbeddingIcon from '../dialog/EmbeddingIcon';
import axios from 'axios';
import DefaultUserImg from '../defaultpromptimg.jpg';
import { CustomToggle } from './Toggle';
import { HPEToggleSwitchTheme, checkboxCheckStyle } from '../theme/HPEToggleSwitchTheme';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';
// TEMP REMOVAL OF 30 SEC TIMEOUT
// import { fetchWithRetry } from '../service/FetchWithRetry';

export const Horizontal = (props) => {
    let location = useLocation();
    const navigate = useNavigate();

    const { getNewToken } = useRegenerateToken();
    const [data, setData] = useState(location.state);
    const [openNotification, setOpenNotification] = useState(true);
    const [getChatHistory, setChatHistory] = useState([]);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [botResponse, setBotResponse] = useState(false);
    const [yoffset, setYoffset] = useState(45);//Changed from 50 to 0. 
    const [firstNewItemActive, setFirstNewItemActive] = useState(false);
    const spinnerRef = useRef();
    const processingSpinnerRef = useRef();
    const embeddingSprinnerRef = useRef();
    const AgreementRef = useRef();
    const scrollableDivRef = useRef();
    const activeSessionRef = useRef();
    const textareaRef = useRef();
    const [agreementVal, setAgreementVal] = useState(data);
    const [linkDisable, setLinkVisibility] = useState(agreementVal);
    const accessToken = localStorage.getItem('accessToken');
    const [chatBotData, setBotData] = useState(data.chatHPE_bot_data);
    const [isScrollable, setIsScrollable] = useState(false);
    const [profilePic, setProfilePic] = React.useState(localStorage.getItem('profilePicSetting') || 'Profile Picture');
    const [initials, setInitials] = useState('');
    const [isUploadOpen, setIsUploadOpen] = useState(false);
    const [isDeleteOpen, setDeleteOpen] = useState(false)
    const [appId, setAppId] = useState(localStorage.getItem('appIdValue') || '1');
    const [fileNames, setFileNames] = useState([]);
    const [isFileUploadInProgress, setFileUploadInProgress] = useState(false);
    const [savedFiles, setSavedFiles] = useState([]);
    const [userSessionFiles, setUserSessionFiles] = useState([]);
    const [filesForDelete, setFilesForDelete] = useState([]);
    const [busy, setBusy] = useState(false);
    const [success, setSuccess] = useState(false);
    const [progressPercentage, setProgresspercentage] = useState(0)
    const [progressBarType, setProgressBarType] = useState('')
    const progressBarRef = useRef();
    const uploading = (e) => progressBarRef.current.ProcessingStart(e);
    //Hooks for Quota
    const [totalAllowedFileSize, setTotalAllowedFileSize] = useState();
    const [totalFilesAllowed, setTotalFilesAllowed] = useState();

    // Hooks for buttons visibility
    // Start change for ChatHPE-249 - 2/9/2024
    const [isTextBarDisabled, setTextBarDisabled] = useState(false);
    const [isAttachButtonDisabled, setAttachButtonDisabled] = useState(false);
    const [isProcessButtonDisabled, setProcessButtonDisabled] = useState(true);
    const [isEmbedButtonDisabled, setEmbedButtonDisabled] = useState(true);
    const [isDeleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
    const [isSummarizeButtonDisabled, setSummarizeButtonDisabled] = useState(true);
    const [isSendPromptButtonDisabled, setSendPromptButtonDisabled] = useState(false);
    const [isSendPromptButtonVisable, setPromptButtonVisable] = useState(true);
    const [isActionBarVisible, setIsActionBarVisible] = useState(false);
    // End change for ChatHPE-249 - 2/9/2024
    const [isRegenerateButtonVisable, setRegenerateButtonVisable] = useState(false);
    const [isTokenLimitMeterVisable, setTokenLimitMeterVisable] = useState(false);

    //Hooks for Notifications
    const [uploadSuccessNotification, setUploadSuccessNotification] = useState(false);
    const [uploadFailureNotification, setUploadFailureNotification] = useState(false);
    const [processSuccessNotification, setprocessSuccessNotification] = useState(false);
    const [processFailureNotification, setProcessFailureNotification] = useState(false);
    const [deleteNotification, setDeleteNotification] = useState(false);

    const [uploadSuccessFiles, setUploadSuccessFiles] = useState([]);
    const [uploadFailedFiles, setUploadFailedFiles] = useState([])
    const [processFailedFiles, setProcessFailedFiles] = useState([]);
    const [isProceedDisabled, setIsProceedDisabled] = useState(false);
    const [uploadFailedDialogShowed, setUploadFailedDialogShowed] = useState(false);

    const [successNotification, setSuccessNotification] = useState(false);
    const [failureNotification, setFailureNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [isTokenLimitReached, setTokenLimitReached] = useState(false);


    // Textbar Placehoder text
    const [textbarPlaceHolderText, setTextbarPlaceHolderText] = useState("Message Chat HPE...")

    // Head subheading
    const [titleSubHeading, setTitleSubHeading] = useState("AI Chat")


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);



    const [isNotProcessedAvailable, setIsNotProcessedAvailable] = useState(false);
    const [isNotUploadedAvailable, setIsNotUploadedAvailable] = useState(false);

    const [bottomSettingsVisible, setBottomSettingsVisible] = useState(true);
    const [streamMessage, setStreamMessage] = useState('');
    const [streaming, setStreaming] = useState(() => {
        const localStorageValue = localStorage.getItem('Streaming');
        return localStorageValue === "true"; // Convert string "true" to boolean true, otherwise false
    });

    const [streamingProgress, setStreamingProgress] = useState(false);
    const [textAreaHeight, setTextAreaHeight] = useState('auto');

    const topScrollableDivRef = useRef();
    const [initialTheme, setInitialTheme] = useState(localStorage?.getItem('isDarkMode') === 'true' ? true : false)
    const { toggleTheme } = useTheme();
    useEffect(() => {
        toggleTheme(initialTheme)
        // updateDarkMode(initialTheme)
    }, [initialTheme])

    var showInitials = true
    const { theme } = useTheme();
    const [isFileUploadDialogVisable, setIsFileUploadDialogVisable] = useState(false)

    const loading = (e) => spinnerRef.current.LoadingStart(e);
    const processing = (e) => processingSpinnerRef.current.ProcessingStart(e)
    const embedding = (e) => embeddingSprinnerRef.current.EmbeddingStart(e);
    const SignAgreement = () => AgreementRef.current.onOpen();
    const tokensUsed = localStorage.getItem('tokensUsed');

    const handleChange = (event) => {
        setNewMessage(event.target.value);
    }
    // const scrollNotifications = () => {
    //     scrollableDivRef.current.scrollTop+scrollableDivRef.current.height;
    // };

    //Logic for changing AppMode in the application
    // useEffect(()=>{
    //     console.log(appId);
    //     if(appId==='1'){setIsActionBarVisible(false);}
    //     else{
    //         setIsActionBarVisible(true);
    //     }
    // },[appId])

    // useEffect(()=>{
    //     if(localStorage.getItem('appIdValue')==='3'){
    //         setStreaming(false);
    //         setStreamingProgress(false);
    //     }
    // }, [localStorage.getItem('appIdValue')]);

    useEffect(()=>{
        if(Number(localStorage.getItem('tokensUsed'))>=Number(localStorage.getItem('tokenUsageTotal'))){
            setTokenLimitReached(true);
            setRegenerateButtonVisable(false);
            setSendPromptButtonDisabled(true);
            setIsActionBarVisible(false);   
            setAttachButtonDisabled(true);
        }
        else{
            setTokenLimitReached(false);
            // setRegenerateButtonVisable(true);
            // setSendPromptButtonDisabled(false);
            // setIsActionBarVisible(true);   
            // setAttachButtonDisabled(false);
        }
    }, [localStorage.getItem('tokensUsed')])


    
    useEffect(() => {
        if (botResponse && streaming) {
            setStreamingProgress(true);
        }
        else {
            setStreamingProgress(false);
        }
    }, [botResponse]);


    const changeStream = async (newChecked) => {

        setStreaming(newChecked);
        localStorage.setItem('Streaming', newChecked);
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                callStreamApi()
            }
        } else {
            callStreamApi();
        }
        async function callStreamApi() {
            try {
                let streamVariable = localStorage.getItem('Streaming');
                if (streamVariable === 'true') {
                    streamVariable = true
                }
                else {
                    streamVariable = false
                }
                // TEMP REMOVAL OF 30 SEC TIMEOUT
                const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.STREAM, {
                // const response = await fetch(constClass.STREAM, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    },
                    body: JSON.stringify({ stream: streamVariable, chatHPE_bot_data: JSON.parse(localStorage.getItem('chatBotData')) })
                });
                const newData = await response.json();
                //Added condition for Authentication to fix the uncaught runtime error. Issue 146
                if (response.status === 200) {
                    localStorage.setItem('Streaming', newData.stream);
                    setStreaming(newData.stream);
                    // toggleTheme({ theme: newData.dark_mode ? 'dark' : 'light' });
                    // localStorage.removeItem('chatBotData')
                    // const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
                    // localStorage.setItem('chatBotData', chatBotDataString);
                    //loading(undefined);
                }
                else {
                    navigate('/error', { state: { errorMessage: newData['message'] } });
                }
            } catch (error) {
                console.error('An error occurred:', error)
                navigate('/error')
            }
        }

    };


    const responseLengths = {
        "gpt-3.5-turbo": 1228, //previously 4000
        "gpt-3.5-turbo-16k": 4915, //previously 16000
        "gpt-4": 2457, //previously 8000
        "gpt-4-32k": 4500 //previously 32000 // 9830
    };

    //START CODE CHATHPE-465 ----> Code for resizing the textbar
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';

            const newHeight = `${textareaRef.current.scrollHeight}px`;

            // Limit height to 300px
            if (parseInt(newHeight) <= 130) {
                textareaRef.current.style.height = newHeight;
            } else {
                textareaRef.current.style.height = '130px';
            }
        }
        if (newMessage === '') {
            setTextAreaHeight('auto');
        }

    }, [newMessage]);

    useEffect(() => {
        if (newMessage) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, [newMessage]);
    //END CODE

    // START CODE - CHATHPE-249 , CHATHPE-229 , CHATHPE-267  14/02/24  
    useEffect(() => {

        if (localStorage.getItem('activeItem') != null) {
            setTextBarDisabled(false);
        }
        else {
            // console.log(localStorage.getItem('appIdValue'));
            setAppId(localStorage.getItem('appIdValue'));
            if (localStorage.getItem('appIdValue') === '1') {
                setTitleSubHeading("AI Chat");
                setIsActionBarVisible(false);
                setTextBarDisabled(false);
            }
            else if (localStorage.getItem('appIdValue') === '2') {
                setTitleSubHeading("Document Q&A");
                setTextBarDisabled(true);
                setIsActionBarVisible(true);
            }
            else if (localStorage.getItem('appIdValue') === '3') {
                setTitleSubHeading("Document Summarization");
                setTextBarDisabled(true);
                setIsActionBarVisible(true);
            }
            else {
                localStorage.setItem('appIdValue', '1');
                setTitleSubHeading("AI Chat");
                setIsActionBarVisible(false);
                setTextBarDisabled(false);
            }
        }
        // if(localStorage.getItem('appIdValue')==='3'){
        //     setTextBarDisabled(true);
        // }
        // else{
        //     setTextBarDisabled(false);
        // }
    }, [localStorage.getItem('appIdValue')])
    //END CODE - CHATHPE-249 , CHATHPE-229 , CHATHPE-267    

    useEffect(() => {
        let chatData = JSON.parse(localStorage.getItem('chatBotData'));
        if (chatData && chatData['response_token_limit'] > responseLengths[chatData['model_name']]) {
            chatData['response_token_limit'] = responseLengths[chatData['model_name']];
            localStorage.setItem('chatBotData', JSON.stringify(chatData));
        }
    }, [localStorage.getItem('chatBotData')]);



    //START CODE - CHATHPE-251  14/02/2024   W
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // useEffect(() => {
    //     const first = localStorage.getItem('first');
    //     const last = localStorage.getItem('last');
    //     profileImage();
    //     setInitials(first.substring(0, 1) + last.substring(0, 1));
    // }, []);
    useEffect(() => {
        const name = localStorage.getItem('name'); 
        if (name) {
            const [last, first] = name.split(',').map(part => part.trim()); 
            if (first && last) {
                const lastNameInitial = last.split(' ')[0].substring(0, 1); 
                const firstNameInitial = first.substring(0, 1); 
                setInitials(firstNameInitial + lastNameInitial); 
            }
            // profileImage();
        }
    }, []);
    

    useEffect(() => {
        profileImage()
    }, [initials]);

    useEffect(() => {
        if (windowWidth <= 768) {
            setOpenNotification(false);
        } else {
            setOpenNotification(true);
        }

        // Check if height is beyond a limit (e.g., 500)
        if (windowHeight <= 500) {
            setBottomSettingsVisible(false);
        } else {
            setBottomSettingsVisible(true);
        }
    }, [windowWidth, windowHeight]);
    //END CODE - CHATHPE-251


    useEffect(() => {
        if (processFailedFiles.length > 0) {
            setIsNotProcessedAvailable(true);
        }
    }, [processFailedFiles]);

    useEffect(() => {
        if (uploadFailedFiles.length > 0 && (uploadFailedDialogShowed == false)) {
            setIsNotUploadedAvailable(true);
        }
    }, [uploadFailedFiles])

    useEffect(() => {
        const storedFileList = JSON.parse(localStorage.getItem('fileList'));
        if (storedFileList) {
            setUserSessionFiles(storedFileList);
            // console.log("The Files left: ", storedFileList)
        } else {
            setUserSessionFiles(null)
        }
    }, [localStorage.getItem('fileList')]);
    useEffect(() => {
        if (userSessionFiles?.length > 0) {
            setAttachButtonDisabled(true);
        } else {
            setAttachButtonDisabled(false);
        }
    }, [userSessionFiles]);

    // useEffect(() => {
    //     if (isScrollable && streamingProgress) {
    //         scrollToBottom();
    //     }
    // }, [isScrollable, streamingProgress])

    const scrollToBottom = () => {
        if (scrollableDivRef.current) {
            // scrollableDivRef.current.scrollTop += (scrollableDivRef.current.scrollHeight+1000);
            scrollableDivRef.current.scrollTo({
                top: scrollableDivRef.current.scrollHeight + 1000,
                behaviour: 'smooth'
            });
            setIsScrollable(false)
        }
    }

    const handleNotificationLoad = () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollableDivRef.current;
        // const isNearBottom = Math.round(scrollTop + clientHeight) < scrollHeight;
        const isNearBottom = Math.ceil(scrollTop + clientHeight) < scrollHeight;
        setIsScrollable(isNearBottom);
    }

    const setActiveSession = (e, sess, sess_id) => activeSessionRef.current.clickHandler(e, sess, sess_id)

    const moveTitleToDown = () => {
        setYoffset(0)
        // scrollableDivRef.current.scrollTop = 0;
    };

    //Fix for Issue-142 (burger menu bug). Passing it to Toggle.js and updating the data.dark_mode there.
    const updateDarkMode = (newDarkMode) => {
        setData((prevData) => ({
            ...prevData,
            dark_mode: newDarkMode,
        }));

    };


    const handleRefresh = async () => {
        loading(true)
        // console.log('before app id: ', appId);
        const data = {}
        try {
            const appId = localStorage.getItem('appIdValue')
            // TEMP REMOVAL OF 30 SEC TIMEOUT
            const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.LOGIN, {
            // const response = await fetch(constClass.LOGIN, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                    "Client-ID": constClass.CLIENT,
                    "Connection": 'keep-alive'
                },
                body: JSON.stringify({ appId: appId })
            });
            const newData = await response.json();
            if (response.status === 200) {
                // console.log('item after setttnig as active item: ', JSON.parse(localStorage.getItem('activeItem')));
                setData(newData);
                // localStorage.setItem('isDarkMode', newData.dark_mode);
                const item = localStorage.getItem('activeItem') !== null && localStorage.getItem('activeItem') !== 'undefined' ? JSON.parse(localStorage?.getItem('activeItem')) : null;
                localStorage.removeItem('fileList');
                // setUserSessionFiles(JSON.parse(localStorage.getItem('fileList')));
                //const item  = JSON.parse(localStorage.getItem('chatBotData'));
                //item['max_response_tokens'] = item.response_token_limit;
                //item['model'] = item.model_name;
                //console.log(item);

                if (item) {
                    setActiveSession('undefined', item, item.sessionId);
                }
                loading(undefined)
            }
            else {
                navigate('/error', { state: { errorMessage: newData['message'] } });
            }
        } catch (error) {
            console.error('An error occurred:', error)
            navigate('/error')
        }

    };

    useEffect(() => {
        // Check if savedFiles is defined before using map
        if (savedFiles && savedFiles.length > 0) {
            // setProcessButtonDisable(false);
            const newFileNames = savedFiles.map(file => file.name);
            setFileNames(newFileNames);
        }

    }, [savedFiles]);

    // useEffect(() => {
    //     // console.log('uploadfailed: ', uploadFailedFiles);
    // }, [uploadFailedFiles])

    //COMMENTING THE BELOW CODE FOR STREAMING
    // useEffect(() => {
    //     if (window.performance.navigation.type === 1) {
    //         handleRefresh();
    //     }
    // }, [window.performance.navigation.type]);
    useEffect(() => {
        const item = localStorage.getItem('activeItem') !== null && localStorage.getItem('activeItem') !== 'undefined' ? JSON.parse(localStorage?.getItem('activeItem')) : null;

        if (item) {
            setActiveSession('undefined', item, item.sessionId);
        }
    }, [accessToken])

    useEffect(() => {
        if ((!agreementVal.agreement) && agreementVal.agreement != undefined) {
            SignAgreement();
        }
    }, [agreementVal])

    const handleSendMessage = () => {
        if (Number(tokensUsed) >= Number(localStorage.getItem('tokenUsageTotal'))) {
            setNotificationMessage('Token limit reached');
            setFailureNotification(true);
            setNewMessage('');
            return;
        }
        if ((localStorage.getItem('fileList') === null || localStorage.getItem('fileList') === undefined) && localStorage.getItem('appIdValue') != '1') {
            setNotificationMessage('Process files to ask questions');
            setFailureNotification(true);
            setNewMessage('');
            return;
        }

        if (newMessage.trim() !== '') {
            setRegenerateButtonVisable(false);   //Change for CHATHPE-869
            setMessages([...messages, { text: newMessage, sender: 'user' }]);
            setNewMessage('');
            if (localStorage.getItem('appIdValue') === '1') {
                if (streaming === false) {
                    // loading(true) // <-- Add this    
                }
                getAssistantResponse();
            } else {
                if (streaming === false || localStorage.getItem('appIdValue') === '3') {
                    // loading(true) // <-- Add this    
                }
                ragChat();
            }
            setBotResponse(true);
        }
        else{
            setNewMessage('');
        }
    };
    // TEMP REMOVAL OF 30 SEC TIMEOUT
    // const genericAPICall=(count)=>{
    //     if(count===0){
    //         return
    //     }
    //     return new Promise((resolve,reject)=>{
    //         setTimeout(()=>{
    //             reject()
    //         },30000)

    //     })
    // }

    const getAssistantResponse = async () => {
        // loading(true);
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                callStartChatApi()
            }
        } else {
            callStartChatApi();
        }
        async function callStartChatApi() {
            try {
                const chat_data = localStorage.getItem('chatBotData')
                const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.START_CHAT, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    },
                    body: JSON.stringify({ user_message: newMessage, chatHPE_bot_data: JSON.parse(chat_data), stream: streaming })
                });
                if (response.status === 403) {
                    setNotificationMessage('Token limit reached');
                    setFailureNotification(true);
                    setBotResponse(false);   //START CODE CHATHPE - 661  After /Chat fails due to token limit the state is handled here.  17/04/2024
                }
                else{
                    let buffer = '';
                    if (streaming) {
                        const reader = response.body.getReader();
                        let decoder = new TextDecoder();
                        reader.read().then(function processStream({ done, value }) {
                            if (done) { 
                                setBotResponse(false);
                                setStreamMessage('');
                                setRegenerateButtonVisable(true);
                                setTimeout(scrollToBottom, 100)
                                return;
                            }
                            buffer += decoder.decode(value, { stream: true });
                            
                            while (buffer.includes('\n')) { 
                                const lineEndIndex = buffer.indexOf('\n');
                                const line = buffer.slice(0, lineEndIndex + 1);
                                buffer = buffer.slice(lineEndIndex + 1);
    
                                try {
                                    const jsonResponse = JSON.parse(line);
                                    if(jsonResponse.error){
                                        setFailureNotification(true);
                                        setNotificationMessage(jsonResponse.error);
                                        handleRefresh();
                                    }
                                    if (jsonResponse.chat_history) {
                                        setChatHistory(jsonResponse.chat_history);
                                        setSessionData({ ...data, chat_history: jsonResponse.chat_history, all_sess: jsonResponse.all_sess });
                                        const item = jsonResponse.all_sess.sort((a, b) => new Date(b.endDateTime) - new Date(a.endDateTime))[0]
                                        localStorage.removeItem('activeItem')
                                        localStorage.setItem('activeItem', JSON.stringify(item));
                                        const prevChatbotData = JSON.parse(localStorage.getItem('chatBotData'));
                                        localStorage.removeItem('chatBotData');
                                        const activeItem = JSON.parse(localStorage.getItem('activeItem'));
                                        console.log(activeItem);
                                        localStorage.setItem('chatBotData', JSON.stringify({
                                            appId: localStorage.getItem('appIdValue'),
                                            sessionId: activeItem.sessionId, 
                                            userid: prevChatbotData.userid,
                                            username: prevChatbotData.username
                                        }));
                                        localStorage.setItem('tokensUsed',jsonResponse.total_tokens_used)
                                    }
                                    const message = jsonResponse.message;
                                    console.log(message);   
                                    if (message) {
                                        setStreamMessage(prevMessage => prevMessage + message);
                                    }
                                    
                                } 
                                catch (error) {
                                    console.error('Error parsing JSON:', error);
                                }
                            }
    
                            return reader.read().then(processStream);
                        });
                    }
    
                    else {
                        const newData = await response.json();
                        // console.log("newData after chat: ", newData);
                        if (response.status === 200) {
                            setChatHistory(newData.chat_history);
                            // Start code change for ChatHPE-591
                            setSessionData({ ...data, chat_history: newData.chat_history, all_sess: newData.all_sess });
                            const item = newData.all_sess.sort((a, b) => new Date(b.endDateTime) - new Date(a.endDateTime))[0]
                            localStorage.removeItem('activeItem')
                            // End code change for ChatHPE-591
                            localStorage.setItem('activeItem', JSON.stringify(item));
                            const prevChatbotData = JSON.parse(localStorage.getItem('chatBotData'));
                                        localStorage.removeItem('chatBotData');
                                        const activeItem = JSON.parse(localStorage.getItem('activeItem'));
                                        console.log(activeItem);
                                        localStorage.setItem('chatBotData', JSON.stringify({
                                            appId: localStorage.getItem('appIdValue'),
                                            sessionId: activeItem.sessionId, 
                                            userid: prevChatbotData.userid,
                                            username: prevChatbotData.username
                                        }));
                            localStorage.setItem('tokensUsed',newData.total_tokens_used)
                            // localStorage.removeItem('chatBotData')
                            //  const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
                            //  localStorage.setItem('chatBotData', chatBotDataString);
                            //   localStorage.setItem('activeItem', chatBotDataString);
                            // console.log('active session: ', chatBotDataString);
                            setBotResponse(false)
                            loading(undefined);
                            setRegenerateButtonVisable(true);
                            // setFirstNewItemActive(true);
                            setTimeout(scrollToBottom, 100)
                            setTimeout(() => {
                                textareaRef.current.focus();
                            }, 100);
                            // activeSessionRef.current.setActive(newData.chatHPE_bot_data.sessionId)
                        }
                        else {
                            navigate('/error', { state: { errorMessage: newData['message'] } });
                        }
                    }
                }


            } catch (error) {
                navigate('/error')
            }
        }
    }

    const getSessionChatHistory = (chatHistory) => {
        setChatHistory([]);
        setChatHistory(chatHistory.chat_history);
        setMessages([]);
    }

    const setSessionData = (updatedSession) => {
        setData('');
        setData(updatedSession);
    }

    useEffect(() => {
        setData(data);
        setFirstNewItemActive(false);
    }, [data]);


    // Fetch logic for phase 2. RAG API's are below
    const AppMode = async () => {

        loading(true);
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                changeAppMode()
            }
        } else {
            setSavedFiles([]);
            setUploadFailedFiles([])
            changeAppMode();
        }
        async function changeAppMode() {
            try {
                moveTitleToDown();
                loading(undefined);
                // setFirstNewItemActive(true);
                setTimeout(scrollToBottom, 100)
                setTimeout(() => {
                    textareaRef.current.focus();
                }, 100);
                localStorage.removeItem('activeItem')
                newSessionState(localStorage.getItem('appIdValue'))
                const prevChatbotData = JSON.parse( localStorage.getItem('chatBotData'))
                localStorage.removeItem('chatBotData')
                localStorage.setItem('chatBotData',JSON.stringify({appId:localStorage.getItem('appIdValue'),sessionId:-1,userid:prevChatbotData.userid,username:prevChatbotData.username}))
                // const response = await fetch(constClass.APP_MODE, {
                //     method: "POST",
                //     headers: {
                //         "Content-Type": "application/json",
                //         "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                //         "Client-ID": constClass.CLIENT
                //     },
                //     body: JSON.stringify({ appId: localStorage.getItem('appIdValue') })
                // });
                // const newData = await response.json();
                //Added condition for Authentication to fix the uncaught runtime error. Issue 146
                // if (response.status === 200) {
                //     setChatHistory(newData.chat_history);
                //     setSessionData(newData);
                //     localStorage.removeItem('chatBotData')
                //     const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
                //     localStorage.setItem('chatBotData', chatBotDataString);
                //     // console.log("New chatBotData after mode change: ", chatBotDataString)
                //     localStorage.removeItem('activeItem')
                //     moveTitleToDown();
                //     loading(undefined);
                //     // setFirstNewItemActive(true);
                //     setTimeout(scrollToBottom, 100)
                //     setTimeout(() => {
                //         textareaRef.current.focus();
                //     }, 100);
                //     newSessionState(localStorage.getItem('appIdValue'))
                // }
                // else {
                //     navigate('/error', { state: { errorMessage: newData['message'] } });
                // }

            } catch (error) {
                navigate('/error')
            }
        }
    }

    const attachFile = () => {
        if (Number(tokensUsed) >= Number(localStorage.getItem('tokenUsageTotal'))) {
            setNotificationMessage('Token limit reached');
            setFailureNotification(true);
            setNewMessage('');
            return;
        }
        getQuota();
        setIsUploadOpen(true);
    }

    const deleteFiles = () => {
        setDeleteOpen(true);
        // deleteFile(filesForDelete)
    }


    // ALL THE QUOTA RELATED CHANGES ARE BELOW
    // useEffect(() => {
    //     // console.log(totalAllowedFileSize, totalFilesAllowed);

    // }, [totalAllowedFileSize, totalFilesAllowed]);

    const getQuota = async () => {
        try {
            //loading(true);
            const chat_data = JSON.parse(localStorage.getItem('chatBotData'));
            const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.QUOTA, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                    "Client-ID": constClass.CLIENT,
                    "Connection": 'keep-alive'
                }
            });
            const newData = await response.json();
            if (response.status === 200) {
                let uploadedFileSizeMB = parseFloat(newData.doc_size_uploaded) / (1024 * 1024);
                let maxSizeAllowedMB = parseFloat(newData.max_doc_size_allowed) / (1024 * 1024);     
                uploadedFileSizeMB = uploadedFileSizeMB.toFixed(2);
                maxSizeAllowedMB = maxSizeAllowedMB.toFixed(2);
                setTotalAllowedFileSize(maxSizeAllowedMB - uploadedFileSizeMB);
                setTotalFilesAllowed(newData.max_files_allowed - newData.total_files_uploaded);
                // console.log(totalAllowedFileSize, totalFilesAllowed);
                loading(undefined);
                // setIsUploadOpen(true);
                setTimeout(scrollToBottom, 100)
                setTimeout(() => {
                    textareaRef.current.focus();
                }, 100);
            }
            else if (response.status == 403) {
                setNotificationMessage('Token limit reached');
                setFailureNotification(true);
                setNewMessage('');

                // alert(`Excecution Blocked : ${newData}`)
                // navigate("/")
            }
            else {
                // console.log('print after quota failed');
                handleRefresh();
            }
        } catch (error) {
            console.error('An error occured:', error);
            navigate('/error');
        }
    }




    const processFile = async () => {
        const chat_data = JSON.parse(localStorage.getItem('chatBotData'))
        // console.log('filenames before processing: ', fileNames);
        // processing(true);
        // setAppId('2');
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                callProcessFile()
            }
        } else {
            callProcessFile();
        }
        async function callProcessFile() {
            try {
                const body = JSON.stringify({ chatHPE_bot_data: chat_data, file_names: fileNames})
                const response = await axios.post(constClass.setApiUrl(localStorage.getItem('region')) + constClass.PROCESS_DOCUMENTS, body, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    },
                    onUploadProgress: (data) => {
                        //Set the progress value to show the progress bar
                        setProgresspercentage(Math.round((100 * data.loaded) / data.total) * (2 / 3));
                        // console.log(data, Math.round((100 * data.loaded) / data.total));
                    },
                });
                const newData = await response.data;
                setUploadFailedDialogShowed(true);
                let successCount = 0;
                let flag = true;
                let failedFilesArray = [];
                newData.file_details.forEach(file => {
                    if (file.status === 'success') {
                        successCount++;
                    }
                    else {
                        flag = false;
                        failedFilesArray.push(file);
                    }
                });
                setProcessFailedFiles(failedFilesArray);
                if (successCount >= 1) {
                    // setNotificationMessage('Processed Successfully!');
                    // setSuccessNotification(true);
                    // setData(newData);
                    setBotResponse(false)
                    setSessionData({ ...data, all_sess: newData.all_sess });
                    const item = newData.all_sess.sort((a, b) => new Date(b.endDateTime) - new Date(a.endDateTime))[0]
                    localStorage.removeItem('activeItem')
                    localStorage.setItem('activeItem', JSON.stringify(item));
                    // setProgresspercentage((2/3)*100)
                    // const newDatawithFiles = JSON.parse(newData);
                    // console.log(newData);
                    // newData.file_details.forEach(file => {
                    //     if(file.message=='success'){
                    //         console.log(file.message);
                    //         setSuccessCount(prevSuccessCount => prevSuccessCount + 1);
                    //     }
                    // });


                    // setChatHistory(newData.chat_history);
                    // setSessionData(newData);
                    // localStorage.removeItem('chatbotData');
                    // localStorage.setItem('chatBotData', chatBotDataString);
                    // localStorage.setItem('activeItem', chatBotDataString);
                    // const fileDetails = newData.file_status;

                    // deleteButtonDisable(false);
                    // embedButtonDisable(false);
                    // processButtonDisable(true);
                    // localStorage.removeItem('fileList');
                    localStorage.setItem('fileList', JSON.stringify(newData.files));
                    // setCompletedFiles(newData.files);
                    processing(undefined);
                    // setFirstNewItemActive(true);
                    setTimeout(scrollToBottom, 100)
                    setTimeout(() => {
                        textareaRef.current.focus();
                    }, 100);
                    // Once processing is done we lock/disable the processing button
                    setProcessButtonDisabled(true)
                    // Unlock summarize button
                    setSummarizeButtonDisabled(false)
                    // Unlock textbar
                    setTextBarDisabled(false)
                    // Unlock delete files button
                    setDeleteButtonDisabled(false)
                    if (localStorage.getItem('appIdValue') === '2') {
                        embedButtonAction();
                    }
                    if (localStorage.getItem('appIdValue') === '3') {
                        ragChat();
                    }
                }
                // Is this else needed since the catch is creating the same error?
                // Using catch we can show the user in the toast what happened.
                else {
                    setSuccessNotification(false);
                    setNotificationMessage('File Processing failed!');
                    setFailureNotification(true);
                    processing(undefined);
                    uploading(undefined);
                    newSessionState(localStorage.getItem('appIdValue'));
                }
            } catch (error) {
                setSuccessNotification(false);
                setNotificationMessage('File Processing failed! ' + error);
                setFailureNotification(true);
                processing(undefined);
                uploading(undefined)
                newSessionState(localStorage.getItem('appIdValue'));

                // console.error('An error occured:', error);
                // navigate('/error');
            }
        }
    }
    const deleteFile = async (filesToBeDeleted) => {
        const chat_data = JSON.parse(localStorage.getItem('chatBotData'))
        loading(true);
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                callProcessFile()
            }
        } else {
            callProcessFile();
        }
        async function callProcessFile() {
            try {
                const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.PROCESS_DOCUMENTS, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    },
                    body: JSON.stringify({ chatHPE_bot_data: chat_data, appId: localStorage.getItem('appIdValue'), file_names: filesToBeDeleted })
                });
                const newData = await response.json();
                // console.log('delete after: ', newData);
                let successCount = 0;
                let flag = true;
                newData.file_details.forEach(file => {
                    if (file.status === 'success') {
                        successCount++;
                    }
                    else {

                        flag = false;
                    }
                });
                if (flag && successCount === filesToBeDeleted.length) {
                    setFailureNotification(false);
                    localStorage.setItem('fileList', JSON.stringify(newData.files));
                    setUserSessionFiles(newData.files)
                    setDeleteNotification(true);
                    // setChatHistory(newData.chat_history);    
                    // setSessionData(newData);
                    // localStorage.removeItem('chatbotData');
                    // localStorage.setItem('chatBotData', chatBotDataString);
                    // localStorage.setItem('activeItem', chatBotDataString);
                    // const fileDetails = newData.file_status;
                    if (savedFiles?.length === 0 && newData.files.length === 0) {
                        setAttachButtonDisabled(false)
                        setProcessButtonDisabled(true)
                        setSummarizeButtonDisabled(true)
                    }
                    setBotResponse(false)
                    // localStorage.removeItem('fileList');
                    // localStorage.setItem('fileList', JSON.stringify(newData.files));
                    loading(undefined);
                    processing(undefined);
                    // setFirstNewItemActive(true);
                    setTimeout(scrollToBottom, 100)
                    setTimeout(() => {
                        textareaRef.current.focus();
                    }, 100);
                    setData(newData);
                    localStorage.setItem("fileList", JSON.stringify(newData.files));
                    embedButtonAction().then(() => {
                        setNotificationMessage("Deleted Successfully");
                        setSuccessNotification(true);
                    });
                }
                // else{
                //     setSuccessNotification(false);
                //     setNotificationMessage('File deletion failed!');
                //     setFailureNotification(true);
                //     loading(undefined);
                //     newSessionState(localStorage.getItem('appIdValue'));
                // }
            } catch (error) {

                setNotificationMessage('File deletion failed!' + error);
                setFailureNotification(true);
                loading(undefined);
                newSessionState(localStorage.getItem('appIdValue'));
                // console.error('An error occured:', error);
                // navigate('/error');
            }
        }
    }

    const embedButtonAction = async () => {
        const chat_data = JSON.parse(localStorage.getItem('chatBotData'))
        // embedding(true);
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                createEmbed()
            }
        } else {
            createEmbed();
        }
        async function createEmbed() {
            try {
                // const response = await fetch(constClass.CREATE_EMBEDDING, {
                //     method: "POST",
                //     headers: {
                //         "Content-Type": "application/json",
                //         "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
                //     },
                //     body: JSON.stringify({ chatHPE_bot_data: chat_data, appId: localStorage.getItem('appIdValue') })
                // });
                const body = JSON.stringify({ chatHPE_bot_data: chat_data, appId: localStorage.getItem('appIdValue') })
                const response = await axios.post(constClass.setApiUrl(localStorage.getItem('region')) + constClass.CREATE_EMBEDDING, body, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    },
                    onUploadProgress: (data) => {
                        //Set the progress value to show the progress bar
                        setProgresspercentage(Math.round((100 * data.loaded) / data.total) * (3 / 3));
                        // console.log(data, Math.round((100 * data.loaded) / data.total));
                    },
                });
                const newData = await response.data;
                if (response.status === 200) {
                    // console.log('after embedding: ', newData);
                    // setChatHistory(newData.chat_history);
                    // setSessionData(newData);
                    // setChatHistory(newData.chat_history);
                    //  setSessionData({...data,chat_history:newData.chat_history});
                    // localStorage.removeItem('chatBotData')
                    // const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
                    // localStorage.setItem('chatBotData', chatBotDataString);
                    // localStorage.setItem('activeItem', chatBotDataString);
                    // const fileDetails = newData.file_status;
                    // embedButtonDisable(true);
                    // textBarDisable(false);
                    //  setProgresspercentage((3/3)*100)

                    setBotResponse(false)
                    embedding(undefined);
                    setProgresspercentage(0)
                    setNotificationMessage('Your documents are now ready for questions.');
                    setSuccessNotification(true);
                    // setFirstNewItemActive(true);
                    setTimeout(scrollToBottom, 100)
                    setTimeout(() => {
                        textareaRef.current.focus();
                    }, 100);
                    uploading(undefined)
                    loading(undefined)
                    setTextBarDisabled(false)
                    setDeleteButtonDisabled(false)
                    setAttachButtonDisabled(false)
                    setProcessButtonDisabled(true)
                    setEmbedButtonDisabled(true)
                    setAttachButtonDisabled(true)
                }
                else {
                    setSuccessNotification(false);
                    setNotificationMessage('Embedding Failed!');
                    setFailureNotification(true);
                    embedding(undefined);
                    uploading(undefined)
                    loading(undefined)
                    newSessionState(localStorage.getItem('appIdValue'));
                }
            } catch (error) {
                // console.error('An error occured:', error);
                // navigate('/error');
                setSuccessNotification(false);
                setNotificationMessage('Embedding Failed!' + error);
                setFailureNotification(true);
                embedding(undefined);
                uploading(undefined)
                loading(undefined)
                newSessionState(localStorage.getItem('appIdValue'));

            }
        }
    }

    const ragChat = async () => {
        //  uploading(undefined);
        // loading(true);
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                if (streaming === false || localStorage.getItem('appIdValue') === '3') {
                    callRagChat()
                }
                else {
                    callRagChatStream()
                }
            }
        } else {
            if (streaming === false || localStorage.getItem('appIdValue') === '3') {
                callRagChat()
            }
            else {
                callRagChatStream()
            }
        }
        async function callRagChatStream() {
            try {
                const chat_data = localStorage.getItem('chatBotData')
                const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.DOC_CHAT, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    },
                    body: JSON.stringify({ user_message: newMessage, chatHPE_bot_data: JSON.parse(chat_data), stream: true })
                });
                if (response.status === 403) {
                    setNotificationMessage('Token limit reached');
                    setFailureNotification(true);
                    setBotResponse(false);   //START CODE CHATHPE - 661  After /Chat fails due to token limit the state is handled here.  17/04/2024
                }
                else{
                    let buffer = '';
                    const reader = response.body.getReader();
                    let decoder = new TextDecoder();
                    reader.read().then(function processStream({ done, value }) {
                        if (done) {
                            setBotResponse(false);
                            setStreamMessage('');
                            setRegenerateButtonVisable(true);
                            setTimeout(scrollToBottom, 100)
                            return;
                        }
                        buffer += decoder.decode(value, { stream: true });
                        while (buffer.includes('\n')) {
                            const lineEndIndex = buffer.indexOf('\n');
                            const line = buffer.slice(0, lineEndIndex + 1);
                            buffer = buffer.slice(lineEndIndex + 1);
    
                            try {
                                const jsonResponse = JSON.parse(line);
                                if (jsonResponse.chat_history) {
                                    setChatHistory(jsonResponse.chat_history);
                                    setSessionData({ ...data, chat_history: jsonResponse.chat_history, all_sess: jsonResponse.all_sess });
                                    const item = jsonResponse.all_sess.sort((a, b) => new Date(b.endDateTime) - new Date(a.endDateTime))[0]
                                    localStorage.removeItem('activeItem')
                                    localStorage.setItem('activeItem', JSON.stringify(item));
                                    const prevChatbotData = JSON.parse(localStorage.getItem('chatBotData'));
                                        localStorage.removeItem('chatBotData');
                                        const activeItem = JSON.parse(localStorage.getItem('activeItem'));
                                        console.log(activeItem);
                                        localStorage.setItem('chatBotData', JSON.stringify({
                                            appId: localStorage.getItem('appIdValue'),
                                            sessionId: activeItem.sessionId, 
                                            userid: prevChatbotData.userid,
                                            username: prevChatbotData.username
                                        }));
                                    localStorage.setItem('tokensUsed',jsonResponse.total_tokens_used)
    
                                }
                                const message = jsonResponse.message;
                                console.log(message);
                                if (message) {
                                    setStreamMessage(prevMessage => prevMessage + message);
                                }
                            } catch (error) {
                                console.error('Error parsing JSON:', error);
                            }
                        }
    
                        return reader.read().then(processStream);
                    });
                }
            } catch (error) {
                console.error('An error occurred:', error)
                uploading(undefined)
                loading(undefined)
                navigate('/error')
            }
        }
        async function callRagChat() {
            try {
                const chat_data = localStorage.getItem('chatBotData');
            
                const body = { 
                    user_message: newMessage ? newMessage : "Summarize the documents", 
                    chatHPE_bot_data: JSON.parse(chat_data), 
                    stream: false 
                };
            
                const response = await axios.post(constClass.setApiUrl(localStorage.getItem('region')) + constClass.DOC_CHAT, body, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    },
                    onUploadProgress: (data) => {
                        // Set the progress value to show the progress bar
                        setProgresspercentage(Math.round((100 * data.loaded) / data.total) * (3 / 3));
                    },
                });
            
                const newData = response.data;
            
                if (response.status === 200) {
                    setChatHistory(newData.chat_history);
                    setSessionData({ ...data, chat_history: newData.chat_history, all_sess: newData.all_sess });
                    localStorage.setItem('tokensUsed', newData.total_tokens_used);
            
                    setBotResponse(false);
                    loading(undefined);
                    uploading(undefined);
                    setProgresspercentage(0);
            
                    setTimeout(scrollToBottom, 100);
                    setTimeout(() => {
                        textareaRef.current.focus();
                    }, 100);
                    setRegenerateButtonVisable(true);
                } else {
                    uploading(undefined);
                    loading(undefined);
                    navigate('/error', { state: { errorMessage: newData['message'] } });
                }
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setNotificationMessage('Token limit reached');
                    setFailureNotification(true);
                    setBotResponse(false); //START CODE CHATHPE - 661  After /Chat fails due to token limit the state is handled here.  17/04/2024
                } else {
                    console.error('An error occurred:', error);
                    uploading(undefined);
                    loading(undefined);
                    navigate('/error');
                }
            }            
        }
    }

    const newChat = async () => {
        const chat_data = JSON.parse(localStorage.getItem('chatBotData'))
        loading(true);
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                callNewChatApi()
            }
        } else {
            callNewChatApi();
        }
        async function callNewChatApi() {
            try {
                moveTitleToDown();
                loading(undefined);
                // setFirstNewItemActive(true);
                setTimeout(scrollToBottom, 100)
                setTimeout(() => {
                    textareaRef.current.focus();
                }, 100);
                localStorage.removeItem('activeItem')
                newSessionState(localStorage.getItem('appIdValue'))
                const prevChatbotData = JSON.parse( localStorage.getItem('chatBotData'))
                localStorage.removeItem('chatBotData')
                localStorage.setItem('chatBotData',JSON.stringify({appId:localStorage.getItem('appIdValue'),sessionId:-1,userid:prevChatbotData.userid,username:prevChatbotData.username}))
               
                // const response = await fetch(constClass.NEW_CHAT, {
                //     method: "POST",
                //     headers: {
                //         "Content-Type": "application/json",
                //         "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                //         "Client-ID": constClass.CLIENT
                //     },
                //     body: JSON.stringify({ chatHPE_bot_data: chat_data, appId: localStorage.getItem('appIdValue') })
                // });
                // const newData = await response.json();
                // // console.log("newData: ", newData);
                // //Added condition for Authentication to fix the uncaught runtime error. Issue 146
                // if (response.status === 200) {
                //     localStorage.removeItem('activeItem');
                //     moveTitleToDown();
                //     setChatHistory(newData.chat_history);
                //     setSessionData(newData);
                //     localStorage.removeItem('chatBotData')
                //     const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
                //     localStorage.setItem('chatBotData', chatBotDataString);
                //     newSessionState(localStorage.getItem('appIdValue'))
                //     //  localStorage.removeItem('activeItem')
                //     setTimeout(() => {
                //         textareaRef.current.focus();
                //     }, 100)
                //     setBotResponse(false);
                //     loading(undefined);
                // }
                // else {
                //     navigate('/error', { state: { errorMessage: newData['message'] } });
                // }
            } catch (error) {
                navigate('/error')
            }
        }
    }

    const onKeyDownHandler = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    useEffect(() => {
        if (uploadSuccessFiles.length > 0) {
            handleFileUploadComplete();
        }
    }, [uploadSuccessFiles])

    const handleFileUploadComplete = () => {
        setFileUploadInProgress(false);
        // setAttachButtonDisable(true);
        processFile();
    };

    const updateAgreementValue = val => {
        setLinkVisibility(val);
        setAgreementVal(val);
    }

    const truncateName = (name) => {
        if (name.length > 10) {
            return name.slice(0, 10) + "...";
        }
        return name
    }

    /**
     * Handle removal of a file by index.
     *
     * This function removes a file from the state arrays (`fileNames` and `savedFiles`)
     * based on the provided index. It ensures that the state is updated with the new
     * arrays excluding the removed file.
     *
     * @param {number} index - The index of the file to be removed.
     * @function
     *
     * @example
     * // Example of using handleRemove in your component
     * const handleRemoveClick = (index) => {
     *   handleRemove(index);
     * }
     *
     * @returns {void}
     */
    const handleRemove = (index) => {
        // Creating a variable to store the number of files in the fileNames array
        // Adding -1 will allow us to capture that when the last one is removed
        // we can disable the processing button. 
        const fileName = allFiles[index]
        if (fileName.type === 'saved') {

            let newFiles = structuredClone(savedFiles)
            newFiles.splice(savedFiles.findIndex(file => file.name === fileName.name), 1)
            // console.log(savedFiles, newFiles, 'ffff');
            setSavedFiles(newFiles)
            // Start of code change ChatHPE-240 & ChatHPE-237 - 2/9/2024
            const processedFiles = JSON.parse(localStorage.getItem('fileList') ?? '[]')?.map(file => { return { name: file, type: 'processed' } })
            if (newFiles?.length === 0 && processedFiles.length === 0) {
                setAttachButtonDisabled(false)
                setProcessButtonDisabled(true)
                setSummarizeButtonDisabled(true)
            }
            // End code change ChatHPE-240 & ChatHPE-237 - 2/9/2024

        }
        else if (fileName.type === 'uploadFailed') {
            let newFiles = structuredClone(savedFiles)
            newFiles.splice(savedFiles.findIndex(file => file.name === fileName.name), 1)
            setSavedFiles(newFiles)
            // Start of code change ChatHPE-240 & ChatHPE-237 - 2/9/2024
            const processedFiles = JSON.parse(localStorage.getItem('fileList') ?? '[]')?.map(file => { return { name: file, type: 'processed' } })
            if (newFiles?.length === 0 && processedFiles.length === 0) {
                setAttachButtonDisabled(false)
                setProcessButtonDisabled(true)
                setSummarizeButtonDisabled(true)
            }
            let newFailedFiles = structuredClone(uploadFailedFiles)
            newFailedFiles.splice(uploadFailedFiles.findIndex(file => file.name === fileName.name), 1)
            // console.log(savedFiles, newFiles, newFailedFiles, 'ffff');

            setUploadFailedFiles(newFailedFiles)
        }
        else if (fileName.type === 'processed') {

            deleteFile([fileName.name])
        }

        // const numberOfFiles = fileNames.length - 1;
        // // Create a copy of the fileNames array to avoid mutating the original state
        // const updatedFileNames = [...fileNames];
        // // Remove the file name at the specified index
        // updatedFileNames.splice(index, 1);
        // // Update the state with the new fileNames array
        // setFileNames(updatedFileNames);
        // // Create a copy of the savedFiles array to avoid mutating the original state
        // const updatedSavedFiles = [...savedFiles];
        // // Remove the saved file at the specified index
        // updatedSavedFiles.splice(index, 1);
        // // Update the state with the new savedFiles array
        // setSavedFiles(updatedSavedFiles);
        // // Checking to see if the number of files is zero. 
        // // If it is then we disable the process button
        // if (numberOfFiles === 0) {
        //     setProcessButtonDisabled(true)
        // }
    }
    const processedFiles = JSON.parse(localStorage.getItem('fileList') ?? '[]')?.map(file => { return { name: file, type: 'processed' } })
    const filesSaved = savedFiles?.filter((file) => !uploadFailedFiles?.find(uf => uf.name === file.name)).map(file => { return { name: file.name, type: 'saved' } }) ?? []

    const allFiles = [...processedFiles, ...filesSaved, ...uploadFailedFiles]
    // console.log(allFiles, 'all');
    let placeholderText;
    switch (localStorage.getItem('appIdValue')) {
        case '1':
            placeholderText = 'Message Chat HPE...';
            break;
        case '2':
            placeholderText = 'Ask questions on uploaded document...';
            break;
        case '3':
            placeholderText = 'Refine summary...';
            break;
        default:
            placeholderText = 'Enter your message...';
    }

    const startUploadProcess = () => {
        // processing(true);
        setFileUploadInProgress(true);

    }

    const updateProcessButtonDisabled = (value) => {
        setProcessButtonDisabled(value);
    };

    const updateSummarizeButtonDisabled = (value) => {
        setSummarizeButtonDisabled(value);
    };
    // Start code change CHATHPE-203
    function newSessionState(newSessionState) {
        if (newSessionState === '1') {
            setTitleSubHeading("AI Chat"); // Code change for ChatHPE-201
            // setSavedFiles([]);
            //  setFileNames([]);
            localStorage.removeItem("fileList");
            setTextBarDisabled(false);
            setPromptButtonVisable(true);
            setIsActionBarVisible(false);
            setRegenerateButtonVisable(false);
            setNewMessage("");
        } else if (newSessionState === '2') {
            setTitleSubHeading("Document Q&A"); // Code change for ChatHPE-201
            //  setSavedFiles([]);
            //  setFileNames([]);
            localStorage.removeItem('fileList')
            setIsActionBarVisible(true);
            filesSaved?.length > 0 ? setProcessButtonDisabled(false) : setProcessButtonDisabled(true);
            setDeleteButtonDisabled(true)
            setTextBarDisabled(true)
            setPromptButtonVisable(true);
            setAttachButtonDisabled(false);
            setRegenerateButtonVisable(false);
            setNewMessage("");
        } else if (newSessionState === '3') {
            setTitleSubHeading("Document Summarization"); // Code change for ChatHPE-201
            // setSavedFiles([]);
            //  setFileNames([]);
            localStorage.removeItem('fileList')
            setIsActionBarVisible(true);
            filesSaved?.length > 0 ? setProcessButtonDisabled(false) : setProcessButtonDisabled(true);
            setDeleteButtonDisabled(true)
            setTextBarDisabled(true)
            setPromptButtonVisable(false);
            filesSaved?.length > 0 ? setSummarizeButtonDisabled(false) : setSummarizeButtonDisabled(true)
            setAttachButtonDisabled(false);
            setRegenerateButtonVisable(false);
            setNewMessage("");
        } else {
            // Some other state...
        }
    }
    // End code change CHATHPE-203
    const onClose = () => {
        setIsNotProcessedAvailable(false);
        setProcessFailedFiles([]);
    }
    const onCloseUpload = () => {
        setIsNotUploadedAvailable(false);
        setUploadFailedDialogShowed(true);
        // newSessionState(localStorage.getItem('appIdValue'));
        // setFileUploadInProgress(false);
    }

    const onProceedClick = () => {
        uploading(true)
        setSavedFiles([]);
        setIsNotUploadedAvailable(false);
        handleFileUploadComplete();
    }

    //START CODE    CHATHPE-521     13/03/2024
    useEffect(() => {
        if (getChatHistory.length === 0) {
            setRegenerateButtonVisable(false);
        }
    }, [getChatHistory])
    //END CODE

    const regenerateMessage = () => {
        var userQuestionArray = [];
        getChatHistory.map((chat) => {
            if (chat.role === "user") {
                userQuestionArray.push(chat.message)
            }
        });
        // console.log(userQuestionArray[userQuestionArray.length -1])
        setNewMessage(userQuestionArray[userQuestionArray.length - 1])
        textareaRef?.current?.focus()
    }

    const logout = async () => {
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                callLogoutApi()
            }
        } else {
            callLogoutApi();
        }
        // const callLogoutApi = async () => {
        async function callLogoutApi() {
            try {
                // TEMP REMOVAL OF 30 SEC TIMEOUT
                const response = await fetch(constClass.setApiUrl(localStorage.getItem('region')) + constClass.LOGOUT, {
                // const response = await fetch(constClass.LOGOUT, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    }
                });
                const newData = await response.json();
                if (!newData.authentication) {
                    localStorage.clear();
                    navigate('/logout')
                }
                else {
                    navigate('/error', { state: { errorMessage: newData['message'] } });
                }
            } catch (error) {
                console.error('An error occurred:', error)
                navigate('/error')
            }
        }
    }

    const downloadPdf = () => {
        const pdfUrl = constClass.USER_GUIDE;

        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'
        link.download = 'User-Guide.pdf';
        link.click();
    };

    const validateRegenButton = (value) => {
        if (value === 0) {
            setRegenerateButtonVisable(false);
        } else {
            setRegenerateButtonVisable(true);
        }
    }

    const profileImage = () => {
        fetch(constClass.USER_IMAGE_GRAPH_URL, {
            method: "GET",
            headers: {
                // "Content-Type": "image/*",
                "responseType": "blob",
                "Authorization": `${localStorage.getItem('graphToken')}`,
                "Connection": 'keep-alive'
            }
        }).then((resp) => {
            if (resp.status === 200) {
                resp.blob().then((imgdata) => {
                    showInitials = false
                    localStorage.setItem("promptBackUrl", URL.createObjectURL(imgdata));
                    setProfilePic(URL.createObjectURL(imgdata))
                });

            } else {
                // console.log("Loading default Image");
                localStorage.setItem("promptBackUrl", DefaultUserImg);
                setProfilePic(DefaultUserImg)
            }
        })
    };

    const getColorLevel = (value) => {
        if (value <= 70) {
            return (`${theme}` === 'light' ? '#17EBA0' : '#008567'); // Green for values <= 70
        } else if (value <= 90) {
            return (`${theme}` === 'light' ? '#FFBC44' : '#9B6310'); // Yellow for values <= 90
        } else {
            return (`${theme}` === 'light' ? '#FC5A5A' : '#D04F4E'); // Red color for values > 90
        }
    };

    const totalTokens = parseInt(localStorage.getItem('tokenUsageTotal'));
    const usedTokens = parseInt(tokensUsed);

    // Ensure the meter value does not exceed 100%
    const meterValue = usedTokens <= totalTokens ? usedTokens : totalTokens;

    // useEffect(()=>{
    //     console.log(userSessionFiles);
    //     if(localStorage.getItem('appIdValue')!='1' && userSessionFiles?.length===0){
    //         console.log(userSessionFiles, userSessionFiles?.length);
    //         setTextBarDisabled(true);
    //         setAttachButtonDisabled(false);
    //     }
    // }, [userSessionFiles, localStorage.getItem('chatBotData')])

    useEffect(()=>{
        let appIdValue = localStorage.getItem('appIdValue');
        if ((appIdValue === '2' || appIdValue === '3') && processedFiles.length === 0) {
            setTextBarDisabled(true);
            setAttachButtonDisabled(false);
        } else {
            setTextBarDisabled(false);
            setAttachButtonDisabled(true);
        } 
    }, [localStorage.getItem('appIdValue'), processedFiles]);
    

    return (
        <Grommet full>
            <LoadingIcon ref={spinnerRef} />
            <ProcessingIcon ref={processingSpinnerRef} />
            <EmbeddingIcon ref={embeddingSprinnerRef} />
            <ProgressBar ref={progressBarRef} progress={progressPercentage} type={progressBarType} />
            <Agreement ref={AgreementRef} agreementValueCallback={updateAgreementValue} chatHPE_bot_data={chatBotData} />
            <FileUploadDialog
                setIsProceedDisabled={setIsProceedDisabled}
                totalAllowedFileSize={totalAllowedFileSize}
                totalFilesAllowed={totalFilesAllowed}
                newSessionState={newSessionState}
                setSuccessNotification={setSuccessNotification}
                setFailureNotification={setFailureNotification}
                setNotificationMessage={setNotificationMessage}
                isFileUploadInProgress={isFileUploadInProgress}
                setFileUploadInProgress={setFileUploadInProgress}
                isProcessButtonDisabled={updateProcessButtonDisabled}
                isSummarizeButtonDisabled={updateSummarizeButtonDisabled}
                setAttachButtonDisabled={setAttachButtonDisabled}
                setSummarizeButtonDisabled={setSummarizeButtonDisabled}
                setProcessButtonDisabled={setProcessButtonDisabled}
                savedFiles={savedFiles}
                setSavedFiles={setSavedFiles}
                isUploadOpen={isUploadOpen}
                setUpload={setIsUploadOpen}
                appId={appId}
                data={data}
                setFileNames={setFileNames}
                setUploadSuccessFiles={setUploadSuccessFiles}
                onUploadComplete={handleFileUploadComplete}
                deleteFile={deleteFile}
                setProgresspercentage={setProgresspercentage}
                progressPercentage={progressPercentage}
                uploading={uploading}
                uploadFailedFiles={uploadFailedFiles}
                setUploadFailedFiles={setUploadFailedFiles}
                setIsNotUploadedAvailable={setIsNotUploadedAvailable}
            // setFilesForDelete={setFilesForDelete}
            />

            <DeleteFileDialog
                userSessionFiles={userSessionFiles}
                deleteFile={deleteFile}
                setFilesForDelete={setFilesForDelete}
                setDelete={setDeleteOpen}
                isDeleteOpen={isDeleteOpen} />
            <Box height={'100%'} direction='row' flex='grow'>
                <Collapsible direction="horizontal" open={openNotification}>
                    <Box
                        flex
                        border="right"
                        width="350px" //TODO: CHANGE TO MEDIUM
                        overflow="auto"
                        alignSelf='start'
                        pad="22px 0 0 22px"
                        className='sessions'
                    >
                        {/* <SessionList ref={activeSessionRef} activeFirst={firstNewItemActive} moveInputBox={moveTitleToDown} sessions={data.all_sess} accessToken={accessToken} sessionChatHistoryCallback={getSessionChatHistory} sessionListCallback={setSessionData} isDisabled={!linkDisable} chatHPE_bot_data={chatBotData} isDarkMode={data.dark_mode} /> */}
                        <SessionList ref={activeSessionRef}
                            botResponse={botResponse}
                            bottomSettingsVisible={bottomSettingsVisible}
                            activeFirst={firstNewItemActive}
                            moveInputBox={moveTitleToDown}
                            sessions={data.all_sess}
                            accessToken={accessToken}
                            sessionChatHistoryCallback={getSessionChatHistory}
                            sessionListCallback={setSessionData}
                            isDisabled={!linkDisable}
                            chatHPE_bot_data={chatBotData} isDarkMode={data.dark_mode} updateDarkMode={updateDarkMode}
                            setAppId={setAppId} appId={appId} AppMode={AppMode} newChat={newChat} setSummarizeButtonDisabled={setSummarizeButtonDisabled}
                            setDeleteButtonDisabled={setDeleteButtonDisabled} setTextBarDisabled={setTextBarDisabled} setPromptButtonVisable={setPromptButtonVisable}
                            setIsActionBarVisible={setIsActionBarVisible} setProcessButtonDisabled={setProcessButtonDisabled}
                            getChatHistory={getChatHistory} setChatHistory={setChatHistory}
                            // Start code change for ChatHPE-526
                            setTitleSubHeading={setTitleSubHeading} createEmbedApi={embedButtonAction} setAttachButtonDisabled={setAttachButtonDisabled} embedding={embedding}
                            // End code change for ChatHPE-526
                            setNewMessage={setNewMessage} setRegenerateButtonVisable={setRegenerateButtonVisable} validateRegenButton={validateRegenButton}
                            setSavedFiles={setSavedFiles}
                        />
                    </Box>

                </Collapsible>
                {successNotification && (
                    <Notification
                        align='center'
                        toast
                        status="normal"
                        style={{ fontFamily: "MetricHPE-Web-Regular", fontSize: '14', color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), backgroundColor: (`${theme}` === 'light' ? '#FFFFFF' : '#1C1C1C') }}
                        title={notificationMessage}
                        onClose={() => setSuccessNotification(false)}
                    />
                )}
                {failureNotification && (
                    <Notification
                        toast
                        status="critical"
                        style={{ color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), backgroundColor: (`${theme}` === 'light' ? '#FFFFFF' : '#1C1C1C') }}
                        title={notificationMessage}
                        onClose={() => setFailureNotification(false)}
                    />
                )}
                {isNotProcessedAvailable && (
                    <Layer
                        position="center"
                        onClickOutside={onClose}
                        onEsc={onClose}
                        size="large"
                        style={{ backgroundColor: (`${theme}` === 'light' ? '#FFFFFF' : '#1C1C1C'), borderRadius: "16px", boxShadow: (`${theme}` === 'light' ? '0px 6px 12px #0000001F' : '0px 6px 12px #0000005C') }}
                    >
                        <Box pad="medium" gap="small" width="large">
                            <Heading level={3} margin="none" style={{ color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), textAlign: "left" }}>
                                Processing Errors
                            </Heading>
                            <div style={{ overflow: 'auto', minHeight: '50px', maxHeight: '250px' }}>
                                <Box
                                    as="footer"
                                    gap="small"
                                    direction="column"
                                    align="center"
                                    justify="end"
                                    // pad={{ top: 'medium', bottom: 'small' }}
                                    style={{ backgroundColor: (`${theme}` === 'light' ? '#F7F7F7' : '#222222'), borderRadius: "16px" }}
                                >
                                    {processFailedFiles.map((file, index) => {
                                        let filename = file.filename;
                                        if (filename.length > 20) {
                                            filename = `${filename.substring(0, 40)}...${filename.substring(filename.length - 10)}`;
                                        }
                                        return (
                                            <Box key={index} direction='row' margin={{ top: 'small', bottom: 'small' }} style={{ color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), fontSize: "18px", paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Text style={{ fontSize: '16px' }}><strong>{filename}</strong></Text>
                                                <Text style={{ fontFamily: "MetricHPE-Web-Regular", marginLeft: "15px", color: (`${theme}` === 'light' ? '#FC5A5A' : '#D04F4E'), fontSize: '16px' }}>{file.message}</Text>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </div>
                            <div direction='row'>
                                <Button
                                    label={
                                        <Text color="white">
                                            Close
                                        </Text>
                                    }
                                    onClick={onClose}
                                    primary
                                    color="status-critical"
                                    style={{
                                        fontFamily: "MetricHPE-Web-Bold",
                                        backgroundColor: '#01A982',
                                        paddingLeft: '17px',
                                        border: 'none',
                                        fontSize: "18px",
                                        borderRadius: '16px',
                                        // fontWeight: "bold",
                                        marginTop: "20px",
                                        width: '80px',
                                    }}
                                />
                            </div>
                        </Box>
                    </Layer>

                )}
                {isNotUploadedAvailable && (
                    <Layer
                        // position="center"
                        onClickOutside={onCloseUpload}
                        onEsc={onCloseUpload}
                        size="large"
                        style={{ backgroundColor: (`${theme}` === 'light' ? '#FFFFFF' : '#1C1C1C'), borderRadius: "16px", boxShadow: (`${theme}` === 'light' ? '0px 6px 12px #0000001F' : '0px 6px 12px #0000005C') }}
                    >
                        <Box pad="medium" gap="small" width="large">
                            <Heading level={3} margin="none" style={{ color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), textAlign: "left" }}>
                                Upload Errors
                            </Heading>
                            <Text style={{ fontFamily: "MetricHPE-Web-Regular", fontSize: '14', color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), textAlign: "left" }}>The following files haved failed to upload. Please review, correct, and try processing again.</Text>
                            <div style={{ overflow: 'auto', minHeight: '50px', maxHeight: '250px' }}>
                                <Box
                                    as="footer"
                                    gap="small"
                                    direction="column"
                                    align="start"
                                    justify="end"
                                    style={{ backgroundColor: (`${theme}` === 'light' ? '#F7F7F7' : '#222222'), borderRadius: "16px" }}
                                >
                                    {uploadFailedFiles.map((file, index) => {
                                        let filename = file.name;
                                        if (filename.length > 20) {
                                            filename = `${filename.substring(0, 40)}...${filename.substring(filename.length - 10)}`;
                                        }
                                        return (
                                            <Box key={index} direction='row' margin={{ top: 'small', bottom: 'small' }} style={{ color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), fontSize: "18px", paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Text style={{ fontSize: '16px' }}><strong>{filename}</strong></Text>
                                                <Text style={{ fontFamily: "MetricHPE-Web-Regular", marginLeft: "15px", color: (`${theme}` === 'light' ? '#FC5A5A' : '#D04F4E'), fontSize: '16px' }}>{file.message}</Text>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </div>
                            <Button
                                label={
                                    <Text color="white">
                                        Okay
                                    </Text>
                                }
                                align='end'
                                onClick={onCloseUpload}
                                primary
                                style={{
                                    fontFamily: "MetricHPE-Web-Bold",
                                    backgroundColor: '#01A982',
                                    paddingLeft: '17px',
                                    border: 'none',
                                    fontSize: "18px",
                                    borderRadius: '16px',
                                    // fontWeight: "bold",
                                    marginTop: "20px",
                                    width: '80px',
                                }}
                            />
                        </Box>

                    </Layer>

                )}
                
                <Box width="100%" border={false} style={{ zIndex: 1, display: 'flex', flex: 1, flexDirection: 'column', maxHeight: '100%', overflow: 'auto' }}>
                {isTokenLimitReached && (
                    <Notification
                    global
                    icon={<StatusWarning color={theme === 'light' ? '#FF0000' : '#FF0000'} size='medium' />}
                    style={{
                        backgroundColor: theme === 'light' ? '#ffe4e4' : '#E56C6C',
                      }}    
                    actions={[
                        {
                          href: 'mailto:chathpe-support@hpe.com',
                          label: 'Contact Support',
                          style: {
                            fontSize: '14px',
                            color: theme === 'dark' ? '#4D2F55' : '', 
                          }
                        },
                      ]}
                    status="critical"
                    title={
                        <span style={{
                            color: theme === 'light' ? '#555555' : '#D0D0D0',
                            fontSize: '16px'
                        }}>
                        Token Limit Reached
                        </span>
                      }
                    message=" "
                  />
                )}
                    <Box style={{ padding: '8px 24px 8px 24px' }} justify='between' direction="row" align='center'>
                        
                        <Button onClick={() => setOpenNotification(!openNotification)} icon={<Sidebar />}/>
                        <Heading margin="none" style={{ fontSize: '28px', fontFamily: "MetricHPE-Web-Medium", display: 'flex', alignItems: 'Center' }}>
                            {<Hpe color='plain' size='medium' style={{ marginRight: '10px' }} />}Chat HPE:
                            <Text style={{ color: (`${theme}` === 'light' ? '#757575' : '#FFFFFF80'), fontFamily: "MetricHPE-Web-Regular", align: 'bottom', fontSize: '28px', marginLeft: '4px' }}>{titleSubHeading}</Text>
                        </Heading>
                        
                        <Box direction='row' align="center">
                            <Box direction='column' align='start' style={{ padding: '0px 10px 0px 0px' }}>
                                <Text style={{ color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), fontSize: '20px', fontFamily: "MetricHPE-Web-Medium" }}>{localStorage.getItem("name")}</Text>
                                {/* <Text style={{ color: (`${theme}` === 'light' ? '#757575' : '#FFFFFF80'), fontSize: '16px', fontFamily: "MetricHPE-Web-Regular" }}>{localStorage.getItem("email")}</Text> */}
                            </Box>
                            <Box>
                                <Tip dropProps={{ style: { backgroundColor: (`${theme}` === 'light' ? '#F7F7F7' : '#222222'), size: 'small', borderRadius: '16px', border: 'none', overflow: 'hidden', } }} content={<Text size='10px' className={`${theme} tooltip`}>User Settings</Text>}>
                                    <DropButton  dropProps={{ align: { top: 'bottom', right: 'right' }, className: `${theme} userSettingsDropContent` }} style={{
                                            border: 'none',
                                            backgroundImage: "url('" + profilePic + "')",
                                            backgroundSize: "50px 50px",
                                            width: '50px',
                                            height: '50px',
                                            borderRadius: '50%',
                                            color: '#fff',
                                            cursor: 'pointer',
                                            ...botResponse ? { pointerEvents: 'none', opacity: 0.4 } : {}
                                        }}
                                        dropContent={
                                            <Card width="medium">
                                                {/* <CardHeader pad="medium">James Martin
                                            </CardHeader> */}
                                                <CardBody className={`${theme} settingsCardBody`} pad="medium">
                                                    <Box>
                                                        <Box style={{ display: 'flex', justifyContent: "space-between" }} direction='row' >
                                                            <Box direction='column' align='start'>
                                                                <Text style={{ fontSize: '20px', fontFamily: "MetricHPE-Web-Medium" }}>{localStorage.getItem("name")}</Text>
                                                                <Text style={{ fontSize: '16px', fontFamily: "MetricHPE-Web-Regular" }}>{localStorage.getItem("email")}</Text>
                                                            </Box>
                                                            <Box direction='column' align='end'>
                                                                <Button onClick={() => logout()} style={{ borderRadius: '16px', border: 'none' }} icon={<Logout color='#01A982' size="22px" />} tip={{ content: <Text size='10px' className={`${theme} tooltip`}>Logout</Text> }} hoverIndicator />
                                                            </Box>
                                                        </Box>
                                                        {/* <br />
                                                    <RadioButtonGroup
                                                            name="doc"
                                                            direction='row'
                                                            style={{color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF')}}
                                                            options={['HPE Image', 'Initials']}
                                                            // value={value}
                                                            // onChange={(event) => setValue(event.target.value)}
                                                        /> */}
                                                        <br />
                                                        <Box direction='column' align='start'>
                                                            <Button onClick={downloadPdf} style={{ borderRadius: '16px', padding: '0px 6px 0px 6px', color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), border: 'none' }} label="User Guide" icon={<Catalog color='#01A982' size="15px" />} hoverIndicator />
                                                            <Button onClick={(e) => { e.preventDefault(); window.open('https://www.hpe.com/us/en/about/legal/terms-of-use.html', '_blank'); }} style={{ borderRadius: '16px', padding: '0px 6px 0px 6px', color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), border: 'none' }} label="HPE Terms of Use" icon={<HelpOption color='#01A982' size="15px" />} hoverIndicator />
                                                            <Button onClick={(e) => { e.preventDefault(); window.open('https://www.hpe.com/us/en/legal/privacy.html', '_blank'); }} style={{ borderRadius: '16px', padding: '0px 6px 0px 6px', color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), border: 'none' }} label="HPE Privacy Statement" icon={<Secure color='#01A982' size="15px" />} hoverIndicator />
                                                            <Button onClick={(e) => { e.preventDefault(); window.open('https://hpe.sharepoint.com/:b:/r/teams/ChatHPE/Shared%20Documents/Chat%20HPE/Chat%20HPE%20Acceptable%20Use%20Notice.pdf?csf=1&web=1&e=SykeDw', '_blank'); }} style={{ borderRadius: '16px', padding: '0px 6px 0px 6px', color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), border: 'none' }} label="Chat HPE Acceptable Use Notice" icon={<Compliance color='#01A982' size="15px" />} hoverIndicator />

                                                        </Box>
                                                        <Box style={{ display: 'flex', justifyContent: "space-between" }} direction='row'>
                                                                <Box style={{ padding: '10px 30px 0px 0px' }} direction='column'>
                                                                   <Box direction='row' style={{display: 'flex', justifyContent: 'space-between', padding: '10px 0px 0px 0px'}} align='center'>
                                                                   <Text style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Regular" }}>
                                                                        Token Usage
                                                                    </Text>
                                                                    <Text style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Regular" }}>
                                                                   { (tokensUsed)} / {localStorage.getItem('tokenUsageTotal')} ({(tokensUsed*100/localStorage.getItem('tokenUsageTotal')).toFixed(2)}%)
                                                                     </Text>
                                                                   </Box>
                                                                   <Meter max={localStorage.getItem('tokenUsageTotal')} round thickness="xsmall" value={tokensUsed} color={getColorLevel((tokensUsed / localStorage.getItem('tokenUsageTotal')*100))} background={(`${theme}` === 'light' ? '#CCCCCC' : '#555555')} />
                                                                </Box>
                                                            </Box>
                                                        <br />
                                                        <Box flex justify='end'>
                                                            <Box style={{ display: 'flex', justifyContent: "space-between" }} direction='row'>
                                                                <Box direction='row' align='center'>
                                                                    <Text size='14px' style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Regular" }}>Dark Mode</Text>
                                                                </Box>
                                                                <Box style={{ padding: '0px 30px 0px 0px' }} direction='column'>
                                                                    <CustomToggle isDarkMode={props.isDarkMode} updateDarkMode={updateDarkMode} />
                                                                </Box>
                                                            </Box>
                                                                {/* <Box>
                                                                <CheckBox
                                                                        checked={streaming}
                                                                        label="Stream Response"
                                                                        onChange={(event) => changeStream(event.target.checked)}
                                                                        toggle
                                                                        />
                                                                </Box> */}
                                                        </Box>
                                                        <Box flex justify='end' style={{marginTop: '5px'}}>
                                                        {localStorage.getItem('appIdValue') !== '3' && (
                                                                <Box style={{ display: 'flex', justifyContent: 'space-between' }} direction='row' disab>
                                                                    <Box direction='row' align='center'>
                                                                    <Text size='14px' style={{ fontSize: 16, fontFamily: 'MetricHPE-Web-Regular' }}>Streaming Mode</Text>
                                                                    </Box>
                                                                    <Grommet theme={deepMerge(grommet, HPEToggleSwitchTheme)} background='transparent'>
                                                                    <Box style={{ padding: '0px 30px 0px 0px' }} direction='column'>
                                                                        <CheckBox
                                                                        disabled={localStorage.getItem('appIdValue') === '3'}
                                                                        checked={localStorage.getItem('appIdValue') !== '3' ? streaming : false}
                                                                        onChange={(event) => changeStream(event.target.checked)}
                                                                        toggle
                                                                        />
                                                                    </Box>
                                                                    </Grommet>
                                                                </Box>
                                                                )}
                                                        </Box>
                                                    </Box>
                                                </CardBody>
                                                <CardFooter className={`${theme} settingsCardFooter`}>
                                                    <Button onClick={(e) => { e.preventDefault(); window.location.href = 'mailto:chathpe-support@hpe.com'; }} style={{ borderRadius: '16px', color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), border: 'none' }} label="Support" icon={<Support color='#01A982' size="15px" />} hoverIndicator />
                                                    <Text style={{ padding: '0 22px 0 0', fontSize: 14, fontFamily: "MetricHPE-Web-Regular" }}>Chat HPE v{constClass.CHATHPE_VERSION_NUMBER}</Text>
                                                </CardFooter>
                                            </Card>
                                        }
                                    />
                                </Tip>
                            </Box>
                            {/* <Button disabled={!linkDisable} label="Add Chat +" reverse onClick={() => { newChat() }} style={{ fontFamily: "MetricHPE-Web-Bold", background: '#01A982', border: 'none', fontSize: '16px', color: '#FFFFFF' }} /> */}
                            {/* <Button alignSelf="start" style={{ backgroundImage: "url('" + localStorage.getItem("promptBackUrl") + "')", backgroundSize: "50px 50px", width: '50px', height: '50px', borderRadius: '50%', color: '#fff', cursor: 'auto' }}>{initials ? showInitials : ''}</Button> */}
                        </Box>
                    </Box>
                    {/* <Box direction='row' justify='between' border={{ side: 'top', color: (`${theme}` === 'light' ? '#0000001F' : '#FFFFFF1F') }} style={{ padding: '8px 24px 8px 24px' }}>
                        <Box>
                            <Text style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Medium" }}>Session Name</Text>
                            <Text style={{ fontSize: 14, fontFamily: "MetricHPE-Web-Regular" }}>Chat Mode</Text>
                        </Box>
                        <Box direction='row'>
                            <Button icon={<Bookmark size='20px'/>}></Button>
                            <Button icon={<More size='20px'/>}></Button>
                        </Box>
                    </Box> */}
                    
                    <Box style={{ padding: '8px 32px 8px 32px' }} flex onScroll={handleNotificationLoad} overflow="auto" ref={scrollableDivRef}>
                        <StatusNotification botResponse={botResponse} streamMessage={streamMessage} streaming={streaming} messageToRegenerate={regenerateMessage} onNotificationLoadHandler={handleNotificationLoad} sessionChatHistoryCallback={getSessionChatHistory} chatHistory={getChatHistory} userResponse={messages} chatHPE_bot_data={chatBotData} userInput={messages[messages?.length - 1]} />
                    </Box>
                    <Box direction='row' align="center" alignSelf='end' style={{ width: '100%', paddingBottom: '0px' }}>

                        {isScrollable &&
                            <Button icon={<LinkBottom size='16px' />} onClick={() => { scrollToBottom(); textareaRef.current.focus(); }}
                                className={`${theme} scrollToBottomButton ${isScrollable ? 'easeIn' : 'easeOut'}`}
                                plain={false} alignSelf="center"
                                margin={{ horizontal: "small" }} style={{ borderRadius: '100%', marginLeft: '50%', padding: '8px' }}
                            />
                        }

                        {(isRegenerateButtonVisable && !isTokenLimitReached) &&
                            <Button onClick={regenerateMessage} className={`${theme} regenButton`} label="Regenerate"
                                icon={<Update size='16px' />} style={{ marginLeft: 'auto', marginRight: 30 }}
                            />
                        }

                    </Box>
                    <Box style={{ padding: '8px 32px 8px 32px', position: "relative", bottom: `${yoffset}%`, transition: '1s' }}>
                        <Box direction="column" fill="horizontal" className={`${theme} demo`}>
                            <Box align="center" alignSelf='center' direction="row" fill="horizontal">
                                <TextArea
                                    ref={textareaRef}
                                    plain
                                    style={{ height: textAreaHeight }}
                                    placeholder={placeholderText}
                                    value={newMessage}
                                    {...props}
                                    onChange={handleChange}
                                    aria-label="text area"
                                    size='small'
                                    disabled={botResponse || !linkDisable || isTextBarDisabled || isTokenLimitReached}
                                    border="none"
                                    resize={false}
                                    focusIndicator={false}
                                    onClick={() => { moveTitleToDown() }}
                                    onKeyDown={onKeyDownHandler}
                                    rows={1}
                                />
                               {setTokenLimitMeterVisable && (
                                    (tokensUsed/localStorage.getItem('tokenUsageTotal'))*100 >= 60 ?
                                    (
                                        <Tip
                                            plain
                                            content={
                                                <Box width="medium" className={`${theme} warningBox`}>
                                                    <Notification
                                                        status="warning"
                                                        message={(tokensUsed/localStorage.getItem('tokenUsageTotal'))*100 >= 100 ? 
                                                            "You have used all your tokens allowed for Chat HPE." : 
                                                            "You are close to using all your allowed tokens in Chat HPE."
                                                        }
                                                    />
                                                </Box>
                                            }
                                            dropProps={{ align: { right: 'right', bottom: 'top' } }}
                                        >
                                            <Meter max={totalTokens} size="28px" type="circle" round thickness="xsmall" value={meterValue} color={getColorLevel((meterValue / totalTokens) * 100)} background={(`${theme}` === 'light' ? '#CCCCCC' : '#555555')} />
                                        </Tip>
                                    ) : null
                                )}

                                {isSendPromptButtonVisable && (
                                    <Button
                                        icon={<Send size="medium" color={(`${theme}` === 'light' ? '#01A982' : '#01A982')} />}
                                        onClick={handleSendMessage}
                                        disabled={!newMessage.length || isSendPromptButtonDisabled}
                                    />
                                )}
                            </Box>
                            {isActionBarVisible && <Box className={`${theme} file-action-bar`} justify='between' border='top' direction="row"
                                fill="horizontal">
                                <Box direction='row'>
                                    <Button onClick={attachFile}
                                        disabled={isAttachButtonDisabled || isTokenLimitReached}
                                        className={`${theme} sample-prompt-button`} 
                                        icon={<Attachment className={`${theme} sample-prompt-button-icon`}
                                            size='medium' />} label='Add Files'></Button>
                                    {
                                        localStorage.getItem("appIdValue") === '2' ? (
                                            <Button
                                                onClick={() => { startUploadProcess(); setProgressBarType('progress') }}
                                                disabled={isProcessButtonDisabled}
                                                className={`${theme} sample-prompt-button`}
                                                icon={<Cpu className={`${theme} sample-prompt-button-icon`} size='medium' />}
                                                label='Process Files'></Button>
                                        ) : localStorage.getItem("appIdValue") === '3' && localStorage.getItem("fileList") === null ? (
                                            <Button
                                                onClick={() => { startUploadProcess(); setProgressBarType('summarize'); setNewMessage('') }}
                                                disabled={isSummarizeButtonDisabled}
                                                className={`${theme} sample-prompt-button`}
                                                icon={<Send className={`${theme} sample-prompt-button-icon`} size='medium' />}
                                                label='Summarize'></Button>
                                        ) : <Button
                                            onClick={() => { handleSendMessage(); setNewMessage(''); }}
                                            disabled={isSummarizeButtonDisabled || newMessage===''} //CODE CHANGE FOR CHATHPE-719   08/05/24
                                            className={`${theme} sample-prompt-button`}
                                            icon={<Send className={`${theme} sample-prompt-button-icon`} size='medium' />}
                                            label='Summarize'></Button>
                                    }
                                </Box>
                                <Box width={{ max: '55%' }} overflow="auto" align='center' alignSelf='center' direction='row'>
                                    {userSessionFiles === null ? (
                                        allFiles.map((fileName, index) => (
                                            <Tag
                                                key={index}
                                                name={truncateName(fileName.name)}
                                                size="small"
                                                title={fileName.name}
                                                className={`${theme} unprocessed-file-name-tag`}
                                                style={{
                                                    backgroundColor: fileName.type === 'processed' ? (`${theme}` === 'light' ? '#17EBA0' : '#008567') : fileName.type === 'saved' ? (`${theme}` === 'light' ? '#CCCCCC' : '#555555') : (`${theme}` === 'light' ? '#FFBC44' : '#9B6310') // Code change for ChatHPE-198
                                                }}
                                                onRemove={fileName.type === 'processed' ? null : () => handleRemove(index)}
                                            />
                                        ))
                                    ) : (
                                        allFiles.map((fileName, index) => (
                                            <Tag
                                                key={index}
                                                name={truncateName(fileName.name)}
                                                size="small"
                                                title={fileName.name}
                                                className={`${theme} processed-file-name-tag`}
                                                style={{
                                                    backgroundColor: fileName.type === 'processed' ? (`${theme}` === 'light' ? '#17EBA0' : '#008567') : fileName.type === 'saved' ? (`${theme}` === 'light' ? '#CCCCCC' : '#555555') : (`${theme}` === 'light' ? '#FFBC44' : '#9B6310') // Code change for ChatHPE-198
                                                }}
                                                onRemove={fileName.type === 'processed' ? null : () => handleRemove(index)}
                                            />
                                        ))
                                    )}
                                </Box>
                            </Box>}
                        </Box>
                        <Footer justify="center" pad="4px">
                            <Box>
                                <Text textAlign="center" size="xsmall" style={{ fontSize: 14, fontFamily: "MetricHPE-Web-Light" }}>
                                    AI-Generated content may be inaccurate. © Copyright 2024 Hewlett Packard Enterprise Development LP
                                </Text>
                            </Box>
                        </Footer>
                    </Box>
                </Box>
            </Box>
        </Grommet>
    );
};

Horizontal.args = {
    full: true,
};

export default {
    title: 'Utilities/Collapsible/Horizontal',
};