import React, { useContext, useImperativeHandle, useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
// import styled from 'styled-components';
import {Box, Button,Layer, Footer, Text, TextArea, Header, Heading, ResponsiveContext,Tip,TextInput,RadioButtonGroup, Grommet,RangeInput,Select,CheckBox} from 'grommet';
import { CircleInformation, RotateRight } from 'grommet-icons';
// import { deepMerge } from 'grommet/utils';
import * as constClass from '../Constants';
import { checkTokenExpired } from '../service/TokenService';
import useRegenerateToken from '../components/RenegerateToken';
import { useTheme } from '../contexts/ThemeContext';
import LoadingIcon from '../dialog/LoadingIcon';
// import { fetchWithRetry } from '../service/FetchWithRetry';


const SettingsPopup = React.forwardRef((props, ref) => {
    const navigate = useNavigate();
    const size = useContext(ResponsiveContext);

    const [isSuccessful, setIsSuccessful] = useState(false);
    const [open, setOpen] = useState(false);
    const [responseLength, setResponseLength] = useState(props.allSessions.max_response_tokens);
    const [creativityLevel, setCreativityLevel] = useState(props.allSessions.temperature);
    const [assistBehaviour, setAssistBehaviour] = useState(props.allSessions.behaviour);
    const [chunkSize, setChunkSize] = useState(props.allSessions.chunkSize);
    const [chunkOverlap, setChunkOverlap] = useState(props.allSessions.chunkOverlap);
    const [isChunkChange, setIsChunkChange] = useState(false);
    const [searchType, setSearchType] = useState(props.allSessions.searchType);
    const [topN, setTopN] = useState(props.allSessions.topN);
    const [isBulleted, setIsBulleted] = useState(props.allSessions.is_bulleted);
    const [isConcise, setIsConcise] = useState(props.allSessions.is_concise);
    const [doubleSummarise, setDoubeSummarise] = useState(props.allSessions.double_summarise);
    const [maxChunkValue, setMaxChunkValue] = useState();
    const [maxChunkOverlap, setMaxChunkOverlap] = useState();
    const [maxResponseLength, setMaxResponseLength] = useState();
    const [session, setSession] = useState();
    const [value, setValue] = useState('gpt-4o');
    const accessToken = localStorage.getItem('accessToken');
    const { getNewToken } = useRegenerateToken();
    const spinnerRef = useRef();
    const originalChunkSize = useRef(props.allSessions.chunkSize)
    const originalChunkOverlap = useRef(props.allSessions.chunkOverlap)
    // const [isFavourite, setIsFavourite] = useState(props.allSessions.isFavourite || false);

    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [displayCreativeAlertMessage, setDisplayCreativeAlertMessage] = useState(false);
    const [displayChunkSizeAlertMessage, setDisplayChunkSizeAlertMessage] = useState(false);
    const [displayChunkOverlapMessage, setDisplayChunkOverlapMessage] = useState(false);
    const [displayResponseLengthMessage, setDisplayResponseLengthMessage] = useState(false);
    const [displayTopNMessage, setDisplayTopNMessage] = useState(false);

    


    const loading = (e) => spinnerRef.current.LoadingStart(e);

    const onOpen1 = (sess) => {
        if (localStorage.getItem('appIdValue') === '3') {
            setMaxChunkValue(2000);
        }
        // console.log(sess);
        setSession(sess);
        setResponseLength(sess.max_response_tokens)
        setCreativityLevel(sess.temperature)
        setAssistBehaviour(sess.behaviour)
        // if(localStorage.getItem('appIdValue')=='2'){
        setChunkOverlap(sess.chunkOverlap);
        setChunkSize(sess.chunkSize);
        originalChunkOverlap.current = sess.chunkOverlap
        originalChunkSize.current = sess.chunkSize
        setSearchType(sess.searchType);
        setTopN(sess.topN);
        // }
        // if(localStorage.getItem('appIdValue') == '3'){
        setIsBulleted(sess.is_bulleted);
        setIsConcise(sess.is_concise);
        setDoubeSummarise(sess.double_summarise);
        // setIsFavourite(sess.isFavourite);
        // }
       // // setValue(sess.model) need to be revisited
       setValue('gpt-4o')
        setOpen(true)


        validateChunkOverlap();
        validateChunkSize();
        validateCreativity();
        validateResponseLength();
        validateTopN();
    };
    const { theme } = useTheme();
    const onClose = () => setOpen(undefined);

    const closeFeedbackModal = () => {
        setTimeout(() => {
            setOpen(false);
            setIsSuccessful(false);
        }, 2000);
    };

    const [chatBotData, setChatBotData] = useState(props.chatHPE_bot_data)

    useImperativeHandle(ref, (e) => ({
        onOpen1(e) {
            onOpen1(e)
        }
    }));

    const responseLengths = {
        "gpt-3.5-turbo": 1228, //previously 4000
        "gpt-3.5-turbo-16k": 4915, //previously 16000
        "gpt-4": 2457, //previously 8000
        "gpt-4-32k": 4500, //previously 32000 // 9830 CODE CHANGE FOR CHATHPE-671
        "gpt-4o":4096
    };


    //START CODE CHATHPE-213      14/02/2024      
    useEffect(() => {
        if (localStorage.getItem('appIdValue') === '2') {
            if (value && topN) {
                const responseLength = responseLengths[value];
                let maxChunkSize = Math.floor(responseLength / topN);
                if (maxChunkSize > 1500) {
                    maxChunkSize = 1500;
                }
                maxChunkSize = Math.max(maxChunkSize, 1500);
                setMaxChunkValue(maxChunkSize);
            }
        }   
    }, [value, topN]);

    //START CODE    CHATHPE-487
    useEffect(()=>{
        if(responseLength>responseLengths[value]){
            setResponseLength(responseLengths[value]);
        }
        // console.log('resposndasindad: ', value, responseLength, responseLengths[value]);
    }, [value, responseLength]);
    //END CODE

    useEffect(() => { /* START CODE CHATHPE-278 , 433     14/02/2024  */
        //CHATHPE-433 -> after the maximum calculation logic now i'm setting the max value to the chunk overlap variable. 
        //this will fix the bug
        setChunkSize(Math.min(chunkSize, maxChunkValue));
        let maxChunkOverlapValue = Math.floor(maxChunkValue * 0.1);
        if(chunkOverlap > maxChunkOverlapValue){
            setChunkOverlap(maxChunkOverlapValue);
        }
        setMaxChunkOverlap(maxChunkOverlapValue);
    }, [maxChunkValue]);
    //END CODE
    
    //START CODE    CHATHPE-382     Date: 26/02/2024
    useEffect(() => {
        setMaxResponseLength(responseLengths[value]);
        if (responseLength > responseLengths[value]) {
            const responseLen = responseLengths[value];
            setResponseLength(responseLen);
        }
    }, [value])
    //END CODE
    
    //END CODE

    //START CODE CHATHPE-233  14/02/2024
    useEffect(() => {
        setIsChunkChange(true);
        // console.log(chunkOverlap, chunkSize);
    }, [chunkOverlap, chunkSize]);
    //END CODE

    const sessionSettings = async () => {
        loading(true);
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                callModSessApi()
            }
        } else {
            callModSessApi();
        }
        async function callModSessApi() {
            try {
                const chat_data = JSON.parse(localStorage.getItem('chatBotData'))
                // TEMP REMOVAL OF 30 SEC TIMEOUT
                const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.MOD_SESS, {
                // const response = await fetch(constClass.MOD_SESS, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    },
                    body: JSON.stringify({ appId: localStorage.getItem('appIdValue'), sessionId: session.sessionId, charLimit: responseLength, creativity: creativityLevel, behaviour: assistBehaviour, model: value, chatHPE_bot_data: chat_data, chunkSize: chunkSize, chunkOverlap: chunkOverlap, search_type: searchType, topN: topN })
                });
                const newData = await response.json();

                //Added condition for Authentication to fix the uncaught runtime error. Issue 146
                if (response.status===200) {
                    // console.log('after modification: ,', newData);
                    props.settingsCallBackHandler(newData, session.sessionId)
                    setAssistBehaviour(newData.all_sess.behaviour);
                    setValue(newData.all_sess.model);
                    setCreativityLevel(newData.all_sess.temperature);
                    setResponseLength(newData.all_sess.max_response_tokens);
                    setChunkSize(newData.all_sess.chunkSize);
                    setChunkOverlap(newData.all_sess.chunkOverlap);
                    setSearchType(newData.all_sess.searchType);
                    setTopN(newData.all_sess.topN);
                    // setIsConcise(newData.all_sess.is_concise);
                    // setIsBulleted(newData.all_sess.is_bulleted);
                    // setDoubeSummarise(newData.all_sess.double_summarise);
                    // localStorage.removeItem('chatBotData')
                    // const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
                    // localStorage.setItem('chatBotData', chatBotDataString);
                    loading(undefined);
                    setOpen(false);
                    // console.log(originalChunkOverlap.current, chunkOverlap, 'j');
                    // console.log(originalChunkSize.current, chunkSize, 'j');

                    //START CODE CHATHPE-233 14/02/2024 
                    if (localStorage.getItem('appIdValue') === '2' && (originalChunkOverlap.current !== Number(chunkOverlap) || originalChunkSize.current !== Number(chunkSize))) {
                        props?.embedding(true)
                        props?.createEmbedApi()

                        setIsChunkChange(false);
                    }
                    //END CODE
                }
                else {
                    navigate('/error', { state: { errorMessage: newData['message'] } });
                }
            } catch (error) {
                console.error('An error occurred:', error)
                navigate('/error')
            }
        }
    };

    const resetToDefault = () => {
        if(localStorage.getItem('appIdValue')==='1'){
            setValue('gpt-4o');
            setResponseLength(4096);
            setAssistBehaviour('You are helpful AI Assistant.')
            setCreativityLevel(0.7);
        }
        else if(localStorage.getItem('appIdValue')==='2'){
            setResponseLength(4096);
            setCreativityLevel(0);
            setChunkSize(1000);
            setChunkOverlap(100);
            setSearchType('hybrid');
            setTopN(10);
            setAssistBehaviour('You are helpful AI Assistant.')
            setValue('gpt-4o')
        }
        else{
            setValue('gpt-4o')
            // setIsConcise('True');
            // setDoubeSummarise('False');
            setCreativityLevel(0);
            setChunkSize(2000);
        }
    };

    const postMethods = [
       // { label: 'GPT-3.5', value: 'gpt-3.5-turbo' },
       // { label: 'GPT-4', value: 'gpt-4-32k' },
        {label:'GPT-4o',value:'gpt-4o'}
        
        // Commenting out until we have an advanced menu
        // { label: 'GPT-3.5 Turbo', value: 'gpt-3.5-turbo' },
        // { label: 'GPT-3.5 Turbo 16K', value: 'gpt-3.5-turbo-16k' },
        // { label: 'GPT-4', value: 'gpt-4' },
        // { label: 'GPT-4-32K', value: 'gpt-4-32k' }
    ];

    //START CODE    CHATHPE-382, 383    Date: 26/02/2024
    useEffect(()=>{
        validateCreativity();
    }, [creativityLevel]);
    
    const validateCreativity = () => {
        let value = creativityLevel;

        const decimalCount = (value?.toString().split('.')[1] || []).length;
        
        if (value === null || value === '' || isNaN(value) || value < 0 || value > 1 || decimalCount > 1) {
            setDisplayCreativeAlertMessage(true);
        }
         else {
            setDisplayCreativeAlertMessage(false);
        }
    };

    useEffect(()=>{
        validateChunkSize();
    }, [chunkSize, maxChunkValue])

    const validateChunkSize = () => {
        let value = chunkSize;
        if (value < 500 || value > maxChunkValue) {
            setDisplayChunkSizeAlertMessage(true);
        } else {
            setDisplayChunkSizeAlertMessage(false);
        }
        if(localStorage.getItem('appIdValue')==='1'){
            setDisplayChunkSizeAlertMessage(false);
        }
    }   
    
        
    useEffect(()=>{ 
        validateChunkOverlap();
    }, [chunkOverlap, maxChunkOverlap])

    const validateChunkOverlap = () =>{
        let value = chunkOverlap;
        if((value < 10 || value > maxChunkOverlap) ){
            setDisplayChunkOverlapMessage(true);
        } 
        else{
            setDisplayChunkOverlapMessage(false);
        }
        //START CODE    CHATHPE-451         29/02/2024
        if(localStorage.getItem('appIdValue')!='2'){
            setDisplayChunkOverlapMessage(false);
        }
        //END CODE
    }

    useEffect(()=>{
        validateTopN();
    },[topN])

    const validateTopN = () => {
        let value = topN;
        if(value === null || value === '' || isNaN(value) || value < 1 || value > 10 || !Number.isInteger(Number(value))){
            setDisplayTopNMessage(true);
        }    
        else{
            setDisplayTopNMessage(false);
        }
        if(localStorage.getItem('appIdValue')!='2'){
            setDisplayTopNMessage(false);
        }
    }

    useEffect(()=>{
        validateResponseLength();
    }, [responseLength, maxResponseLength])

    const validateResponseLength = () =>{
        let value = responseLength;
        if(value === null || value === '' || isNaN(value) || value < 1 || value > maxResponseLength || !Number.isInteger(Number(value))){
            setDisplayResponseLengthMessage(true);
        }    
        else{
            setDisplayResponseLengthMessage(false);
        }
        if(localStorage.getItem('appIdValue')==='3'){
            setDisplayResponseLengthMessage(false);
        }
    }


    useEffect(()=>{
        const isSaveButtonDisabled =
        displayCreativeAlertMessage ||
        displayChunkSizeAlertMessage ||
        displayChunkOverlapMessage ||
        displayResponseLengthMessage ||
        displayTopNMessage;
    
        setSaveButtonDisabled(isSaveButtonDisabled);
    }, [displayChunkOverlapMessage, displayChunkSizeAlertMessage, displayCreativeAlertMessage, displayResponseLengthMessage, displayTopNMessage])


    //END CODE


    return (
        <Grommet>
            <LoadingIcon ref={spinnerRef} />
            {open && (
                <Layer className={`${theme} settingPane`} style={{ borderRadius: '12px', border: 'none' }} onClickOutside={onClose} onEsc={onClose}>
                    <Box
                        fill="vertical"
                        className={`${theme} settingPane`}
                        overflow="auto"
                        width={!['medium', 'large'].includes(size) ? 'medium' : undefined}
                        pad="medium"
                        gap="medium"
                    >
                        <Header
                            direction="column"
                            align="start"
                            gap="xxsmall"
                            pad={{ horizontal: 'xxsmall' }}
                        >
                            <Heading style={{ fontSize: 24 }} size="small" margin="none">
                                Session Settings
                            </Heading>
                            <Text style={{ fontSize: 16 }} size='xsmall'>
                                The below settings can be modified for an individual chat session. Changes will apply only to the chat session.
                            </Text>
                        </Header>
                        <Box style={{ minHeight: '20px' }}>
                            <Box direction='row'>
                                <Text style={{ fontSize: 16 }} size='xsmall'>Large language model</Text>
                                <Tip
                                    content={
                                        <Box width={{ max: 'medium' }} background="#fff" border="1px solid #000" pad="xsmall">
                                            <>
                                                <Text size="small">Choose the large language model you would like to chat with. Currently, only GPT-4o is available.</Text>
                                            </>
                                        </Box>
                                    }
                                    dropProps={{ align: { left: 'right', top: 'top' } }}
                                >
                                    <Button pad="none" style={{ paddingLeft: '10px' }} icon={<CircleInformation size="18px" />} />
                                </Tip>
                            </Box>
                        </Box>
                        {/* <CheckBox
                                checked={isFavourite}
                                label="Favourite"
                                onChange={(event) => setIsFavourite(event.target.checked ? 1 : 0)}
                                toggle
                            /> */}
                        <RadioButtonGroup
                            
                            name="radio"
                            background={(`${theme}` === 'light' ? '#F7F7F7' : '#222222')}
                            options={postMethods}
                            direction="row"
                            value={value}
                            onChange={(event) => {
                                setValue(event.value)
                            }}
                        />
                        {(localStorage.getItem('appIdValue') === '1' || localStorage.getItem('appIdValue') === '2') &&
                            <Box style={{ minHeight: '120px' }}>
                                <Box style={{ minHeight: '20px' }} direction='row' align='center' alignContent='center'>
                                    <Text style={{ fontSize: 16 }}>AI Assistant Behavior</Text>
                                    <Tip
                                        content={
                                            <Box style={{ minHeight: '50px' }} width={{ max: 'medium' }} background="#fff" border="1px solid #000" pad="xsmall">
                                                <>
                                                    <Text style={{ fontSize: 16 }} size="small">
                                                        Provide your AI assistant with a specific persona and/or requirements for generating appropriate responses to your prompts. For example:
                                                    </Text>
                                                    <Box margin={{ vertical: 'small' }}>
                                                        <Text size="small">• The point of view the AI assistant should assume</Text>
                                                        <Text size="small">• The expected tone of the responses</Text>
                                                        <Text size="small">• The intended audience</Text>
                                                        <Text size="small">• How you would like the responses organized</Text>
                                                    </Box>
                                                    <Text style={{ fontSize: 16 }} size="small">Note: This counts towards the prompt character limit.</Text>
                                                </>
                                            </Box>
                                        }
                                        dropProps={{ align: { left: 'right', top: 'top' } }}
                                    >
                                        <Button pad="none" style={{ paddingLeft: '10px' }} icon={<CircleInformation size="18px" />} />
                                    </Tip>
                                </Box>
                                <Box style={{ minHeight: '50px' }}>
                                    <Text style={{ fontSize: 16, padding: '10px 0' }} size='xsmall'>Maximum characters: 420</Text>
                                    <TextArea
                                        style={{ fontSize: 16, minHeight: '50px' }}
                                        id="example1-id"
                                        name="example1"
                                        placeholder="Placeholder input prompt"
                                        size='xsmall'
                                        maxLength="420"
                                        value={assistBehaviour}
                                        resize={false}
                                        onChange={event => setAssistBehaviour(event.target.value)}
                                    />
                                </Box>
                            </Box>}
                        {localStorage.getItem('appIdValue') === '2' &&
                            <Box style={{ minHeight: '50px' }}>
                                <Box direction='column' style={{ minHeight: '50px', position: 'relative' }}>
                                    <Box style={{paddingBottom: '10px', minHeight: '10px'}} direction='row' align='center' alignContent='center'>
                                        <Text style={{  fontSize: 16 }}>Search Type</Text>
                                        <Tip
                                            content={
                                                <Box width={{ max: 'medium' }} background="#fff" border="1px solid #000" pad="xsmall">
                                                    <>
                                                        <Text style={{ fontSize: 16 }} size="small">
                                                            The method or algorithm used to retrieve relevant information or context from the embeddings.
                                                        </Text>
                                                    </>
                                                </Box>
                                            }
                                            dropProps={{ align: { left: 'right', top: 'top' } }}
                                        >
                                            <Button pad="none" style={{ paddingLeft: '10px' }} icon={<CircleInformation size="18px" />} />
                                        </Tip>
                                    </Box>
                                    <Select
                                    options={['vector', 'key word', 'hybrid', 'semantic hybrid']}
                                    value={searchType}  
                                    dropProps={{background: `${theme}` === 'light' ? '#F7F7F7' : '#222222'}}
                                    // onChange={({ option }) => setValue(option)}
                                    onChange={({ option }) => setSearchType(option)}
                                    />
                                </Box>
                            </Box>}
                        {/* {localStorage.getItem('appIdValue') === '3' &&
                            <Box style={{ minHeight: '50px', padding: '10px 0'}}>
                                <Box direction='row' style={{ position: 'relative', padding: '10px 0' }}>
                                    <Box alignSelf='start' direction='row'>
                                        <Text style={{ fontSize: 16 }} size='xsmall'>Is Concise</Text>
                                        <Tip
                                            content={
                                                <Box width={{ max: 'medium' }} background="#fff" border="1px solid #000" pad="xsmall">
                                                    <>
                                                        <Text style={{ fontSize: 16 }} size="small">
                                                            Indicates whether the summary should be brief and to the point, capturing essential information in a succinct manner.
                                                        </Text>
                                                    </>
                                                </Box>
                                            }
                                            dropProps={{ align: { left: 'right', top: 'top' } }}
                                        >
                                            <Button pad="none" style={{ paddingLeft: '10px' }} icon={<CircleInformation size="18px" />} />
                                        </Tip>
                                    </Box>
                                </Box>
                                <Select
                                    options={['True', 'False']}
                                    value={isConcise==='True'?'True':'False'}
                                    onChange={({ option }) => {
                                        setIsConcise(option==='True'?'True':'False');
                                    }}
                                />

                            </Box>} */}
                        {/* {localStorage.getItem('appIdValue')==='3' && 
                        <Box>
                            <Box direction='row' style={{ position: 'relative', padding: '10px 0' }}>
                                <Box alignSelf='start' direction='row'>
                                    <Text style={{ fontSize: 16 }} size='xsmall'>Is Bulleted</Text>
                                    <Tip
                                        content={
                                            <Box width={{ max: 'medium' }} background="#fff" border="1px solid #000" pad="xsmall">
                                                <>
                                                    <Text style={{ fontSize: 16 }} size="small">
                                                    Determines whether the summary should be presented in a bulleted format, organizing key points into a list for clarity and ease of reading.
                                                    </Text>
                                                </>
                                            </Box>
                                        }
                                        dropProps={{ align: { left: 'right', top: 'top' } }}
                                    >
                                        <Button pad="none" style={{ paddingLeft: '10px' }} icon={<CircleInformation size="18px" />} />
                                    </Tip>
                                </Box>
                            </Box>
                            <Select
                                    options={['True', 'False']}
                                    value={isBulleted}
                                    // onChange={({ option }) => setValue(option)}
                                    onChange={({ option }) => {
                                        setIsBulleted(option);
                                    }}
                            />
                        </Box>} */}
                        {/* {localStorage.getItem('appIdValue') === '3' &&
                            <Box>
                                <Box direction='row' style={{ position: 'relative', padding: '10px 0' }}>
                                    <Box alignSelf='start' direction='row'>
                                        <Text style={{ fontSize: 16 }} size='xsmall'>Double Summarise</Text>
                                        <Tip
                                            content={
                                                <Box width={{ max: 'medium' }} background="#fff" border="1px solid #000" pad="xsmall">
                                                    <>
                                                        <Text style={{ fontSize: 16 }} size="small">
                                                            Indicates whether the document should undergo two rounds of summarization, potentially refining the summary output for improved clarity and relevance.
                                                        </Text>
                                                    </>
                                                </Box>
                                            }
                                            dropProps={{ align: { left: 'right', top: 'top' } }}
                                        >
                                            <Button pad="none" style={{ paddingLeft: '10px' }} icon={<CircleInformation size="18px" />} />
                                        </Tip>
                                    </Box>
                                </Box>
                                <Select
                                     options={['True', 'False']}
                                     value={doubleSummarise==='True'?'True':'False'}
                                     onChange={({ option }) => {
                                         setDoubeSummarise(option==='True'?'True':'False');
                                     }}
                                />
                            </Box>} */}
                        <Box style={{ minHeight: '50px' }}>                             
                            <Box direction='row' style={{ position: 'relative'}}>
                                <Box alignSelf='start' direction='row' style={{paddingBottom: '10px'}}>
                                    <Text style={{ fontSize: 16 }} size='xsmall'>Creativity Level</Text>    
                                    <Tip
                                        content={
                                            <Box width={{ max: 'medium' }} background="#fff" border="1px solid #000" pad="xsmall">
                                                <>
                                                    <Text style={{ fontSize: 16 }} size="small">
                                                        A higher creativity level (closer to 1.0) may result in more novel ideas, while a lower level (closer to 0.0) will yield more repetitive responses to be used when responses require more precision.
                                                    </Text>
                                                </>
                                            </Box>
                                        }
                                        dropProps={{ align: { left: 'right', top: 'top' } }}
                                    >
                                        <Button pad="none" style={{ paddingLeft: '10px' }} icon={<CircleInformation size="18px" />} />
                                    </Tip>
                                </Box>
                                <Box alignSelf='end' justify='end' style={{ position: 'absolute', right: 0 , }}>                                
                                <TextInput 
                                        justify="end" 
                                        direction="row" 
                                        width="55px" 
                                        style={{ padding: '5px', 'text-align': 'center' }} 
                                        value={creativityLevel} 
                                        // onChange={event => {
                                        //     let value = event.target.value;
                                        //     if(value > 1){
                                        //         value = 1;
                                        //     }
                                        //     else if(value < 0){
                                        //         value = 0;
                                        //     }
                                        //     setCreativityLevel(value);
                                        // }}
                                        onChange={event => {
                                            let value = event.target.value;
                                            setCreativityLevel(value);
                                        }}
                                        type="number"
                                        step="0.1"
                                        min="0"
                                        max="1"
                                    ></TextInput>
                                    
                                </Box>
                                
                            </Box>
                            {displayCreativeAlertMessage && (
                                    <Box style={{ marginBottom: '5px', color: '#FC5A5A', fontSize: '15px' , width: '70%'}}>
                                       * Creativity Level should be a decimal between 0 and 1
                                    </Box>
                                )} 
                            <RangeInput
                                value={creativityLevel}
                                min={0}
                                max={1}
                                step={0.1}
                                color="#7630EA"
                                className='progress'
                                onChange={event => setCreativityLevel(event.target.value)}
                            />
                        </Box>

                        {(localStorage.getItem('appIdValue') === '2' || localStorage.getItem('appIdValue') === '3') && /* CHATHPE-210   14/02/2024 */
                            <Box style={{ minHeight: '50px' }}>
                                
                                <Box direction='row' style={{ position: 'relative' }}>
                                    <Box alignSelf='start' direction='row' style={{paddingBottom: '10px'}}>
                                        <Text style={{ fontSize: 16 }} size='xsmall'>Chunk Size</Text>
                                        <Tip
                                            content={
                                                <Box width={{ max: 'medium' }} background="#fff" border="1px solid #000" pad="xsmall">
                                                    <>
                                                        <Text style={{ fontSize: 16 }} size="small">
                                                            The size or length of each segment or portion of the input text that is processed sequentially during QnA.
                                                        </Text>
                                                    </>
                                                </Box>
                                            }
                                            dropProps={{ align: { left: 'right', top: 'top' } }}
                                        >
                                            <Button pad="none" style={{ paddingLeft: '10px' }} icon={<CircleInformation size="18px" />} />
                                        </Tip>
                                    </Box>
                                    <Box alignSelf='end' justify='end' style={{ position: 'absolute', right: 0 }}>
                                        <TextInput justify="end" direction="row" width="65px" style={{ padding: '5px', 'text-align': 'center' }} value={chunkSize} 
                                        onChange={event => {
                                            setChunkSize(Number(event.target.value.split('.')[0]));
                                        }}
                                        type="number"
                                        step="1"
                                        min="500"
                                        max={maxChunkValue}>
                                        </TextInput>
                                    </Box>
                                </Box>
                                {displayChunkSizeAlertMessage && (
                                    <Box style={{ marginBottom: '5px', color: '#FC5A5A', fontSize: '15px', width: '70%' }}>
                                       * Chunk Size should be a number between 500 to {maxChunkValue}
                                    </Box>
                                )} 
                                <RangeInput
                                    value={chunkSize}
                                    min={500}
                                    max={maxChunkValue}
                                    step={1}
                                    color="#7630EA"
                                    className='progress'
                                    onChange={event => setChunkSize(event.target.value)}
                                />  
                            </Box>}
                        {localStorage.getItem('appIdValue') === '2' &&
                            <Box style={{ minHeight: '50px' }}>
                                <Box direction='row' style={{ position: 'relative'}}>
                                    <Box alignSelf='start' direction='row' style={{paddingBottom: '10px'}}>
                                        <Text style={{ fontSize: 16 }} size='xsmall'>Chunk Overlap</Text>
                                        <Tip
                                            content={
                                                <Box width={{ max: 'medium' }} background="#fff" border="1px solid #000" pad="xsmall">
                                                    <>
                                                        <Text style={{ fontSize: 16 }} size="small">
                                                            The amount of overlap between consecutive chunks of text during QnA, allowing for continuity and coherence across segments.
                                                        </Text>
                                                    </>
                                                </Box>
                                            }
                                            dropProps={{ align: { left: 'right', top: 'top' } }}
                                        >
                                            <Button pad="none" style={{ paddingLeft: '10px' }} icon={<CircleInformation size="18px" />} />
                                        </Tip>
                                    </Box>
                                    <Box alignSelf='end' justify='end' style={{ position: 'absolute', right: 0 }}>
                                        <TextInput justify="end" direction="row" width="55px" style={{ padding: '5px', 'text-align': 'center' }} value={chunkOverlap}
                                            onChange={event => {
                                                setChunkOverlap(Number(event.target.value.split('.')[0]));
                                            }}
                                            type="number"
                                            step="1"
                                            min="10"
                                            max={maxChunkOverlap}></TextInput>
                                    </Box>
                                </Box>
                                {displayChunkOverlapMessage && (
                                    <Box style={{ marginBottom: '5px', color: '#FC5A5A', fontSize: '15px', width: '70%' }}>
                                       * Chunk Overlap should be a number between 10 to {maxChunkOverlap}
                                    </Box>
                                )} 
                                <RangeInput
                                    value={chunkOverlap}
                                    min={10}
                                    max={maxChunkOverlap}
                                    step={1}
                                    color="#7630EA"
                                    className='progress'
                                    onChange={event => setChunkOverlap(event.target.value)}
                                />
                            </Box>}
                        {localStorage.getItem('appIdValue') === '2' &&
                            <Box style={{ minHeight: '50px' }}>
                                <Box direction='row' style={{ position: 'relative', padding: '10px 0' }}>
                                    <Box alignSelf='start' direction='row'>
                                        <Text style={{ fontSize: 16 }} size='xsmall'>topN</Text>
                                        <Tip
                                            content={
                                                <Box width={{ max: 'medium' }} background="#fff" border="1px solid #000" pad="xsmall">
                                                    <>
                                                        <Text style={{ fontSize: 16 }} size="small">
                                                            The number of top-ranked or most relevant results retrieved from the embeddings. to be considered during the QnA process.
                                                        </Text>
                                                    </>
                                                </Box>
                                            }
                                            dropProps={{ align: { left: 'right', top: 'top' } }}
                                        >
                                            <Button pad="none" style={{ paddingLeft: '10px' }} icon={<CircleInformation size="18px" />} />
                                        </Tip>
                                    </Box>
                                    <Box alignSelf='end' justify='end' style={{ position: 'absolute', right: 0 }}>
                                        <TextInput justify="end" direction="row" width="55px" style={{ padding: '5px', 'text-align': 'center' }} value={topN} onChange={event => {
                                                setTopN(Number(event.target.value.split('.')[0]));
                                            }}
                                            type="number"
                                            step="1"
                                            min="1"
                                            max="10"></TextInput>
                                    </Box>
                                </Box>
                                {displayTopNMessage && (
                                    <Box style={{ marginBottom: '5px', color: '#FC5A5A', fontSize: '15px', width: '70%' }}>
                                        * TopN value should be a number between 1 to 10
                                    </Box>
                                )}
                                <RangeInput
                                    value={topN}
                                    min={1}
                                    max={10}
                                    step={1}
                                    color="#7630EA"
                                    className='progress'
                                    onChange={event => setTopN(event.target.value)}
                                />
                            </Box>}
                        {(localStorage.getItem('appIdValue') === '1' || localStorage.getItem('appIdValue') === '2') &&
                            <Box style={{ minHeight: '50px' }}>
                                <Box direction='row' style={{ position: 'relative'}}>
                                    <Box alignSelf='start' direction='row' style={{paddingBottom: '10px'}}>
                                        <Text style={{ fontSize: 16 }} size='xsmall'>Response Length</Text>
                                        <Tip
                                            content={
                                                <Box width={{ max: 'medium' }} background="#fff" border="1px solid #000" pad="xsmall">
                                                    <>
                                                        <Text size="small">
                                                            You can increase or decrease the maximum length of the response provided by Chat HPE. Longer, more detailed explanations require may cause longer response times. If Chat HPE’s response is truncated, request the model to continue where it left off (e.g., 'Pick up where the last response left off', 'Continue').
                                                        </Text>
                                                    </>
                                                </Box>
                                            }
                                            dropProps={{ align: { left: 'right', top: 'top' } }}
                                        >
                                            <Button pad="none" style={{ paddingLeft: '10px' }} icon={<CircleInformation size="18px" />} />
                                        </Tip>
                                    </Box>
                                    <Box alignSelf='end' justify='end' style={{ position: 'absolute', right: 0 }}>
                                    <TextInput 
                                        justify="end" 
                                        direction="row" 
                                        width="75px" 
                                        style={{ padding: '5px', 'text-align': 'center' }} 
                                        value={responseLength} 
                                        // START CODE CHATHPE-382, 383      Date: 26/02/2024
                                        onChange={event => {
                                            setResponseLength(Number(event.target.value.split('.')[0]));
                                        }}
                                        //END CODE
                                        type="number"
                                        step="1"
                                        min="1"
                                        max={maxResponseLength}
                                    ></TextInput>
                                    </Box>
                                </Box>
                                {displayResponseLengthMessage && (
                                    <Box style={{ marginBottom: '5px', color: '#FC5A5A', fontSize: '15px', width: '70%' }}>
                                        * Response Length value should be a number between 1 to {maxResponseLength}
                                    </Box>
                                )}
                                <RangeInput
                                    value={responseLength}
                                    min='1'
                                    max={maxResponseLength}
                                    step='1'
                                    color="#7630EA"

                                    className='progress'
                                    onChange={event => setResponseLength(event.target.value)}
                                />
                            </Box>}
                            
                        <Box>
                            <Footer
                                margin={{ top: 'medium', bottom: 'small' }}
                                direction="row"
                                justify="end"
                                gap="small"
                            >
                                {/* Reusing feedbackBoxCancelButton class. Mostly its only because f the same style */}
                                <Button className={`${theme} feedbackBoxCancelButton`} style={{fontSize: 16, fontFamily: "MetricHPE-Web-Bold"}} label="Reset to default" icon={<RotateRight size='medium' />} onClick={resetToDefault} />
                                <Button disabled={saveButtonDisabled} style={{ background: '#01A982', border: 'none', fontSize: '16px', fontWeight: 'Bold', color: '#fff' }} label="Save" primary type="submit" onClick={sessionSettings} />
                            </Footer>
                        </Box>
                    </Box>
                </Layer>
            )
            }
        </Grommet >
    );
});

export default SettingsPopup;