import React, { useContext, useImperativeHandle } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { convert } from 'html-to-text';

const DownloadChat = React.forwardRef((props, ref) => {
  useImperativeHandle(ref, (e,sess_name, chat_hist) => ({
    downloadCsvFile(e,sess_name, chat_hist) {
      downloadCsvFile(e,sess_name, chat_hist);
    },
  }));

  // Start CHATHPE-82 chat history csv to pdf 
  const downloadCsvFile = (e,sess_name, chat_hist) => {
    // Convert chatHistory to a formatted PDF string
    const pdfData = formatChatHistoryAsPDF(chat_hist);

    // Create a Blob with the data
    const blob = new Blob([pdfData], {type: 'application/pdf'});

    // Create a link element
    const link = document.createElement('a');

    const timestamp = Date.now();
    const sess_name_value = sess_name.split(' ').join('_');
    // Set the download attribute and file name
    link.download = `${sess_name_value}_chat_history_${Date.now()}.pdf`;

    // Create a URL for the Blob and set it as the href attribute of the link
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const formatChatHistoryAsPDF = (chatHistory) => {
    const headers = ['Create Time', 'Role', 'Message', 'MessageId'];
    const rows = chatHistory.map((entry) => [
      entry.createTime,
      entry.role,
      entry.message,
      entry.messageId,
    ]);

    // Create a new jsPDF instance
    const pdf = new jsPDF();
				 
    // Set table column widths
    const columnWidths = [40, 40, 40, 40];
	

    // Add headers to the PDF table
    pdf.autoTable({
      head: [headers],
      theme: 'striped', // you can change the theme
      columnStyles: { 0: { cellWidth: columnWidths[0] }, 1: { cellWidth: columnWidths[1] }, 2: { cellWidth: columnWidths[2] }, 3: { cellWidth: columnWidths[3] } },
    });

    // Add data rows to the PDF table
    rows.forEach(row => {
      row[2]=convert(row[2]);
      pdf.autoTable({
        body: [row.map(escapeCsvValue)],
        columnStyles: { 0: { cellWidth: columnWidths[0] }, 1: { cellWidth: columnWidths[1] }, 2: { cellWidth: columnWidths[2] }, 3: { cellWidth: columnWidths[3] } },
      });
    });

    // Save or open the PDF
    const pdfBlob = pdf.output('blob');
    return pdfBlob;
  };
// End CHATHPE-82

  //START CODE  CHATHPE-497   date: 08/03/2024
  const escapeCsvValue = (value) => {
    const stringValue = String(value);
  
    if (stringValue.includes(',') || stringValue.includes('\n')) {
      return `"${stringValue.replace(/"/g, '""')}"`;
    }
    return stringValue;
  };
  //END CODE 

  return <div></div>;
});
export default DownloadChat;
