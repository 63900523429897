import { Grommet, Box, Heading, Button } from "grommet";
import { Hpe } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//Changed the logic of the entire Error page to have automatic timer to redirect, button to redirect to login and displays custom messages to users based on the error.
const ErrorPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [countdown, setCountdown] = useState(10);
    const errorMessage = location.state?.errorMessage || 'An error occurred.';
    let countdownInterval;
      const navigateToLogin = () => {
        localStorage.clear();
        console.log('Navigating to login page...');
        navigate('/');
    };
    
    useEffect(() => {
      countdownInterval = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      if (countdown === 0) {
          clearInterval(countdownInterval);
          let failedAttempts = Number(sessionStorage.getItem('failedAttempts')??0)
          // Start code change for ChatHPE-496
          if (failedAttempts < 3) {
            sessionStorage.setItem('failedAttempts',failedAttempts+1)
            setCountdown(10);
            navigateToLogin()
            console.log(failedAttempts,'failed');
          }
      }
      return () => clearInterval(countdownInterval);
  }, [countdown]);
  // End code change for ChatHPE-496
    return (
        <Grommet full>
        <Box fill align="center" justify="center" alignSelf="center" direction="row">
            <Box width="large">
                <Box align="center" pad="large" border="small" round>
                    <Hpe size="medium" color="#01A982" />
                    <Heading level={2} weight={900} color="red">
                    {errorMessage} We've already notified our support team about this issue, and they are actively working on resolving it.
                    </Heading>
                </Box>
            <Box justify="center" direction="column"><p style={{ fontFamily: "MetricHPE-Web-Regular"}}>Redirecting to the login page in {countdown} seconds. Click on the button below to proceed immediately.</p>
            <Button label="Login with SSO" reverse onClick={() => { 
            navigateToLogin()
          }
            } style={{ background: '#01A982', border: 'none', fontSize: '14px', color: '#fff', marginTop: '20px', alignSelf:'end'}} />
            </Box>
            </Box>
        </Box>
    </Grommet>
    )
}

export default ErrorPage;