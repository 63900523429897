import React, { useEffect, useState } from 'react';
import { Grommet, AnchxBoxor, Header, Nav, Box, dark, Button } from 'grommet';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { PageLayout } from './components/PageLayout';
import './App.css';
import { Horizontal } from './Dashboard/HeaderNav';
import { Redirect, Route, Routes, BrowserRouter, Navigate, useHref, useNavigate } from 'react-router-dom'
import LoginApp from './login';
import { useTheme } from './contexts/ThemeContext';
import ErrorPage from './pages/ErrorPage';
import NotFound from './pages/NotFound';
import { SignInButton } from './components/SignInButton';
import { useIsAuthenticated } from '@azure/msal-react';
import ErrorBoundry from './Error/ErrorBoundry';
import { useIdleTimer } from 'react-idle-timer'
import { checkTokenExpired } from './service/TokenService';
import { Layer } from 'grommet';
import { LoginHelper } from './LoginHelper';
import * as constClass from './Constants';

// const customTheme = {
//   global: {
//     colors: {
//       // Overriding existing colors
//       'accent-1': '#6FFFB0',
//       'accent-2': '#7FFFB0',
//       'accent-3': '#8FFFB0',
//       'accent-4': '#9FFFB0',
//       'neutral-1': '#10873D',
//       'neutral-2': '#20873D',
//       'neutral-3': '#30873D',
//       'neutral-4': '#40873D',
//       focus: '#000',
//       // Setting new colors
//       blue: '#00C8FF',
//       green: '#17EBA0',
//       teal: '#82FFF2',
//       purple: '#F740FF',
//       red: '#FC6161',
//       orange: '#FFBC44',
//       yellow: '#FFEB59',
//       // you can also point to existing grommet colors
//       brightGreen: 'accent-1',
//       deepGreen: 'neutral-2',
//       // Changing default text color,
//       // all colors could be either a string or a dark and light object
//       text: {
//         dark: 'teal',
//       },
//       background: {
//         dark: "#1C1C1C",
//         light: "#FFFFFF"
//       },
//       border: {
//         light: "#000",
//         dark: "#f00"
//       }
//     },
//     focus: {
//       shadow: {
//         dark: "red",
//         light: "blue"
//       }
//     }
//   },
//   button: {
//     border: {
//       radius: undefined,
//       color: '#2196f3',
//     },
//     disabled: {
//       color: 'orange',
//       border: {
//         color: 'orange',
//       },
//       extend: `border: 10px dashed red;`,
//     },
//     padding: {
//       vertical: '12px',
//       horizontal: '24px',
//     },
//     primary: {
//       color: '#f00',
//       active: {
//         border: {
//           color: 'red',
//         },
//         extend: `background: cadetblue;`,
//       },
//       extend: `background: skyblue; border: 5px dotted green;`,
//     },
//     extend: (props) => {
//       let extraStyles = '';
//       if (props.primary) {
//         extraStyles = `
//             text-transform: uppercase;
//           `;
//       }
//       return `
//           font-size: 12px;
//           font-weight: bold;
//           ${extraStyles}
//         `;
//     },
//   },
//   tip: {
//     drop: {
//       background: { color: 'light-1' },
//       margin: { vertical: 'small' },
//       round: 'xsmall',
//       elevation: 'large',
//     },
//     content: {
//       elevation: 'none',
//       background: 'none',
//       pad: 'small',
//     },
//   },
// };


/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */

//START CODE CHATHPE-699
const MainContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <LoginApp />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginHelper />
      </UnauthenticatedTemplate>
    </div>
    
  );
};

const PageContent = () => {
  return (
    <MainContent />
  )
};
//END CODE CHATHPE-699

function App() {
  const { theme } = useTheme();
  const navigate = useNavigate()
  const isAuthenticated = useIsAuthenticated();
  const [show, setShow] = useState(false);
  const [logoutTriggered, setLogoutTriggered] = useState(false);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
//   const onIdle = () => {
// // Start code change for ChatHPE-574
//     if (!logoutTriggered) {
//       if (checkTokenExpired()) {
//         callLogoutApi();
//       }
//       callLogoutApi();
//       setLogoutTriggered(true);
//     }
//   };

  // const { getRemainingTime } = useIdleTimer({
  //   onIdle,
  //   onActive: () => {
  //     setShow(false);
  //     // Reset logout trigger when the user becomes active again
  //     setLogoutTriggered(false);
  //   },
  //  timeout: 1200000,
  //   throttle: 500
  // });

  // useEffect(() => {
  //   let interval;
  //   interval = setInterval(() => {
  //     const remainingTime = getRemainingTime();
  //     const minutes = Math.floor(remainingTime / 60000);
  //     setRemainingMinutes(minutes);
  //     console.log(`time remaining ${remainingTime} ${isAuthenticated}`);
  //     // Show the popup during the last 2 minutes
  //     if (minutes <= 2 && isAuthenticated) {
  //       setShow(true);
  //     }
  //   }, 60000);

  //   return ()=>{     
  //     clearInterval(interval);
  //   }
  // },[])
// End code change for ChatHPE-574
// async function callLogoutApi() {
//   try {
//       // TEMP REMOVAL OF 30 SEC TIMEOUT
//       // const response = await fetchWithRetry(constClass.LOGOUT, {
//       const response = await fetch(constClass.setApiUrl(localStorage.getItem('region')) + constClass.LOGOUT, {
//           method: "GET",
//           headers: {
//               "Content-Type": "application/json",
//               "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
//               "Client-ID": constClass.CLIENT,
//               "Connection": 'keep-alive'
//           }
//       });
//       const newData = await response.json();
//       if (!newData.authentication) {
//           localStorage.clear();
//           navigate('/logout')
//       }
//       else {
//           navigate('/error', { state: { errorMessage: newData['message'] } });
//       }
//   } catch (error) {
//       console.error('An error occurred:', error)
//       navigate('/error')
//   }
// }
 
  return (
    <Grommet full className={`App ${theme}`}>
      {/* <BrowserRouter> */}
        {/* <ErrorBoundry> */}
        {/* //Start code CHATHPE-738 */}
        {/* {show && !logoutTriggered && remainingMinutes>0 &&(
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          style={{ color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'),backgroundColor: (`${theme}` === 'light' ? '#FFFFFF' : '#1C1C1C'),padding: "10px",borderRadius: "12px", boxShadow: (`${theme}` === 'light' ? '0px 6px 12px #0000001F' : '0px 6px 12px #0000005C')}}
        >
          <Box style={{ fontSize: 24, fontFamily: "MetricHPE-Web-Regular",display:'flex',flexDirection:'row' }}>{remainingMinutes > 0&&
              `You will be logged out in ${remainingMinutes} mins due to inactivity`
            }</Box>     
        </Layer>
      )} */}
      {/* //End code CHATHPE-739 */}
        <Routes>
          <Route path="/" element={<ErrorBoundry><PageContent /></ErrorBoundry>} />
          <Route exact path='/index' element={isAuthenticated ? <ErrorBoundry>< Horizontal /></ErrorBoundry> : <Navigate to='/' />}></Route>
          {/* <PrivateRoute path='/index' component={<Horizontal />} isAuth={isAuthenticated} /> */}
          <Route exact path='/logout' element={< SignInButton title="Logged Out" message="You've been successfully logged out of Chat HPE." />}></Route>
          <Route exact path='/permission' element={< SignInButton title="Access Denied" message={["Please reach out to administrator on ", <a href='mailto:ChatHPESLATeam@hpe.com'>ChatHPESLATeam@hpe.com</a>, "."]} />}></Route>
          <Route exact path='/error' element={< ErrorPage />}></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* </ErrorBoundry> */}
      {/* </BrowserRouter> */}
    </Grommet>
  );
}

export default App;
