import { css } from 'styled-components';

export const checkboxCheckStyle = css`
  background-color: #01A982;
  border-color: #01A982;
`;

export const HPEToggleSwitchTheme = {
  global: {
	colors: {
	  // bg is the pill border
	  'toggle-bg': '#0000005C',
	  'toggle-knob': 'white',
	  'toggle-accent': '#F7F7F7',
	  'focus': '#00E8CF',
	},
  },
  checkBox: {
	border: {
	  color: {
		light: 'toggle-bg',
	  },
	},
	color: {
	  light: 'toggle-knob',
	},
	check: {
	  radius: '2px',
	},
	hover: {
	  border: {
		color: undefined,
	  },
	},
	toggle: {
	  background: { light: 'toggle-accent' },
	  color: {
		light: 'toggle-knob',
	  },
	  size: '36px',
	  knob: {
		extend: `
		  top: -4px;
		  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12),
		  0px 2px 2px 0px rgba(0,0,0,0.24);
		`,
	  },
	  extend: ({ checked }) => `
		height: 14px;
		${checked && checkboxCheckStyle}
	  `,
	},
	gap: 'large',
	size: '18px',
  },
};