import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as constClass from './Constants';
import { useMsal } from "@azure/msal-react";
import LoadingIcon from "./dialog/LoadingIcon";
import { jwtDecode } from "jwt-decode";
import { setApiUrl } from './Constants';
// import { fetchWithRetry } from "./service/FetchWithRetry";


function LoginApp(props) {
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const spinnerRef = useRef();
    const loading = (e) => spinnerRef.current.LoadingStart(e);
        //     const graphToken = instance.acquireTokenSilent({
//         scopes: [ "User.Read", "User.Read.All",], // all MS Graph API scopes
//         account: accounts[0],
//    })
//    console.log("token seperate")
//    console.log(graphToken)
    const callLogin = (async () => {
        try{
            const tokenVal = localStorage.getItem('accessToken')
            const appId = localStorage.getItem('appIdValue') || '1';
            const data = {appId: localStorage.getItem('appIdValue') ? localStorage.getItem('appIdValue') : '1'};
//START CODE CHATHPE-440    27/02/2024
           // localStorage.removeItem('activeItem');
            //END CODE
            // TEMP REMOVAL OF 30 SEC TIMEOUT
            // const response = await fetchWithRetry(setApiUrl(localStorage.getItem('region'))+constClass.LOGIN, {
            const response = await fetch(setApiUrl(localStorage.getItem('region'))+constClass.LOGIN, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${tokenVal}`,
                    "Content-Type":'application/json',
                    "Client-ID": constClass.CLIENT,
                    "Connection": 'keep-alive'
// "content-type":'application/json',
                },
                body: JSON.stringify(data)
            });
            const newData = await response.json();
            if (response.status === 200) {
                sessionStorage.setItem('failedAttempts',0)
                localStorage.removeItem('fileList');
                localStorage.setItem('isDarkMode', newData.dark_mode);
                localStorage.setItem('Streaming', newData.stream)
                const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
                localStorage.setItem('chatBotData', chatBotDataString);
                const tokensUsed = (newData.total_tokens_used );
                const tokenUsageTotal =  newData.user_token_quota;
                localStorage.setItem('tokensUsed', tokensUsed);
                localStorage.setItem('tokenUsageTotal', tokenUsageTotal);

                loading(undefined)
                
                return navigate('/index', { state: newData, tokenVal });
            }
            else{
                navigate('/error', { state: { errorMessage: newData['message'] } });
            }
        }
        catch{
            loading(undefined);
            navigate('/error');
        }
    });

    useEffect(() => {
        loading(true)
        try {
            instance.acquireTokenSilent({
                scopes: [
                    constClass.LOGIN_SCOPE,
                ],
                account: accounts[0],
            })
                .then((response) => {
                const decodeToken = jwtDecode(response.accessToken);
                const region  = decodeToken.user_region_info;
                console.log('region', region);
                localStorage.setItem('region', region);
                localStorage.setItem('name', decodeToken.name);
                // localStorage.setItem('first', decodeToken.given_name)
                // localStorage.setItem('last', decodeToken.family_name)
                localStorage.setItem('email', decodeToken.preferred_username)
                if (decodeToken.roles && decodeToken.roles.includes(constClass.AD_GROUP)) {
                    localStorage.setItem('accessToken', response.accessToken)
                    callLogin();
                } else {
                    localStorage.clear()
                    navigate('/permission')
                }
            });

            instance.acquireTokenSilent({
                scopes: [constClass.USER_READALL_SCOPE,constClass.USER_READ_SCOPE,], // all MS Graph API scopes
                account: accounts[0],
            }).then((response) => {
                localStorage.setItem('graphToken', response.accessToken)
            });
        } catch {
            loading(undefined)
            navigate('/permission')

        }
    }, [accounts, instance]);

    return <LoadingIcon ref={spinnerRef} />
            
}

export default LoginApp
