import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Box, Button, Grommet, Heading, Text } from "grommet";
import { Hpe } from "grommet-icons";
import { useNavigate } from "react-router-dom";

export const SignInButton = (props) => {
    
    const { instance } = useMsal();
    const navigate = useNavigate();
    const handleLogin = () => {
        localStorage.removeItem('activeItem');
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }

    const handleLogOut = () => {
        navigate('/')
    }

    const btnName = (props.name === 'signIn');


    return (
        <Grommet full>
            <Box fill align="center" justify="center" alignSelf="center">
                <Box width="medium">
                    <Box align="center" pad="large" border={true} round>
                        <Hpe size="medium" color='plain' />
                        <Heading level={3} style={{ margin: 0 }}>{props.title}</Heading>
                        <Text style={{fontSize: '16px', fontFamily: "MetricHPE-Web-Regular",}} margin="20px 0" size="small">
                            {props.message}
                        </Text>
                        <Button label="Login with SSO" style={{fontSize: '18px', fontFamily: "MetricHPE-Web-Bold", background: '#01A982', border: 'none' }} size="small" primary onClick={btnName ? () => { handleLogin() } : () => { handleLogOut() }} />
                    </Box>
                </Box>
            </Box>
        </Grommet>
    );
}