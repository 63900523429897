import React, { useContext, useState, useImperativeHandle, useRef, useEffect, } from 'react';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Form,
    Layer,
    FormField,
    Footer,
    Text,
    TextArea,
    Header,
    Heading,
    ThumbsRating,
    ResponsiveContext,
    CheckBox,
    Select
} from 'grommet';
import LoadingIcon from './LoadingIcon';
import * as constClass from '../Constants';
import { checkTokenExpired } from '../service/TokenService';
import useRegenerateToken from '../components/RenegerateToken';
import { useTheme } from '../contexts/ThemeContext';


const PositionedFeedbackButton = styled(Button)`
  position: fixed;
  bottom: 0px;
  border-radius: 6px;
  right: 0px;
  z-index: 10;
  color: ${(props) => props.theme.global.colors['text-strong'].dark};
`;

// const Option = React.memo(({ value, selected }) => (
//     <Box style={{backgroundColor: (`${theme}` === 'light' ? '#FFFFFF' : '#1C1C1C'),}} direction="row" gap="small" align="center" pad="xsmall">
//         <CheckBox tabIndex="-1" checked={selected} onChange={() => { }} />
//         {value}
//     </Box>
// ));

const dummyArray = ['Inaccurate information or answer', 'Inappropriate content', 'Grammatical or spelling errors', 'Coherence or relevance issues', 'Processing time or technical issues', 'Other'];


export const UpVoting = React.forwardRef((props, ref) => {
    const navigate = useNavigate();
    const size = useContext(ResponsiveContext);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [open, setOpen] = useState(false);
    const [vote, setVote] = useState();
    const [getId, setId] = useState();
    const [selected, setSelected] = React.useState([]);
    const [options, setOptions] = React.useState(dummyArray);
    const [commentLike, setCommentLike] = useState();
    const [commentDisLike, setCommentDisLike] = useState();
    const spinnerRef = useRef();
    const loading = (e) => spinnerRef.current.LoadingStart(e);
    const accessToken = localStorage.getItem('accessToken');
    const [chatBotData, setChatBotData] = useState(props.chatHPE_bot_data);
    const { getNewToken } = useRegenerateToken();
    const { theme } = useTheme();

    const Option = React.memo(({ value, selected }) => (
        <Box background={(`${theme}` === 'light' ? '#F7F7F7' : '#222222')} style={{backgroundColor: (`${theme}` === 'light' ? '#FFFFFF' : '#222222'),color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF')}} direction="row" gap="small" align="center" pad="xsmall">
            <CheckBox tabIndex="-1" checked={selected} onChange={() => { }} />
            {value}
        </Box>
    ));

    const onOpen = (e, val, id) => {
        setVote(val);
        setCommentDisLike('');
        setCommentLike('');
        setSelected([]);
        setId(id);
        setOpen(true);
    };
    const onClose = () => setOpen(undefined);

    useImperativeHandle(ref, (e, val, id) => ({
        onOpen(e, val, id) {
            onOpen(e, val, id)
        }
    }));

    const closeFeedbackModal = () => {
        setTimeout(() => {
            setOpen(false);
            setIsSuccessful(false);
        }, 2000);
    };

    //START CODE CHATHPE-758    24/05/2024
    useEffect(()=>{
        setCommentDisLike('');
        setCommentLike('');
        setSelected([]);
    }, [vote])
    //END CODE

    const submitFeedback = async (e) => {
        loading(true);
        const voteType = e.value.rating === 'like' ? 'upvote' : 'downvote';
        const chat_data = JSON.parse(localStorage.getItem('chatBotData'))
        const params = e.value.rating === 'like' ? { id: getId, vote_type:voteType, chatHPE_bot_data: chat_data, feedback: e.value.comments } : { id: getId,vote_type:voteType, feedback: e.value.comments, reason: e.value.reason, chatHPE_bot_data: chat_data };
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                callVoteApi()
            }
        } else {
            callVoteApi();
        }
        async function callVoteApi() {
            try {
                const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+'/vote' , {   
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    },
                    body: JSON.stringify(params)
                });
                const newData = await response.json();
                // localStorage.removeItem('chatBotData')
                // const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
                // localStorage.setItem('chatBotData', chatBotDataString);
                loading(undefined);
                setIsSuccessful(true);
                props.callbackHandler(newData.chat_history);
                props.getAllDataHandler(newData);
                closeFeedbackModal();
            } catch (error) {
                console.error('An error occurred:', error)
                navigate('/error')
            }
        }

    }

    return (
        <>
            <LoadingIcon ref={spinnerRef} />
            {open && (
                <Layer style={{boxShadow: (`${theme}` === 'light' ? '0px 6px 12px #0000001F' : '0px 6px 12px #0000005C')}} className={`${theme} feedbackBox`}  onClickOutside={onClose} onEsc={onClose}>
                    <Box
                        fill="vertical"
                        overflow="auto"
                        width={!['xsmall', 'small'].includes(size) ? 'medium' : undefined}
                        pad="medium"
                        gap="medium"
                        className={`${theme} feedbackBox`}
                    >
                        <Header
                            direction="column"
                            align="start"
                            gap="xxsmall"
                            pad={{ horizontal: 'xxsmall' }}
                        >
                            <Heading style={{fontSize: 24, fontFamily: "MetricHPE-Web-Regular"}} level={4} size="small" margin="none">
                            Provide additional feedback
                            </Heading>
                        </Header>
                        <Box
                            // Padding used to prevent focus from being cutoff
                            pad={{ horizontal: 'xxsmall' }}
                        >
                            <Form
                                method="post"
                                validate="submit"
                                kind="survey"
                                onSubmit={(value) => {
                                    submitFeedback(value)
                                }}
                            >
                                <FormField
                                    htmlFor="thumbs-rating"
                                    name="rating"
                                    // label={`I ${vote}d the response because`}
                                >
                                    <ThumbsRating id="thumbs-rating" name="rating" value={vote} onChange={(e) => setVote(e.target.value)}  colors="#F00" />
                                </FormField>
                                {(vote === 'like') ?
                                    (<FormField htmlFor="comments" name="comments">
                                        <TextArea maxLength={100} style={{fontSize: 16, fontFamily: "MetricHPE-Web-Regular"}} id="comments" name="comments" placeholder='Provide Additional Comments' value={commentLike} onChange={(e) => setCommentLike(e.target.value)} />
                                    </FormField>) : (
                                        <>
                                            <FormField required={!selected.length}>
                                                <Select
                                                    multiple
                                                    style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Regular"}}
                                                    closeOnChange={false}
                                                    placeholder="Select an option..."
                                                    selected={selected}
                                                    options={options}
                                                    name="reason"       
                                                    // dropHeight="medium"
                                                    onChange={({ selected: nextSelected }) => {
                                                        setSelected(nextSelected);
                                                    }}
                                                >
                                                    {(option, index) => (
                                                        <Option value={option} selected={selected.indexOf(index) !== -1} />
                                                    )}
                                                </Select>
                                            </FormField>
                                            <FormField htmlFor="comments" name="comments" required={selected.includes(5)}>
                                                <TextArea style={{fontSize: 16, fontFamily: "MetricHPE-Web-Regular"}} id="comments" name="comments" placeholder='Provide additional comments' value={commentDisLike} onChange={(e) => setCommentDisLike(e.target.value)} />
                                            </FormField>
                                        </>
                                    )}

                                {!isSuccessful ? (
                                    <Footer
                                        margin={{ top: 'medium', bottom: 'small' }}
                                        direction="row"
                                        justify="end"
                                        gap="small"
                                    >
                                        <Button className={`${theme} feedbackBoxCancelButton`} style={{fontSize: 16, fontFamily: "MetricHPE-Web-Bold"}} onClick={onClose} label="Cancel" />
                                        <Button label="Submit Feedback" primary type="submit" style={{border: 'none', background: '#01A982', fontSize: 16, fontFamily: "MetricHPE-Web-Bold"}} />
                                    </Footer>
                                ) : (
                                    <Footer
                                        margin={{ top: 'medium', bottom: 'small' }}
                                        align="end"
                                    >
                                        <Text weight={900} size="large">
                                            Thank you for your response!
                                        </Text>
                                    </Footer>
                                )}
                            </Form>
                        </Box>
                    </Box>
                </Layer>
            )}
        </>
    );
});

export default UpVoting;