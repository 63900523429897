import React, {  useEffect, useState, useRef } from 'react';
import { Box, Button, Layer, Text, Header, Heading, Grommet, FileInput, Form, FormField, Meter, Stack } from 'grommet';
import { grommet } from 'grommet/themes';
import { useTheme } from '../contexts/ThemeContext';
import { useNavigate } from "react-router-dom";
import * as constClass from '../Constants';
import ProcessingIcon from '../dialog/ProcessingIcon';
import axios from "axios";
import { Close } from 'grommet-icons';
import JSZip from 'jszip'

const FileUploadDialog = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { theme } = useTheme();
  const processingSpinnerRef = useRef();
  const [fileDetails, setFileDetails] = useState([]);
  const [unSavedFiles, setUnsavedFiles] = useState([]);
  const [invalidFileDetails, setInvalidFileDetails] = useState();
  const [invalidFileSize, setInvalidFileSize] = useState();
  const [savedFileSize, setSavedFileSize] = useState(0);
  const [savedFileCount, setSavedFileCount] = useState(0);
  const [addedFileSize, setAddedFileSize] = useState(0);
  const [addedFileCount , setAddedFileCount] = useState(0); 
  const [totalFilesSize, setTotalFilesSize] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [limitMessage, setLimitMessage] = useState('Limit Reached');
  const [selectedFileSize, setSelectedFileSize] = useState(0);
  const [isMaxLimitReached, setIsMaxLimitReached] = useState(false);
  //START CODE CHATHPE-744
  const [availableSize, setAvailableSize] = useState(localStorage.getItem('appIdValue')==='3'?5:20);
  //END CODE
  const [availableCount, setAvailableCount] = useState(10);
  // Settings for the file number limit 
  const totalMonthlyFiles = 50;
  const [active, setActive] = useState(0);
  const [label, setLabel] = useState('');
  const [highlight, setHighlight] = useState(false);
  // Settings for the file number limit 
  const totalMonthlyFileSpace = 100;
  const [activeFileSpace, setActiveFileSpace] = useState(0);
  const [labelFileSpace, setLabelFileSpace] = useState('');
  const [highlightFileSpace, setHighlightFileSpace] = useState(false);
  const [progress, setProgress] = useState(0);
  const [existingFiles, setExistingFiles] = useState([]);
  const [uniqueFileNames , setUniqueFileNames] = useState([]);
  const [existingFilesBrowsed, setExistingFilesBrowsed] = useState([]);
  const navigate = useNavigate();
  const fileNameFormat= /^[a-zA-Z0-9_\- ]{1,255}$/
  const inputRef = useRef(null)
  //START CODE  CHATHPE-216   13/02/2024
  useEffect(()=>{
    //   let placeholderVar = 10;
      if(props.totalAllowedFileSize < 20) {
        setAvailableSize(props.totalAllowedFileSize);
      }
      if(props.totalFilesAllowed < 10){
        setAvailableCount(props.totalFilesAllowed);
      }
      if(totalFilesSize>=props.totalAllowedFileSize || (localStorage.getItem('appIdValue')==='3' && totalFilesSize > 5)||(localStorage.getItem('appIdValue')==='2' && totalFilesSize>20)){
          setLimitMessage('File Size limit Reached.');
          setIsMaxLimitReached(true);
      }   
      else if(totalFiles > props.totalFilesAllowed || totalFiles > 10){
        setLimitMessage('Allowed File count limit reached.');
        setIsMaxLimitReached(true);
      }
      else{
        setIsMaxLimitReached(false);
      }
  },[totalFilesSize, totalFiles]);

    //START CODE    CHATHPE-264     14/02/2024      
    useEffect(()=>{
        const totalSize = parseFloat(addedFileSize) + parseFloat(savedFileSize);
        
        setTotalFilesSize(totalSize);
    },[addedFileSize, savedFileSize])

    useEffect(()=>{
        const totalFile = parseFloat(addedFileCount) + parseFloat(savedFileCount);
        setTotalFiles(totalFile);
    },[addedFileCount, savedFileCount]);

  useEffect(() => {
    // Because the selectedFileSize is stored as MB we are converting back to bytes to sum later.
    if (fileDetails != null) {
      var uploadedFileSize = 0;
      var uploadedFileCount = 0;
      fileDetails.forEach(file => {
        uploadedFileSize = uploadedFileSize + ((file.size) / 1048576)
        if(uploadedFileSize >= 20){
          setInvalidFileSize(prev => (prev ? [...prev, file] : [file]));
        }  
        uploadedFileCount++;
      });
      var result = (parseFloat(uploadedFileSize) + parseFloat(selectedFileSize)).toFixed(2);
      // The set here will set selectedFileSize in MB
      // setSelectedFileSize(result);
      setAddedFileCount(uploadedFileCount);
      setAddedFileSize(result);
    }
    else {
        setAddedFileCount(0);
      setAddedFileSize(0)
    }
  }, [fileDetails]) 

  //START CODE CHATHPE - 860
  //This code is used to remove the duplicate files from the existingFiles and existingFilesBrowsed.  
  useEffect(()=>{
      const combinedFiles = [...existingFiles, ...existingFilesBrowsed];
    
      const uniqueFileNames = new Set();
      const uniqueFiles = [];
    
      combinedFiles.forEach(file => {
        if (!uniqueFileNames.has(file.name)) {
          uniqueFileNames.add(file.name);
          uniqueFiles.push(file);
        }
      });
    
      setUniqueFileNames(uniqueFiles);
  },[existingFiles, existingFilesBrowsed])
  //END CODE

  useEffect(() => {
    if (props.savedFiles != null) {
      var uploadedFileSize = 0;
      var uploadedFileCount = 0;
      props.savedFiles.forEach(file => {
        uploadedFileSize = uploadedFileSize + ((file.size) / 1048576);
        uploadedFileCount++;
      });
      var result = (parseFloat(uploadedFileSize) + parseFloat(selectedFileSize)).toFixed(2);
      // The set here will set selectedFileSize in MB
      setSavedFileCount(uploadedFileCount);
      setSavedFileSize(result);
    }
    else {
        setSavedFileCount(0);
      setSavedFileSize(0);
    }
  }, [props.savedFiles]);
  //END CODE CHATHPE-264 & CHATHPE-216

  useEffect(() => {
    setOpen(props.isUploadOpen);
    setAddedFileSize(0);
    setAddedFileCount(0);
    setInvalidFileDetails([]);
    setInvalidFileSize([])
    setExistingFiles([]);
    setExistingFilesBrowsed([]);
    setUniqueFileNames([]);
  }, [props.isUploadOpen]);

  const onClose = () => {
    setOpen(false);
    // setFileDetails(props.savedFiles);
    setUnsavedFiles([])
    props.setUpload()
  };

  useEffect(() => {
    if (props.isFileUploadInProgress) {
      uploadFiles();
    }
  }, [props.isFileUploadInProgress]);

  const getColorLevel = (value) => {
    if (value <= 70) {
      return (`${theme}` === 'light' ? '#17EBA0' : '#008567'); // Green for values <= 70
    } else if (value <= 90) {
      return (`${theme}` === 'light' ? '#FFBC44' : '#9B6310'); // Yellow for values <= 90
    } else {
      return (`${theme}` === 'light' ? '#FC5A5A' : '#D04F4E'); // Red color for values > 90
    }
  };

  const uploadFiles = async () => {
    props.uploading(true);
    const fileList = props.savedFiles;
    const chatHPE_bot_data = JSON.parse(localStorage.getItem('chatBotData'));
    const formData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      formData.append('files', fileList[i]);
    }
    formData.append('data', JSON.stringify(chatHPE_bot_data));
    try {
      const response = await axios.post(constClass.setApiUrl(localStorage.getItem('region')) + constClass.UPLOAD_FILES, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Client-ID": constClass.CLIENT,
          "Connection": 'keep-alive'
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          props.setProgresspercentage(Math.round((100 * data.loaded) / data.total)*(1/3));
        },
      });
      const newData = await response.data
      if (response.status===200) {
        localStorage.setItem('sessionId', JSON.stringify(newData['sessionId']));
        const prevChatbotData = JSON.parse( localStorage.getItem('chatBotData'))
        localStorage.removeItem('chatBotData')
        localStorage.setItem('chatBotData',JSON.stringify({appId:localStorage.getItem('appIdValue'),sessionId: newData['sessionId'],userid:prevChatbotData.userid,username:prevChatbotData.username}))
        var flag = true;
        const fileList = props.savedFiles;
        const fileNames = [];
        let successCount = 0;
        for (let i = 0; i < fileList.length; i += 1) {
          const file = fileList[i];
          fileNames.push(file.name);
        }
        props.setFileNames(fileNames);
        const failedFiles= props.uploadFailedFiles.length>0?props.uploadFailedFiles: []
        const successFile = []
        newData.results.forEach(result => {
          if (result.status === 'success') {
            successCount++;
            successFile.push(result.filename);
          }
          else { 
            if(!props?.uploadFailedFiles?.find(uff=>uff?.name===result?.filename)){
              failedFiles.push({name: result.filename, type: 'uploadFailed', message: result.error})
            }
            flag = false;
          }
        });
        props.setFileNames(successFile);
        props?.setUploadFailedFiles(failedFiles)
        if(failedFiles.length>0){
          props.setIsNotUploadedAvailable(true);
        }
          props.setUploadSuccessFiles(successFile);
          if (successCount >= 1) {
            setFileDetails([]);
            props.setSavedFiles([])
            // props.onUploadComplete()
          } 
          else {         
              props.newSessionState(localStorage.getItem('appIdValue'));
              props.setFileUploadInProgress(false);
            // props.setNotificationMessage('Upload Failed!')
            // props.setFailureNotification(true);
              props.uploading(undefined);
            // props.newSessionState(localStorage.getItem('appIdValue'));
              props.setFileUploadInProgress(false);
          }
      }
      else {
        props.setNotificationMessage('Upload Failed!')
        props.setFailureNotification(true);
        // props.savedFiles([]);
        props.uploading(undefined);
        props.newSessionState(localStorage.getItem('appIdValue'));
      props.setFileUploadInProgress(false);
        // navigate('/error', { state: { errorMessage: newData['message'] } });
        // console.log('error');
      }
    } catch (error) {
      props.setNotificationMessage('Upload Failed!')
      props.setFailureNotification(true);
      // props.savedFiles([]);
      props.uploading(undefined);
      props.setFileUploadInProgress(false);
      props.newSessionState(localStorage.getItem('appIdValue'));
      // // console.error('An error occurred:', error)
      // navigate('/error')
    }
  };

  const handleUploadClick = () => {
    props.setSavedFiles(Array.from(new Set([...(props.savedFiles??[]), ...fileDetails])));
    if (fileDetails.length !== 0) {
      props.isProcessButtonDisabled(false);
    }
    setAddedFileSize(0);
    setFileDetails([]);
    props.isSummarizeButtonDisabled(false);
    onClose();
  };

  const getDuplicatesFromSelected = async (validFilesList, _savedFiles) => {
    let existingFiles = [];
    for (let i = 0; i < validFilesList?.length; i++) {
      for (let j = 0; j < _savedFiles?.length; j++) {
        const flag = await compare(validFilesList[i], _savedFiles[j]);
        if (flag) {
          existingFiles.push(validFilesList[i]);
        }
      }
    }
    return existingFiles;
  };

  const getDuplicatesFromBrowsedFiles = async (validFilesList) => {
    //check for duplicates within the validFilesList and return the duplicates
    let duplicates = [];
    for (let i = 0; i < validFilesList.length; i++) {
      for (let j = i + 1; j < validFilesList.length; j++) {
        const flag = await compare(validFilesList[i], validFilesList[j]);
        if (flag) {
          duplicates.push(validFilesList[i]);
        }
      }
    }
    return duplicates;
  };

  const getInvalidFilesFromSelected  = async(fileList)=>{
    let res =[]
    for(let j=0;j<fileList.length;j++){
     
    var blob = fileList[j]; // See step 1 above
var fileReader = new FileReader();
 res.push(
    new Promise((resolve)=>{
    fileReader.readAsArrayBuffer(blob);
    fileReader.onloadend = function(e) {
        var arr = (new Uint8Array(e.target.result)).subarray(0, 4);
        // console.log(arr,e.target.result,'tarrrr');
        var header = "";
        for(let i = 0; i < arr.length; i++) {
           header += arr[i].toString(16);
        }
        const fileNameWithoutExtension = fileList[j]?.name?.substring(0,fileList[j]?.name.lastIndexOf('.'))
        if( header!=='efbbbf43' && header!=='ffd8ffe8' && header!=='ffd8ffe3' && header!=='302c312c' && header!=='ffd8ffe2' && header!=='ffd8ffe1' && header!=='ffd8ffe0' && header!=='89504e47' && header!=='25504446' && header!=='efbbbf46' && header!=='43726561' && header!=='504b34' && header!=='49bd3b17' && header!=='efbbbf4e' ){
         console.log('inside','***header***');
          resolve(j)
        }     
        else if(!fileNameFormat.test(fileNameWithoutExtension)){
          // console.log(fileNameWithoutExtension,'withoutextension');
          resolve(j)
        }
        else{
            resolve(undefined)
        }
        // console.log(header,'header - '+j+1);     
        // Check the file signature against known types
      };
 }))
    }
    // console.log(res,'resss');
    const files =await Promise.all(res).then(data=>{
        // console.log(data,'ffffff');
        return data?.filter(item=>item!==undefined)
    })
    return files
  }

  const getInvalidFilesFromZip  = (fileList)=>{
    const allowedFileTypes = ['.pdf', '.csv', '.docx', '.xlsx', '.png', '.jpeg', '.jpg'];
     const invalidFilesList = fileList.filter((file) =>
      !allowedFileTypes.includes(
        file.name.substring(file.name.lastIndexOf('.'))
      )
    );
    return invalidFilesList
    //     let res =[]
    //     const jsZip = new JSZip()
    //     for(let j=0;j<fileList.length;j++){
    //       const data =await fileList[j].async('string')
    //       const blob=new File([data],'test.pdf')
    // var fileReader = new FileReader();
    
    //  res.push(
    //     new Promise((resolve)=>{
    //       fileReader.readAsArrayBuffer(blob);
    //       fileReader.onloadend = function(e) {
    //           var arr = (new Uint8Array(e.target.result)).subarray(0, 4);
    //           console.log(arr,e.target.result,'dattt');
    //           var header = "";
    //         for(let i = 0; i < arr.length; i++) {
    //            header += arr[i].toString(16);
    //         }
    //         if(header!=='25504446' && header!=='43726561' && header!=='504b34' && header!=='49bd3b17'  ){
    //           resolve(j)
    //         }
    //         else{
    //             resolve(undefined)
    //         }
    //         console.log(header ,fileList[j].name,'header in zip - '+j+1);
    //       }
       
    //  }))
    
    //     }
    //     console.log(res,'resss');
    //     const files =await Promise.all(res).then(data=>{
    //         console.log(data,'ffffff');
    //         return data?.filter(item=>item!==undefined)
    //     })
    //     return files
      }
    
    const validateZipFiles=(fileList)=>{
    // console.log(fileList,"filelist");
    for(let i=0;i<fileList?.length;i++){
      if(fileList[i].name.substring(fileList[i].name.lastIndexOf('.'))==='.zip'){
        const jsZip = new  JSZip();
        jsZip.loadAsync(fileList[i]).then(async(zip)=>{
          // zip.files[''].async("string")
          //       .then(function (data) {
          //               GetValueFile(data);                         

          //           });
          const invalidFilesListIndex =( getInvalidFilesFromZip(Object.values(zip.files)))
          if(invalidFilesListIndex.length>0){
           

              setInvalidFileDetails((prev)=>[...prev,fileList[i]])
            
          }
          const validFilesInZip = await Promise.all(Object.values(zip.files).filter((_,i)=>invalidFilesListIndex.indexOf(i)===-1).map(async(file)=>{
            const data =await file.async('string')
            return new File([data],file.name)
          }))
          const dummyFiles = await getDuplicatesFromSelected(
            validFilesInZip,
            props?.savedFiles ?? []
          )??[];
          // console.log(validFilesInZip,dummyFiles,'vvvv');
          setExistingFiles([...existingFiles,...dummyFiles]);
          // console.log(zip,'zippppp');
          // console.log(invalidFilesListIndex,'invalidFilesListIndex');
        })
      }
    }
  }
  
  const handleFileChange = async (event, { files }) => {
        const allowedFileTypes = ['.pdf', '.csv', '.docx', '.xlsx', '.zip', '.png', '.jpeg', '.jpg'];
    const fileList = Array.from(files);
    setUnsavedFiles(fileList)
    // const validFilesList = fileList.filter((file) =>
    //   allowedFileTypes.includes(
    //     file.name.substring(file.name.lastIndexOf('.'))
    //   )
    // );
    // const invalidFilesList = fileList.filter((file) =>
    //   !allowedFileTypes.includes(
    //     file.name.substring(file.name.lastIndexOf('.'))
    //   )
    // );
    const invalidFilesListIndex =(await getInvalidFilesFromSelected(fileList))
    const invalidFilesList = fileList.filter((_,i)=>invalidFilesListIndex.indexOf(i)!==-1)
    const validFilesList = fileList.filter((_,i)=>invalidFilesListIndex.indexOf(i)===-1)

    //START CODE CHATHPE-860 
    //  
    const duplicateFilesBrowsed = await getDuplicatesFromBrowsedFiles(validFilesList);  
    const dummyFiles = await getDuplicatesFromSelected(
      validFilesList,
      props?.savedFiles ?? []
    );

    setExistingFilesBrowsed(duplicateFilesBrowsed);
    setExistingFiles(dummyFiles);
    
    const uniqueFiles = validFilesList?.filter(
      (validFile) =>
        !dummyFiles?.find((dFile) => dFile?.name === validFile?.name)
    );
    //END CODE

    setInvalidFileDetails(invalidFilesList);
    validateZipFiles(fileList)
    setFileDetails(uniqueFiles);
    // TODO: Add an alert for invalid files
    
  };

  const allCovererageFileCheck=async(fileList)=>{
  const filesIncludingFromInput = [...fileList,...inputRef.current?.files]
  //console.log(inputRef,filesIncludingFromInput,'testtt');
  const invalidFilesListIndex =(await getInvalidFilesFromSelected(fileList))
  const invalidFilesList = fileList.filter((_,i)=>invalidFilesListIndex.indexOf(i)!==-1)
   const validFilesList = fileList.filter((_,i)=>invalidFilesListIndex.indexOf(i)===-1)
  const dummyFiles = await getDuplicatesFromSelected(
    validFilesList,
    unSavedFiles ?? []
  );
  setExistingFiles(dummyFiles);
  const uniqueFiles = [...validFilesList,...unSavedFiles]?.filter(
    (validFile) =>
      !dummyFiles?.find((dFile) => dFile?.name === validFile?.name)
  );
  setInvalidFileDetails(invalidFilesList);
  validateZipFiles(fileList)
  
  // setFileDetails(uniqueFiles);  //Commented to resolve a bug which was causing the counter to count double the files in it.
}
  // Start of code change ChatHPE-237 2/9/2024
  const processedFiles = JSON.parse(localStorage.getItem('fileList') ?? '[]')?.map(file => { return { name: file, type: 'processed' } })
 // const savedFiles = (props.savedFiles?.map(file => { return { name: file.name, type: 'saved' } }) ?? [])
  //const allFiles = [...processedFiles, ...savedFiles]
  const savedFiles = props.savedFiles?.filter((file)=>!props.uploadFailedFiles?.find(uf=>uf.name===file.name)).map(file => { return { name: file.name, type: 'saved' } }) ?? []
    const allFiles = [...processedFiles, ...savedFiles,...props.uploadFailedFiles]
  // End of code change ChatHPE-237 - 2/9/2024
  const handleDelete = (fileName) => {
    // console.log(fileName, 'delete');
    // props.setFilesForDelete([fileName])
    if (fileName.type === 'saved') {
      // console.log(fileName, props.savedFiles.find(file => file.name === fileName.name));
      let newFiles = structuredClone(props.savedFiles)
      newFiles.splice(props.savedFiles.findIndex(file => file.name === fileName.name), 1)
      props.setSavedFiles(newFiles)
      const processedFiles = JSON.parse(localStorage.getItem('fileList') ?? '[]')?.map(file => { return { name: file, type: 'processed' } })
      if (newFiles?.length === 0 && processedFiles.length === 0) {
        props.setAttachButtonDisabled(false)
        props.setProcessButtonDisabled(true)
        props.setSummarizeButtonDisabled(true)
      }
      allCovererageFileCheck(newFiles) 
    }
    else if(fileName.type==='uploadFailed'){
      let newFiles = structuredClone(props.savedFiles)
      newFiles.splice(props.savedFiles.findIndex(file => file.name === fileName.name), 1)
      props.setSavedFiles(newFiles)
      // Start of code change ChatHPE-240 & ChatHPE-237 - 2/9/2024
      const processedFiles = JSON.parse(localStorage.getItem('fileList') ?? '[]')?.map(file => { return { name: file, type: 'processed' } })
      if (newFiles?.length === 0 && processedFiles.length === 0) {
        props.setAttachButtonDisabled(false)
        props.setProcessButtonDisabled(true)
        props.setSummarizeButtonDisabled(true)
      }
      let newFailedFiles = structuredClone(props.uploadFailedFiles)
      newFailedFiles.splice(props.uploadFailedFiles.findIndex(file => file.name === fileName.name), 1)
      //console.log(savedFiles,newFiles,newFailedFiles,'ffff');
      props.setUploadFailedFiles(newFailedFiles)
      allCovererageFileCheck(newFiles)
  }
    else if (fileName.type === 'processed') {
      props.deleteFile([fileName.name])
    }
  }

  function compare(file1, file2) {
    // they don't have the same size, they are different
    if (file1.size !== file2.size) return Promise.resolve(false);
    // load both as ArrayBuffers
    return Promise.all([
      readAsArrayBuffer(file1),
      readAsArrayBuffer(file2),
    ]).then(([buf1, buf2]) => {
      // create views over our ArrayBuffers
      const arr1 = new Uint8Array(buf1);
      const arr2 = new Uint8Array(buf2);
      return !arr1.some(
        (val, i) => arr2[i] !== val // search for diffs
      );
    });
  }
  function readAsArrayBuffer(file) {
    // we could also have used a FileReader,
    // but Response is conveniently already Promise based
    return new Response(file).arrayBuffer();
  }

  // Start code change for ChatHPE-50
  return (
    <Grommet theme={grommet}>
      <ProcessingIcon ref={processingSpinnerRef} />
      {open && (
        <Layer className={`${theme} settingPane`} style={{width:'35vw', boxShadow: (`${theme}` === 'light' ? '0px 6px 12px #0000001F' : '0px 6px 12px #0000005C')}} onClickOutside={onClose} onEsc={onClose}>
          <Header
            direction="column"
            align="stretch"
            gap="xxsmall"
            pad={{ horizontal: '14px' }}>
            {/* Start of code change ChatHPE-202 - 2/9/2024 */}
            <Heading style={{ fontSize: 24 }} size="small" margin="none">
              Select Files
            </Heading>
            {/* End of code change ChatHPE-202 - 2/9/2024 */}
            <Text style={{ fontSize: 18, fontWeight: 'bold' }}>ChatHPE Document Storage Limits</Text>
            <Box>
              <Box style={{ justifyContent: "space-between" }} direction="row" align="start">
                <Box direction="row" align="center">
                  <Text style={{ fontSize: 18 }} size='xsmall'>Max number of files this month:</Text>
                </Box>
                <Box direction="row">
                  <Text size="medium" style={{ marginRight: '4px' }}>
                    {active || totalMonthlyFiles}
                  </Text>
                  <Text>{label || 'Total'}</Text>
                </Box>
              </Box>
              <Stack anchor="center">
              <Meter
                className={`${theme} meter`}
                type="bar"
                background="light-2"
                values={[
                  {
                    value: totalMonthlyFiles - props.totalFilesAllowed,
                    color: getColorLevel(totalMonthlyFiles - props.totalFilesAllowed, theme),
                    onHover: (over) => {
                      setActive(over ? totalMonthlyFiles - props.totalFilesAllowed : 0);
                      setLabel(over ? 'In use' : undefined);
                    },
                    onClick: () => {
                      setHighlight(() => !highlight);
                    },
                    highlight,
                  },
                  {
                    value: props.totalFilesAllowed,
                    color: '#CCCCCC',
                    onHover: (over) => {
                      setActive(over ? props.totalFilesAllowed : 0);
                      setLabel(over ? 'Available' : undefined);
                    },
                  },
                ]}
                max={totalMonthlyFiles}
                size="medium"
                thickness="small"
                style={{ width: '100%' }}
              />
              </Stack>
              <Box style={{ justifyContent: "space-between" }} direction="row" align="start">
                <Box direction="row" align="center">
                  <Text style={{ fontSize: 18 }} size='xsmall'>Total size available for this month:</Text>
                </Box>
                <Box direction="row">
                  <Text size="medium" style={{ marginRight: '4px' }}>
                    {activeFileSpace || totalMonthlyFileSpace}
                  </Text>
                  {/* <Text>MB</Text> */}
                  <Text>{labelFileSpace || 'MB'}</Text>
                </Box>
              </Box>
              <Stack anchor="center" >
                <Meter
                  className={`${theme} meter`}
                  type="bar"
                  background="light-2"
                  values={[
                    {
                      value: parseFloat(totalMonthlyFileSpace - props.totalAllowedFileSize).toFixed(2),
                      color: getColorLevel(totalMonthlyFiles - props.totalFilesAllowed, theme),
                      onHover: (over) => {
                        setActiveFileSpace(over ? parseFloat(totalMonthlyFileSpace - props.totalAllowedFileSize).toFixed(2) : 0);
                        setLabelFileSpace(over ? 'MB In use' : undefined);
                      },
                      onClick: () => {
                        setHighlightFileSpace(() => !highlight);
                      },
                      highlightFileSpace,
                    },
                    {
                      value:  props.totalAllowedFileSize,
                      color: '#CCCCCC',
                      onHover: (over) => {
                        setActiveFileSpace(over ?  props.totalAllowedFileSize : 0);
                        setLabelFileSpace(over ? 'MB Available' : undefined);
                      },
                    },
                  ]}
                  max={totalMonthlyFileSpace}
                  size="medium"
                  thickness="small"
                  style={{width:'100%'}}
                />
              </Stack>
            </Box>
            <Text style={{ fontSize: 19 }} size='xsmall'>Total size of selected files: {totalFilesSize} MB (max {localStorage.getItem('appIdValue')==='3'?5:20} MB)</Text>
            <Text style={{ fontSize: 19 }} size='xsmall'>Total count of selected files: {totalFiles} (max {availableCount})</Text>
            <Text style={{ fontSize: 16 }} size='xsmall'>Zip: A single zip file can hold a maximum of 50 files.</Text>
            <Text style={{ fontSize: 16 }} size='xsmall'>Accepted File Types: .pdf, .docx, .xlsx, .csv, .zip</Text>
          </Header>
          <Box fill align="stretch" justify="start" pad="small">
            <Box width="medium" style={{minWidth:'100%'}}>
              <Form validate="submit" onSubmit={handleUploadClick}>
                <FormField name="fileInput" htmlFor="fileInput" required style={{maxHeight:'100px','& div':{'maxHeight':'50px','whiteSpace':'nowrap','overflow':'hidden','textOverflow':'ellipsis'},'& .FormField__FormFieldContentBox':'{"border":"none"}',overflow:'auto'}} >
                  <FileInput
                    aria-label="Choose files"
                   //START CODE  CHATHPE-343 22/02/2024
                    messages={{
                      browse: 'Browse',
                                          }}
                    //END CODE  
                    type='file'
                    accept='.pdf,.csv,.docx,.xlsx,.zip,.png,.jpeg,.jpg' // Code change for ChatHPE-166
                    name="fileInput"
                    id="fileInput"
                    multiple={{
                    aggregateThreshold: 60,
                      max: 60,
                    }}     
                    ref={inputRef}               
                    onChange={handleFileChange}
                  // onChange={(event, { files }) => {
                  //     const allowedFileTypes = ['.pdf', '.csv', '.docx', '.xlsx'];
                  //     const fileList = Array.from(files);
                  //     const validFiles = fileList.filter(file =>
                  //         allowedFileTypes.includes(file.name.substring(file.name.lastIndexOf('.')))
                  //     );
                  //     setFileDetails(validFiles);
                  // }}
                  />
                </FormField>
                               <Box style={{maxHeight:'150px',overflow:'auto',width:'100%'}}>
                {uniqueFileNames?.length > 0 ? (
                  <>
                    {uniqueFileNames?.map((file) => {
                       return (
                        <Box style={{minHeight:'20px',color:"#FC5A5A",display:'flex',flexDirection:'row'}}>
                        <span  style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'70%'}}>
                          {`${file.name} `}
                        </span>
                        <span> &nbsp; already exists in browsed or saved files</span>
                        </Box>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
                {/* {existingFiles?.length > 0 ? (
                  <>
                    {existingFiles?.map((file) => {
                       return (
                        <Box style={{minHeight:'20px',color:"#FC5A5A",display:'flex',flexDirection:'row'}}>
                        <span  style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'70%'}}>
                          {`${file.name} `}
                        </span>
                        <span> &nbsp; already exists</span>
                        </Box>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
                {existingFilesBrowsed?.length > 0 ? (
                  <>
                    {existingFilesBrowsed?.map((file) => {
                       return (
                        <Box style={{minHeight:'20px',color:"#FC5A5A",display:'flex',flexDirection:'row'}}>
                        <span  style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'70%'}}>
                          {`${file.name} `}
                        </span>
                        <span> &nbsp; already exists</span>
                        </Box>
                      );
                    })}
                  </>
                ) : (
                  ""
                )} */}
                {isMaxLimitReached && (
              <Text style={{ fontSize: 15 , color:"#FC5A5A" }} size='xsmall'>{limitMessage}</Text>
                )}
                {invalidFileDetails?.length > 0 ? (
                  < >
                    {Array.from(new Set(invalidFileDetails))?.map((file) => {
                      return (
                        <Box style={{minHeight:'20px',color:"#FC5A5A",display:'flex',flexDirection:'row'}}>
                        <span  style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'70%'}}>
                          {`${file.name} `}
                        </span>
                        <span> &nbsp; {file.name.endsWith('.zip')?"contains invalid file types": "is invalid file type"}</span>
                        </Box>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
                </Box>
                <Box
                  style={{
                    marginBlock: "10px",
                    maxHeight: "100px",
                    overflow: "auto",
                  }}
                >
                  {allFiles?.map((item) => {
                    return <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        padding: "10px",
                        minHeight: "40px",
                        borderBottom: "1px solid",  
                        background:
                        // Start of code change ChatHPE-266
                          item?.type === "processed"
                            ? theme === "dark"
                              ? "#008567"
                              : "#17EBA0"
                            : theme === "dark"
                              ? "#555555"
                              : "#CCCCCC",
                              // End of code change ChatHPE-266
                      }}
                    >
                      <Box style={{ display: 'block',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',width:'95%' }}> {item.name}</Box>
                      <Box><Close color='white' cursor='pointer' size='small' onClick={() => handleDelete(item)} /></Box>
                    </Box>
                  })}
                </Box>
                {/* Start code changes for ChatHPE - 847 */}
                <Button onSubmit={handleUploadClick} disabled={isMaxLimitReached || invalidFileDetails?.length>0 ||  uniqueFileNames?.length>0 || fileDetails?.length===0} style={{ background: '#01A982', border: 'none', fontSize: '16px', fontWeight: 'Bold', color: '#fff' }} label="Select file(s)" primary type="submit" />
                {/* End code changes for ChatHPE - 847 */}
                <Button onClick={onClose} style={{ border: 'none', fontSize: '16px', fontWeight: 'Bold', color:theme==='dark'? '#fff':'#555555' }} label="Cancel" />
              </Form>
            </Box>
            <Box>
            </Box>
          </Box>
        </Layer>
      )}
    </Grommet>
  );
});
// End code change for ChatHPE-50
export default FileUploadDialog; 