import { Grommet, Box, Heading } from "grommet";
import { Hpe } from "grommet-icons";
import React from "react";

const NotFound = () => {
    return (
        <Grommet full>
            <Box fill align="center" justify="center" alignSelf="center">
                <Box width="medium">
                    <Box align="center" pad="large" border="small" round>
                        <Hpe size="medium" color="#01A982" />
                        <Heading level={2}>Page Not Found</Heading>
                    </Box>
                </Box>
            </Box>
        </Grommet>
    )
}

export default NotFound;