import React, { useContext, useState, useImperativeHandle, } from 'react';
import {
    Box,
    Button,
    Layer,
    Text,
    Header,
    Heading,
    ResponsiveContext,
    Paragraph,
    RadioButton
} from 'grommet';
import * as constClass from '../Constants';
import { useNavigate } from 'react-router-dom';
import useRegenerateToken from '../components/RenegerateToken';
import { checkTokenExpired } from '../service/TokenService';
import { useTheme } from '../contexts/ThemeContext';
import LoginApp from '../login';
// import { fetchWithRetry } from '../service/FetchWithRetry';

const Agreement = React.forwardRef((props, ref) => {
    const { getNewToken } = useRegenerateToken();
    const size = useContext(ResponsiveContext);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [open, setOpen] = useState(false);
    const [vote, setVote] = useState();
    const [getId, setId] = useState();
    const [selected, setSelected] = React.useState([]);
    const [commentLike, setCommentLike] = useState();
    const [signAgreement, setSignAgreement] = useState(false);
    const navigate = useNavigate();
    const [chatBotData, setChatBotData] = useState(props.chatHPE_bot_data)
    const [busy, setBusy] = useState(false);
    const [success, setSuccess] = useState(false);
    const { theme } = useTheme();



    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => setOpen(undefined);

    useImperativeHandle(ref, () => ({
        onOpen() {
            onOpen()
        }
    }))

    const closeFeedbackModal = async () => {
        props.agreementValueCallback(false);
        onClose();

    };
    const accessToken = localStorage.getItem('accessToken')
    const signingDone = async () => {
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                callAgreementApi()
            }
        } else {
            callAgreementApi();
        }
        async function callAgreementApi() {
            try {
                const chat_data = JSON.parse(localStorage.getItem('chatBotData'))
                const dark_mode = localStorage.getItem('isDarkMode') === 'true';
                // TEMP REMOVAL OF 30 SEC TIMEOUT
                const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.AGREEMENT, {
                // const response = await fetch(constClass.AGREEMENT, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    },
                    body: JSON.stringify({ agreement: true, dark_mode: dark_mode, chatHPE_bot_data: chat_data })
                });
                const newData = await response.json();
                props.agreementValueCallback(newData)
                navigate('/');
                // localStorage.removeItem('chatBotData')
                // const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
                // localStorage.setItem('chatBotData', chatBotDataString);
                console.log(newData)
                onClose();
            } catch (error) {
                console.error('An error occurred:', error)
                navigate('/error')
            }
        }
    }

    const logout = async () => {
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                callLogoutApi()
            }
        } else {
            callLogoutApi();
        }
        // const callLogoutApi = async () => {
        async function callLogoutApi() {
            try {
                // TEMP REMOVAL OF 30 SEC TIMEOUT
                const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.LOGOUT, {
                // const response = await fetch(constClass.LOGOUT, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    }
                });
                const newData = await response.json();
                if (!newData.authentication) {
                    localStorage.clear();
                    navigate('/logout')
                }
                else {
                    navigate('/error', { state: { errorMessage: newData['message'] } });
                }
            } catch (error) {
                console.error('An error occurred:', error)
                navigate('/error')
            }
        }
    }

    return (
        <>
            {open && (
                <Layer style={{ color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), backgroundColor: (`${theme}` === 'light' ? '#FFFFFF' : '#1C1C1C'), borderRadius: "16px" }}>
                    <Box
                        overflow="auto"
                        width={!['xsmall', 'small'].includes(size) ? '500px' : undefined}
                        pad="small"
                        gap="medium"
                        flex="grow"

                    >
                        <Header
                            direction="column"
                            align="start"
                            gap="xxsmall"
                            pad={{ horizontal: 'xxsmall' }}
                        >
                            <Heading style={{ fontSize: 24, fontFamily: "MetricHPE-Web-Regular" }} margin="none">
                                Chat HPE Acceptable Use Notice
                            </Heading>
                        </Header>
                        <iframe src="https://hpe.sharepoint.com/teams/ChatHPE/_layouts/15/embed.aspx?UniqueId=558ff3ce-552a-44ba-86a6-760015c027fe" width="auto" height="270" frameborder="0" scrolling="no" allowfullscreen title="Chat HPE Acceptable Use Notice_March 2024.pdf"></iframe>
                        <Button onClick={(e) => { e.preventDefault(); window.open('https://hpe.sharepoint.com/:b:/r/teams/ChatHPE/Shared%20Documents/Chat%20HPE/Chat%20HPE%20Acceptable%20Use%20Notice_March%202024.pdf?csf=1&web=1&e=AZG9Zd', '_blank'); }} style={{ fontFamily: "MetricHPE-Web-Bold", background: '#01A982', border: 'none', fontSize: '18px', color: '#FFFFFF' }} label="Open Chat HPE Acceptable Use Notice in New Tab"></Button>
                        <Box style={{ background: (`${theme}` === 'light' ? '#FFFFFF' : '#1C1C1C') }}>
                            <RadioButton
                                label={<Text size='small'>I Accept Chat HPE's Acceptable Use Notice.</Text>}
                                name="name"
                                checked={signAgreement}
                                background={(`${theme}` === 'light' ? '#F7F7F7' : '#222222')}
                                onChange={(e) => setSignAgreement(e.target.value)}
                            />
                        </Box>
                        <Box direction='row' gap='medium' justify='end'>
                            <Button label="Decline and sign out" className={`${theme} feedbackBoxCancelButton`} style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Bold" }} onClick={() => logout()}></Button>
                            {/* <Button label="Accept" onClick={() => signingDone()} primary disabled={!signAgreement} type="submit" style={{border: 'none', background: '#01A982', fontSize: 18, fontFamily: "MetricHPE-Web-Bold"}} /> */}
                            <Button
                                style={{border: 'none', background: '#01A982', fontSize: 18, fontFamily: "MetricHPE-Web-Bold"}}
                                disabled={!signAgreement}
                                label="Accept"
                                onClick={() => {
                                    setBusy(true);
                                    setTimeout(() => {
                                        setBusy(false);
                                        setSuccess(true);
                                        signingDone();
                                    }, 2000);
                                    // setTimeout(() => {
                                    //     setSuccess(false);
                                    // }, 3000);
                                }}
                                busy={busy}
                                success={success}
                                primary
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
        </>
    );
});

export default Agreement;