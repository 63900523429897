const { SecretClient } = require("@azure/keyvault-secrets");
const { ManagedIdentityCredential } = require("@azure/identity");

const secretMapping={    
  "AD_GROUP":'ui-ad-group',
  "USER_GUIDE":'user-guide',
  "LOGIN_SCOPE":'login-scope',
  "CLIENT_ID":'client-id',
  "TENANT_ID":'tenantid'
}

class CustomSecretClientFromEnv{
  constructor(){
    // console.log("Initializing secrets from the environment file.")
    this.secretHolder= {'ui-ad-group': process.env.REACT_APP_AD_GROUP, 
                        'user-guide': process.env.REACT_APP_USER_GUIDE,
                        'login-scope': process.env.REACT_APP_LOGIN_SCOPE,
                        'client-id':process.env.REACT_APP_CLIENT_ID,
                        'tenantid':process.env.REACT_APP_TENANT_ID
    }
  }

  getSecret(secret_name) {    
    const value = this.secretHolder[secret_name]
    return value ? value : null
  }
}

class SecretLoader{  
  constructor(){
    try{
      this.credentials = new ManagedIdentityCredential(process.env.REACT_APP_MANAGED_IDENTITY)
      this.KEY_VAULT_NAME= process.env.REACT_APP_KV_NAME
      this.client = new SecretClient(`https://${this.KEY_VAULT_NAME}.vault.azure.net/`, this.credentials)
    }catch(e){
      console.log(`Unable to load secret from the Vault:: ${e}`)
      this.client = new CustomSecretClientFromEnv()
    }    
  }

  getSecretValue(secretName){
    try{
      return this.client.getSecret(secretMapping[secretName])
    }catch(e){
      console.log(`Unable to find the value for ${secretName}: ${e}`);
      return undefined
    }
    
  }

  getAllSecret(){
    let result = {}
    Object.keys(secretMapping).forEach(key =>  {
      result[key]=this.getSecretValue(key);
    });
    // console.log(result,"All Secrets is as");
    return result
  }
}

const appSecretsLoader = new SecretLoader();
const appSecrets = appSecretsLoader.getAllSecret();
export {appSecrets} ;
