import { Menu } from 'grommet';
import React, { useRef } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Add } from 'grommet-icons';
import { Box } from 'grommet';

const DropdownMenu = ({ AppMode, newChat, setAppId, botResponse, setChatHistory, setActive }) => {
  const { theme } = useTheme();

  const handleAIChatClick = () => {
    localStorage.setItem('appIdValue', '1');
    setChatHistory([]);
    setAppId('1');
    setActive(null);
    newChat();
  };
  
  const handleDocumentQAClick = () => {
    localStorage.setItem('appIdValue', '2'); 
    setChatHistory([]); 
    setAppId('2'); 
    setActive(null);
    AppMode()
  };

  const handleSummarizationClick = () => {
    localStorage.setItem('appIdValue', '3'); 
    setChatHistory([]); 
    setAppId('3'); 
    setActive(null);
    AppMode()
  };
  
// Start code change for ChatHPE-16
const items = [
  { label: 'AI Chat', onClick: handleAIChatClick },
  { label: 'Document Q&A', onClick: handleDocumentQAClick },
  { label: 'Document Summarization', onClick: handleSummarizationClick },
];


  return <Box>
    <Menu
      style={botResponse ? { pointerEvents: 'none', opacity: 0.4 } : {}}
      className={`${theme} new-session-plus-button`}
      icon={<Add  size='small' color='#FFFFFF' />}
      items={items}
      //START CODE CHATHPE-740
      disabled={localStorage.getItem('tokensUsed')===localStorage.getItem('tokenUsageTotal')}
      //END CODE
      dropProps={{ align: { top: 'top', left: 'left' }, className: `${theme} chat-mode-selection` }}
      />
  </Box>
};
// End code change for ChatHPE-16
export default DropdownMenu;

