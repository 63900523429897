import { jwtDecode } from "jwt-decode";

function checkTokenExpired() {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
        return true;
    }
    const decodeToken = jwtDecode(accessToken);
    console.log('decode',new Date(decodeToken.exp*1000));
    if (decodeToken.exp < Date.now() / 1000) {
        return true;
    }
    return false;
};

export { checkTokenExpired };
