import React, { useEffect, useState } from 'react';
import { css } from 'styled-components';
import { Box, Grommet, CheckBox } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import { useTheme } from '../contexts/ThemeContext';
import * as constClass from '../Constants';
import { useNavigate } from 'react-router-dom';
import { checkTokenExpired } from '../service/TokenService';
import useRegenerateToken from '../components/RenegerateToken';
import { HPEToggleSwitchTheme, checkboxCheckStyle } from '../theme/HPEToggleSwitchTheme';
// import { fetchWithRetry } from '../service/FetchWithRetry';

// const checkboxCheckStyle = css`
//   background-color: #01A982;
//   border-color: #01A982;
// `;
// 
// const customToggleTheme = {
//   global: {
//     colors: {
//       // bg is the pill border
//       'toggle-bg': '#0000005C',
//       'toggle-knob': 'white',
//       'toggle-accent': '#F7F7F7',
//       'focus': '#00E8CF',
//     },
//   },
//   checkBox: {
//     border: {
//       color: {
//         light: 'toggle-bg',
//       },
//     },
//     color: {
//       light: 'toggle-knob',
//     },
//     check: {
//       radius: '2px',
//     },
//     hover: {
//       border: {
//         color: undefined,
//       },
//     },
//     toggle: {
//       background: { light: 'toggle-accent' },
//       color: {
//         light: 'toggle-knob',
//       },
//       size: '36px',
//       knob: {
//         extend: `
//           top: -4px;
//           box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12),
//           0px 2px 2px 0px rgba(0,0,0,0.24);
//         `,
//       },
//       extend: ({ checked }) => `
//         height: 14px;
//         ${checked && checkboxCheckStyle}
//       `,
//     },
//     gap: 'large',
//     size: '18px',
//   },
// };



export const CustomToggle = ({isDarkMode, updateDarkMode}) => {
  //START CODE CHATHPE-436   
  if(isDarkMode===null || isDarkMode===undefined){
    isDarkMode=localStorage.getItem('isDarkMode')==='true'?true:false;
  }
  //END CODE  
  const { getNewToken } = useRegenerateToken();
  const [checked, setChecked] = useState(isDarkMode);
  const { toggleTheme } = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (checked !== undefined && checked !== null) {
      toggleTheme(checked);
    } else {
      toggleTheme(localStorage.getItem('isDarkMode'));
    }
  }, [checked]);

  const changeMode = async (newChecked) => {
    setChecked(newChecked);
    updateDarkMode(newChecked);
    localStorage.setItem('isDarkMode', newChecked);

    if (checkTokenExpired()) {
      if (await getNewToken()) {
        callModeChgApi();
      }
    } else {
      callModeChgApi();
    }
  };

  const callModeChgApi = async () => {
    try {
      // TEMP REMOVAL OF 30 SEC TIMEOUT
      // const response = await fetchWithRetry(constClass.setApiUrl(localStorage.getItem('region'))+constClass.MODE, {
      const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.MODE, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Client-ID': constClass.CLIENT,
          Connection: 'keep-alive',
        },
        body: JSON.stringify({
          //CHATHPE-809 : dark_mode value is not upto date in chatHPE_bot_data so directly fetching from local storage
          dark_mode: localStorage.getItem('isDarkMode') === 'true' ? true : false,
          chatHPE_bot_data: JSON.parse(localStorage.getItem('chatBotData')),
        }),
      });

      const newData = await response.json();

      if (response.status === 200) {
        // Do something with the data
      } else {
        navigate('/error', { state: { errorMessage: newData['message'] } });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      navigate('/error');
    }
  };

  return (
    <Grommet theme={deepMerge(grommet, HPEToggleSwitchTheme)} background="transparent">
      <Box>
        <CheckBox
          checked={checked}
          onChange={(event) => changeMode(event.target.checked)}
          reverse
          toggle
        />
      </Box>
    </Grommet>
  );
};

export default {
  title: 'Input/CheckBox/Custom Themed/Custom toggle',
};
