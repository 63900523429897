import { Box, Grommet, Heading, Text } from 'grommet';
import { Hpe } from 'grommet-icons';
import React, { Component } from 'react';

class ErrorBoundry extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error caught by error boundary:', error, errorInfo);
        this.setState({ hasError: true, error });
    }



    render() {
        const { hasError, error } = this.state;

        return hasError ? (
            <Grommet full>
                <Box fill align="center" justify="center" alignSelf="center">
                    <Box width="large">
                        <Box align="center" pad="large" border="small" round>
                            <Hpe size="medium" color="#01A982" />
                            <Heading level={2} weight={900} color="red">An error has occurred, please return to the login page and try again. If the issue persists, please reach out to ChatHPESLATeam@hpe.com</Heading>
                            <Text>{error ? error.toString() : 'An error occurred.'}</Text>
                        </Box>
                    </Box>
                </Box>
            </Grommet>
        ) : (
            this.props.children
        );
    }
}

export default ErrorBoundry;