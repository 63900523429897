import React, { useRef, useState, useEffect } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Grommet, Box, Text, TextInput, DateInput, RadioButtonGroup, Button } from 'grommet';
import { Close } from 'grommet-icons';

const customTheme = {
  radioButton: {
    size: "16px", 
  },
};

export const SearchComponent = ({ allSessions, setFilteredSession, botResponse, isSearchOpen }) => {
    const { theme } = useTheme();
    // const [dateValue, setDateValue] = useState(new Date().toISOString());
    const [dateValue, setDateValue] = useState();
    const [textValue, setTextValue] = useState('');
    const [radioValue, setRadioValue] = useState('Name');
    const [activeDate, setActiveDate] = useState(new Date().toISOString());
    const textInputRef = useRef();  // Ref for text input field


    //START CODE CHATHPE-829
    // This code looks for the search field and focuses on it when the search field is opened.
    useEffect(() => {
      if (isSearchOpen && textInputRef.current) {
        setTimeout(() => {
          textInputRef.current?.focus();
        }, 100); // Adjust delay as necessary
      }
    }, [isSearchOpen, radioValue]);
    //END CODE


    const calendarStyle = theme === 'dark' ? {
      size: 'small',
      width: 'auto',
      daysOfWeek: true,
      // fill: true,
      style: {
        width: '100%', 
        backgroundColor: '#222222',
        color: 'white'
      }
    } : {
      size: 'small',
      width: 'auto',
      daysOfWeek: true,
      focus: 'background',
      // fill: true,
      style: {
        width: '100%', 
        backgroundColor: 'white',
        color: 'black'
      },
    };


    useEffect(() => {
        setTextValue('');
        setDateValue('');
        // if(radioValue!='Name'){
        //   setDateValue(new Date().toISOString());
        // }
      // }, [radioValue, allSessions]); //commenting out for removing date filter
    },[allSessions]);

    useEffect(() => {
        let updatedSession = [];
        
        // Filter by date if dateValue is provided
        if (dateValue) {
          let selectedDate = dateValue.substring(0, 10);
          updatedSession = allSessions.filter(eachSession => 
            eachSession['endDateTime'].substring(0, 10) === selectedDate
          );
        } else {
          updatedSession = [...allSessions];
        }
      
        // Filter by text if textValue is provided
        if (textValue) {
          console.log('textValue: ', textValue);
          const lowercaseTextValue = textValue.trim().toLowerCase();  // Trim leading/trailing spaces
          const searchWords = lowercaseTextValue.split(/\s+/);  // Split into words
      
          updatedSession = updatedSession.filter(eachSession => {
            const sessionName = eachSession.sessionName.toLowerCase();
            return searchWords.every(word => sessionName.includes(word));
          });
        }
      
        setFilteredSession(updatedSession);
      }, [dateValue, textValue, allSessions]); // Ensure allSessions is included in dependencies

      //START CODE CHATHPE-814
      const handleClearFilter = () => {
        setTextValue('');
        setDateValue('');
        setFilteredSession(allSessions);
      };
      //END CODE
      
      return (
        <Grommet theme={customTheme}>
          {/* CHATHPE-826  Restricting user from using Searchfield when waiting for response.*/}
          <Box style={{marginBottom:'5px', ...(botResponse ? { pointerEvents: 'none', opacity: 0.4 } : {})}}> 
            {/* <Box direction="row" gap="medium">
              <RadioButtonGroup
                options={['Name', 'Date']}
                // options={['Name', 'Date']}
                value={radioValue}
                onChange={(event) => setRadioValue(event.target.value)}
                direction='row'
                background={(`${theme}` === 'light' ? 'white' : '#201c1c')} 
              />
            </Box> */}
            <Box direction='row' justify='space-around' margin={{bottom: "small", top:"small"}} width="95%">
              {radioValue === 'Name' ? (
                <TextInput 
                  ref={textInputRef}  
                  placeholder="Search by session name"
                  value={textValue}
                  style={{
                    borderColor: 'grey',
                    marginTop: "0",
                    height: '42px', // Set the desired height
                    fontSize: '16px', // Set the desired font size
                  }}
                  onChange={(event) => setTextValue(event.target.value)}
                />
              ) : (
                <DateInput
                  format="mm/dd/yyyy"
                  // value={dateValue==='' ? (new Date().toISOString()) : dateValue} 
                  // value={(new Date()).toISOString() || dateValue} 
                  // value = {dateValue}
                  value={dateValue || new Date().toISOString()}
                  size='xsmall'
                  // calendarProps={calendarStyle}
                  calendarProps={{ ...calendarStyle, activeDate: dateValue || activeDate }}
                  onChange={({ value }) => {
                    if (!isNaN(new Date(value))) {
                      const localDate = new Date(value);
                      const adjustedDate = new Date(
                        Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate())
                      );
                      console.log(adjustedDate);
                      // if (dateValue === adjustedDate.toISOString()) { 
                      //   setDateValue('');
                      // } else {  
                      //   setDateValue(adjustedDate.toISOString());
                      // }
                      setDateValue(adjustedDate.toISOString());
                      setActiveDate(adjustedDate.toISOString());
                    } else {
                      console.log('Invalid date format');
                    }
                  }}
                  inputProps={{
                    placeholder: 'mm/dd/yyyy',
                    readOnly: true
                  }}
                />
              )}
              { (textValue || dateValue) &&
              (
                <Close size='medium' style={{marginTop:'8px', paddingLeft: '10px', cursor: 'pointer'}} onClick={handleClearFilter}></Close>
              )
              }
            </Box>
          </Box>
        </Grommet>  
      );
}
