import * as constClass from '../Constants';
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom/dist";
import { jwtDecode } from "jwt-decode";

function useRegenerateToken() {

    const { instance, accounts } = useMsal();
    const navigate = useNavigate()
    let returnVal = null;
//     const graphToken = instance.acquireTokenSilent({
//         scopes: [ "User.Read", "User.Read.All",], // all MS Graph API scopes
//         account: accounts[0],
//    })
//    console.log("token  new seperate")
//    console.log(graphToken)
    const getNewToken = async () => {
        localStorage.removeItem('accessToken')
        try {
            await instance.acquireTokenSilent({
                scopes: [
                    constClass.LOGIN_SCOPE,
                ],
                account: accounts[0],
            })
                .then((response) => {
                    const decodeToken = jwtDecode(response.accessToken);
                    if (decodeToken.roles && decodeToken.roles.includes(constClass.AD_GROUP)) {
                        localStorage.setItem('accessToken', response.accessToken)
                        returnVal = true;
                    } else {
                        returnVal = false;
                        localStorage.clear()
                        navigate('/permission')
                    }
                });

                instance.acquireTokenSilent({
                    scopes: [constClass.USER_READALL_SCOPE,constClass.USER_READ_SCOPE,], // all MS Graph API scopes
                    account: accounts[0],
               }).then((response) => {
                localStorage.setItem('graphToken', response.accessToken)
            });
        } catch (error) {
            console.error('An error occurred:', error)
            navigate('/error')
        }

        return returnVal
    };
    return {
        getNewToken
    }
}

export default useRegenerateToken;