import React, { useCallback, useImperativeHandle, useRef } from 'react';
import { Box, Button, Heading, Layer, Menu, Notification, Text, TextInput, Tip, Select, FormField, Collapsible } from 'grommet';
import { Checkmark, Close, HelpOption, Logout, Moon, More, StatusWarning, ChatOption, DocumentText, StarOutline, Star, Down, Search, Up } from 'grommet-icons';
import { useEffect } from 'react';
import { useState } from 'react';
import SettingsPopup from './SettingsPopup';
import LoadingIcon from '../dialog/LoadingIcon';
import { CustomToggle } from './Toggle';
import { useNavigate } from "react-router-dom";
import * as constClass from '../Constants';
import { useTheme } from '../contexts/ThemeContext';
import DownloadChat from '../components/DownloadChat';
import { checkTokenExpired } from '../service/TokenService';
import useRegenerateToken from '../components/RenegerateToken';
import Confirmation from '../dialog/Confirmation';
import DropdownMenu from './DropdownMenu'
import HeaderNav from './HeaderNav';
import FileUploadDialog from './FileUploadDialog'
import { backgrounds } from 'grommet-theme-hpe';
import { SearchComponent } from '../components/SearchComponent';
import { all } from 'axios';
import { filter } from 'jszip';
// import { fetchWithRetry } from '../service/FetchWithRetry';

const SessionList = React.forwardRef((props, ref) => {
  const { getNewToken } = useRegenerateToken();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [allSessions, setSessions] = useState(props.sessions);
  const [fullSession, setFullSession] = useState(props.sessions);      
  const [filteredSession, setFilteredSession] = useState(props.session);
  const [newVal, setNewVal] = useState("");
  const [editingIndex, setEditingIndex] = useState(-1);
  const [open, setOpen] = React.useState();
  const [getSessionID, setSessionId] = React.useState();
  const [active, setActive] = useState(null)
  const myRef = useRef();
  const fileRef = useRef();
  const spinnerRef = useRef();
  const confirmationRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = useRef(false)
  const downloadRef = useRef();
  const loading = (e) => spinnerRef.current.LoadingStart(e);
  const [chatBotData, setChatBotData] = useState(props.chatHPE_bot_data)
  // const [chat_hist, setChatHist] = useState()
  const [allUserSessions, setAllUserSessions] = useState();
  const [index, setIndex] = useState(-1)
  const [downloadDisabled, setDownloadDisabled] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);  
  const [showNoSearchResults, setShowNoSearchResults] = useState(false);
  // const [appId, setAppId] = useState(JSON.parse(localStorage.getItem('appIdValue')) || '1');


  useEffect(() => {
    const sortedListItems = [...props.sessions].sort((a, b) => new Date(b.endDateTime) - new Date(a.endDateTime));
    const sessId = localStorage.getItem('activeItem')!==null && localStorage.getItem('activeItem')!=='undefined'?JSON.parse(localStorage?.getItem('activeItem')):null;


    // console.log("newid, sessid, sortledlist", newid);
    
    if (sessId != null) {
      if (sessId) {
        //CHATHPE-825 Now directly using sessId.id for active sessions. Previously it didnt have clientid
        props.activeFirst ? setActive(sessId.id) : setActive(sessId.id); 
      } else {
        props.activeFirst ? setActive(sessId.id) : setActive(null);
      }
    }
    else {
      setActive(null);
    }
    setSessions(sortedListItems);
  }, [props.sessions]);

    useEffect(()=>{
      setFilteredSession(allSessions);
    }, [allSessions])

    useEffect(()=>{
        console.log(filteredSession?.length);
        setShowNoSearchResults(filteredSession?.length === 0);
    }, [filteredSession])

  // useEffect(()=>{
  //   setActive("11ec39216-c652-463f-9aeb-28e9ead5761e.156")
  // }, [active])

  const listItemRef = useRef(null);
  const [isClickedOutside, setIsClickedOutside] = useState(false);

  //START CODE CHATHPE-504  11/03/2024
  useEffect(() => {
    if (props.getChatHistory.length === 0) {
      setDownloadDisabled(true);
    }
    else {
      setDownloadDisabled(false);
    }
  }, [props.getChatHistory])
  //END CODE

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listItemRef.current && !listItemRef.current.contains(event.target)) {
        setIsClickedOutside(true);
      } else {
        setIsClickedOutside(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const onOpen = (e, sess_id) => {
    e.stopPropagation();
    setOpen(true);
    setSessionId(sess_id);
  };

  const onClose = () => setOpen(undefined);

  const displayConfirmation = (sess_name, sess_id) => {
    // (sess_name === newVal || newVal === "") ? cancelEdit() : 
    //setIsModalOpen(true)
    openModal.current = true
    setIsClickedOutside(false);
    confirmationRef.current.onOpen(true, sess_id)
  };

  const handleEdit = (e, index, sess_name) => {
    e.stopPropagation();
    setEditingIndex(index);
    setNewVal(sess_name);
  };

  const download = (e, sess_name) => {
    console.log(sess_name)
    e.stopPropagation();
    downloadRef.current.downloadCsvFile(e,sess_name, props.getChatHistory)
  };

  const saveEdit = async (sessionId) => {
    const trimmedVal = newVal.trim();
  if (trimmedVal === "") {
    console.error('Session name cannot be empty.');
    return;
  }
    loading(true);
    setIsModalOpen(false)
    openModal.current = false
    setIsClickedOutside(false)
    if (checkTokenExpired()) {
      if (await getNewToken()) {
        callRenameSessApi()
      }
    } else {
      callRenameSessApi();
    }
    const getActiveSession = localStorage.getItem('activeItem');
    async function callRenameSessApi() {
      try {
        // TEMP REMOVAL OF 30 SEC TIMEOUT
        const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.RENAME_SESS, {
        // const response = await fetch(constClass.RENAME_SESS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            "Client-ID": constClass.CLIENT,
            "Connection": 'keep-alive'
          },
          body: JSON.stringify({ sessionId: index, new_name: newVal, chatHPE_bot_data: JSON.parse(localStorage.getItem('chatBotData')) })
        });
        const newData = await response.json();

        //Added condition for Authentication to fix the uncaught runtime error. Issue 146
        if (response.status===200) {
          props.sessionListCallback(newData);
          setSessions(newData.all_sess);
          localStorage.removeItem('chatBotData')
          const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
          localStorage.setItem('chatBotData', chatBotDataString);
          setEditingIndex(-1);
          setActive(getActiveSession.id);
          loading(undefined);
        }
        else {
          navigate('/error', { state: { errorMessage: newData['message'] } });
        }
      } catch (error) {
        console.error('An error occurred:', error)
        navigate('/error')
      }
      setNewVal("")
      // setIsDraft(false)
    }
  };

  const cancelEdit = (index) => {
    openModal.current = false
    setIsModalOpen(false)
    setEditingIndex(-1);
  }



const filterSession = (filterFor) => {
  return (filteredSession ? filteredSession : allSessions)
  .filter(item => { 
    const itemDate = new Date((item.endDateTime.slice(-1) !== "Z") ? item.endDateTime + "Z" : item.endDateTime);
    if(filterFor === 'Today' && item.isFavourite!==1){
      return itemDate.toDateString() === today.toDateString();
    }
    else if (filterFor === 'Previous7days' && item.isFavourite!==1){
      return (itemDate > previous7Days && itemDate < today) && (itemDate.toDateString() !== today.toDateString());
    }
    else if(filterFor === 'previous30days' && item.isFavourite!==1){
      return (itemDate > previous30Days && itemDate < previous7Days && itemDate < today) && (itemDate.toDateString() !== today.toDateString());
    }
    else if(filterFor === 'previous90days' && item.isFavourite!==1){
      return (itemDate > previous90Days && itemDate < previous7Days && itemDate < today) && (itemDate.toDateString() !== today.toDateString());
    }
    else if(filterFor === 'Favourites'){
      return item.isFavourite === 1;
    }
  })
  .sort((a, b) => {
    const dateA = new Date((a.endDateTime.slice(-1) !== "Z") ? a.endDateTime + "Z" : a.endDateTime);
    const dateB = new Date((b.endDateTime.slice(-1) !== "Z") ? b.endDateTime + "Z" : b.endDateTime);

    return dateB - dateA;
})
}

const today = new Date();
const previous7Days = new Date(today);
previous7Days.setDate(today.getDate() - 7);
const previous30Days = new Date(today);
previous30Days.setDate(today.getDate() - 30);
const previous90Days = new Date(today);
previous90Days.setDate(today.getDate()-90);

const sessionTypes = [
  { title: 'Favorites', indexTitle: 'Favourites', listArray: filterSession('Favourites') },
  { title: 'Today', indexTitle: 'Today', listArray: filterSession('Today')},
  { title: 'Previous 7 Days', indexTitle: 'Prev7Days', listArray: filterSession('Previous7days')},
  { title: 'Previous 30 Days', indexTitle: 'Prev30Days', listArray: filterSession('previous30days') },
  { title: 'Previous 90 Days', indexTitle: 'Prev90Days', listArray: filterSession('previous90days') },
];

  const callFavourite = (e, sess) =>{
    loading(true);
    callModSess(sess);
  }


  const callModSess = async (session) => {
    if (checkTokenExpired()) {
        if (await getNewToken()) {
            callModSessApi(session)
        }
    } else {
        callModSessApi(session);
    }
    async function callModSessApi() {
        try {
            const chat_data = JSON.parse(localStorage.getItem('chatBotData'))
            const response = await fetch(constClass.setApiUrl(localStorage.getItem('region')) + constClass.MOD_SESS, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                    "Client-ID": constClass.CLIENT
                },
                body: JSON.stringify({ appId: localStorage.getItem('appIdValue'), sessionId: session.sessionId, isFavourite: (session.isFavourite === 1) ? 0 : 1, chatHPE_bot_data: chat_data })
            });
            const newData = await response.json();

            //Added condition for Authentication to fix the uncaught runtime error. Issue 146
            if (response.status===200) {
                console.log('newData: ', newData);
                  setSessions(newData.all_sess);
                // console.log('after modification: ,', newData);
                // props.settingsCallBackHandler(newData, session.sessionId)
                // setAssistBehaviour(newData.all_sess.behaviour);
                // setValue(newData.all_sess.model);
                // setCreativityLevel(newData.all_sess.temperature);
                // setResponseLength(newData.all_sess.max_response_tokens);
                // setChunkSize(newData.all_sess.chunkSize);
                // setChunkOverlap(newData.all_sess.chunkOverlap);
                // setSearchType(newData.all_sess.searchType);
                // setTopN(newData.all_sess.topN);
                // setIsConcise(newData.all_sess.is_concise);
                // setIsBulleted(newData.all_sess.is_bulleted);
                // setDoubeSummarise(newData.all_sess.double_summarise);
                // localStorage.removeItem('chatBotData')
                // const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
                // localStorage.setItem('chatBotData', chatBotDataString);
                loading(undefined);
                // setOpen(false);
                // console.log(originalChunkOverlap.current, chunkOverlap, 'j');
                // console.log(originalChunkSize.current, chunkSize, 'j');

                //START CODE CHATHPE-233 14/02/2024 
                // if (localStorage.getItem('appIdValue') === '2' && (originalChunkOverlap.current !== Number(chunkOverlap) || originalChunkSize.current !== Number(chunkSize))) {
                //     props?.embedding(true)
                //     props?.createEmbedApi()

                //     setIsChunkChange(false);
                // }
                //END CODE
            }
            else {
                navigate('/error', { state: { errorMessage: newData['message'] } });
            }
        } catch (error) {
            console.error('An error occurred:', error)
            navigate('/error')
        }
    }
};

  const logout = async () => {
    if (checkTokenExpired()) {
      if (await getNewToken()) {
        callLogoutApi()
      }
    } else {
      callLogoutApi();
    }
    // const callLogoutApi = async () => {
    async function callLogoutApi() {
      try {
        // TEMP REMOVAL OF 30 SEC TIMEOUT
        const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.LOGOUT, {
        // const response = await fetch(constClass.LOGOUT, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            "Client-ID": constClass.CLIENT,
            "Connection": 'keep-alive'
          }
        });
        const newData = await response.json();
        if (!newData.authentication) {
          localStorage.clear();
          navigate('/logout')
        }
        else {
          navigate('/error', { state: { errorMessage: newData['message'] } });
        }
      } catch (error) {
        console.error('An error occurred:', error)
        navigate('/error')
      }
    }

  }

  const deleteSession = useCallback(async (sessionId) => {
    loading(true);
    if (checkTokenExpired()) {
      if (await getNewToken()) {
        callDeleteSessApi()
      }
    } else {
      callDeleteSessApi();
    }
    async function callDeleteSessApi() {
      try {
        // TEMP REMOVAL OF 30 SEC TIMEOUT
        const response = await fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.DEL_SESS, {
        // const response = await fetch(constClass.DEL_SESS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            "Client-ID": constClass.CLIENT,
            "Connection": 'keep-alive'
          },
          body: JSON.stringify({ sessionId: sessionId, chatHPE_bot_data: JSON.parse(localStorage.getItem('chatBotData')) })
        });
        const newData = await response.json();
        //Added condition for Authentication to fix the uncaught runtime error. Issue 146
        if (response.status===200) {
          props.sessionChatHistoryCallback(newData);
          props.sessionListCallback(newData);
          setSessions(newData.all_sess);
          localStorage.removeItem('fileList');
          localStorage.removeItem('chatBotData')
          const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
          localStorage.setItem('chatBotData', chatBotDataString);
          props.AppMode();
          onClose();
          loading(undefined);
        }
        else {
          navigate('/error', { state: { errorMessage: newData['message'] } });
        }
      } catch (error) {
        console.error('An error occurred:', error)
        navigate('/error')
      }
    }

  }, []);

  const clickHandler = useCallback(async (e, sess, sessId) => {
    // localStorage.removeItem('activeItem');
    // console.log(isModalOpen, 'rrrrr');
    if (openModal.current) {
      return
    }
    props.setSavedFiles([])
    // const theCurrentSession = JSON.parse(localStorage.getItem('chatBotData'));
    // const theTargetSession = sessId;
    // if (theCurrentSession.sessionId != theTargetSession) {
    const item = JSON.stringify(sess);
    // console.log("sess : ", sess);
    localStorage.setItem('activeItem', item);
    loading(true);
    if (checkTokenExpired()) {
      if (await getNewToken()) {
        callOldSessApi();
      }
    } else {
      callOldSessApi();
    }
    setActive(sess.id);
    // }
    function callOldSessApi() {
      //START CODE    CHATHPE-283   14/02/2024   
      const model = sess.model || sess.model_name;
      const response_token_limit = sess.max_response_tokens || sess.response_token_limit;
      //END CODE
      // TEMP REMOVAL OF 30 SEC TIMEOUT
      fetch(constClass.setApiUrl(localStorage.getItem('region'))+constClass.OLD_SESS, {
      // fetch(constClass.OLD_SESS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
          "Client-ID": constClass.CLIENT,
          "Connection": 'keep-alive'
        },
        body: JSON.stringify({ sessionId: sessId, appId: sess.appId, model: model, temperature: sess.temperature, behaviour: sess.behaviour, response_token_limit: response_token_limit, chatHPE_bot_data: JSON.parse(localStorage.getItem('chatBotData')) })
      }).then(response => response.json())
        .then(response => {
          // console.log("response: ", response);
          //Added condition for Authentication to fix the uncaught runtime error. Issue 146
          if (response) {
            // After the user is logged in and is permitted to use the application we set a variable to hold all sessions for use elseware
            setAllUserSessions(response);
            localStorage.setItem('appIdValue', response.chatHPE_bot_data.appId)
            props.sessionChatHistoryCallback(response);
            localStorage.removeItem('chatBotData')
            const chatBotDataString = JSON.stringify(response.chatHPE_bot_data);
            const chatHistoryString = JSON.stringify(response.chat_history);
            const parsedHistory = JSON.parse(chatHistoryString);
            props.validateRegenButton(parsedHistory.length);

            localStorage.setItem('chatBotData', chatBotDataString);
            // console.log(localStorage.getItem('chatBotData'));
            localStorage.setItem('fileList', JSON.stringify(response.files));
            // props.setAppId(response.chatHPE_bot_data.appId);
            // console.log("appid, localstodate: ", props.appId, localStorage.getItem('appIdValue'));
            props.setChatHistory(response.chat_history);
            props.moveInputBox();
            stateManager(localStorage.getItem('appIdValue'))
            loading(undefined);
          }
          else {
            navigate('/error', { state: { errorMessage: response['message'] } });
          }

        }).catch(error => {
          navigate('/error')
        })
    }
  }, []);

  useImperativeHandle(ref, (e) => ({
    clickHandler(e, sess, sess_id) {
      clickHandler(e, sess, sess_id)
    }
  }));

  const settings = (e, sess) => {
    e.stopPropagation();
    myRef.current.onOpen1(sess);
  };

  const settingsClickHandler = (response) => {
  //  props.sessionChatHistoryCallback(response);
    props.sessionListCallback(response);
    setSessions(response.all_sess);
  }

  const downloadPdf = () => {
    const pdfUrl = constClass.USER_GUIDE;

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank'
    link.download = 'User-Guide.pdf';
    link.click();
  };

  // Start code change CHATHPE-203
  function stateManager(theNewState) {
    if (theNewState === '1') {
      // console.log("Switching state to AI Chat Mode");
      props.setNewMessage("") // Code change for ChatHPE-357
      props.setTitleSubHeading("AI Chat");
      props.setTextBarDisabled(false);
      props.setPromptButtonVisable(true);
      props.setIsActionBarVisible(false);
      props.setRegenerateButtonVisable(true);
    } else if (theNewState === '2') {
      // console.log("Switching state to Doc Q&A");
      props.setNewMessage("") // Code change for ChatHPE-357
      props.setTitleSubHeading("Document Q&A");
      props.setTextBarDisabled(false);
      props.setPromptButtonVisable(true);
      props.setProcessButtonDisabled(true);
      props.setDeleteButtonDisabled(false);
      props.setIsActionBarVisible(true);
      props.setAttachButtonDisabled(true);
      // props.validateRegenButton();
    } else if (theNewState === '3') {
      // console.log("Switching state to Doc Sum");
      props.setNewMessage("") // Code change for ChatHPE-357
      props.setTitleSubHeading("Document Summarization");
      props.setTextBarDisabled(false);
      props.setPromptButtonVisable(false);
      props.setSummarizeButtonDisabled(false);
      // props.setDeleteButtonDisabled(false);
      props.setIsActionBarVisible(true);
      props.setAttachButtonDisabled(true);
      // props.validateRegenButton();
    } else {
      console.log("Incorrect app type");
    }
  }
  // End code change CHATHPE-203

  const SessionListItems = ({title, listArray, indexTitle}) => {
    return(
    <>
    <Text style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Light", marginTop: '15px' }} justify="start" size='10px' weight={400} alignSelf='start' pad="none" hidden={!listArray.length} color="#6F6F6F">{title}</Text>
    <ul pad="none" style={{ margin: 0 }}>
    {listArray.map((sess, index) => {
                const sess_id = sess.sessionId;
                const sess_completeId = sess.id;
                const sess_name = sess.sessionName;
                const sess_appId = sess.appId;
                const sess_fav = sess.isFavourite;
                const truncatedName = sess_name.length > 36 ? sess_name.substring(0, 36) + '...' : sess_name;
                const idx = indexTitle + index;
                return (<li key={sess.sessionId} className={`list-group-item ${active === sess_completeId && 'active'}`}>
                  {editingIndex === idx ? (
                    <Box
                      direction="row"
                      align="center"
                      round="small"
                      onBlur={(e) => { e.stopPropagation(); displayConfirmation(sess_name, sess_id) }}
                      ref={listItemRef}
                      border>
                      <TextInput
                        plain
                        maxLength={20}
                        value={newVal}
                        placeholder="Enter a name"
                        {...props}
                        required
                        autoFocus={true}
                        size="xsmall"
                        onChange={event => {
                          setNewVal(event.target.value);
                          setIndex(sess_id)
                        }}
                      />
                      <Button
                        icon={<Close size="small" />}
                        onClick={cancelEdit}
                      />
                      <Button
                        icon={<Checkmark size="small" />}
                        disabled={sess_name === newVal || newVal === ""}
                        onClick={() => saveEdit(sess_id)}
                      />
                    </Box>
                  ) : (
                    <Box direction='row' onClick={(e) => clickHandler(e, sess, sess_id)}>
                      {/* <DocumentText style={{ paddingRight: '8px' }} size='small' /> */}
                      <Box direction='row' align='center' width="90%">
                        <Box direction='column'>
                          <Button disabled={props.isDisabled} name={sess_name} id={sess_id} userId={sess.userid}>{truncatedName}</Button>
                          {/* <Text style={{fontSize: 14, fontFamily: "MetricHPE-Web-Regular"}}>AI Chat</Text> */}
                          {sess_appId === '1' && (
                            <Text style={{ fontSize: 14, fontFamily: "MetricHPE-Web-Regular" }}>AI Chat</Text>
                          )}
                          {sess_appId === '2' && (
                            <Text style={{ fontSize: 14, fontFamily: "MetricHPE-Web-Regular" }}>Document Q&A</Text>
                          )}
                          {sess_appId === '3' && (
                            <Text style={{ fontSize: 14, fontFamily: "MetricHPE-Web-Regular" }}>Document Summarization</Text>
                          )}
                        </Box>
                      </Box>
                      <Box alignContent='end' justify='center' align='center' alignSelf='center' direction='row'>
                      {/* Start code change for ChatHPE-511 */}
                        {/* <Tip dropProps={{ style: { backgroundColor: (`${theme}` === 'light' ? '#F7F7F7' : '#222222'), size: 'small', borderRadius: '16px', border: 'none', overflow: 'hidden', } }} content={<Text size='10px' className={`${theme} tooltip`}>More</Text>}> */}
                        {/* End code change for ChatHPE-511 */}
                          <Menu
                            /* ChatHPE-461   Date: 04/03/24*/
                            dropProps={{ align: { top: 'bottom', left: 'left' }, className: `${theme} list-panel` }}
                            icon={<More className={`more-icon ${active === sess_completeId && 'active'}`} />}
                            size="xsmall"
                            key={sess_id}
                            justify='center'
                            disabled={props.isDisabled}
                            onClick={event => event.stopPropagation()}
                            id={sess_id}
                            items={[
                              { label: 'Rename', onClick: (e) => { handleEdit(e, idx, sess_name) } },
                              { label: !sess_fav?'Favorite':'Unfavorite', onClick: (e) => { callFavourite(e, sess); e.stopPropagation() } },
                              { label: 'Download', onClick: (e) => { download(e, sess_name) }, disabled: downloadDisabled },
                              { label: 'Settings', onClick: (e) => { settings(e, sess) } },
                              { label: 'Delete', id: sess_id, onClick: (e) => { onOpen(e, sess_id) } },
                            ]}
                          />
                        {/* </Tip> */}
                      </Box>
                    </Box>
                  )}
                </li>)
              })}
    </ul>
    </>
    )
  }

  return (
    <>
      <LoadingIcon ref={spinnerRef} />
      <DownloadChat ref={downloadRef} />
      {/* // Start code change for ChatHPE-526 */}
      <SettingsPopup ref={myRef} settingsCallBackHandler={settingsClickHandler} allSessions={allSessions} chatHPE_bot_data={chatBotData} createEmbedApi={props.createEmbedApi} embedding={props.embedding} />
      {/* // End code change for ChatHPE-526 */}
      <Confirmation ref={confirmationRef} cancelHandler={() => { cancelEdit() }} saveHandler={saveEdit} newVal={newVal} />
      <Box justify='top' style={{ flexWrap: "wrap", justifyContent: "space-between", paddingBottom: '8px' }} direction="row" align='center'>
        <Text className={`${theme} sessions-text`} style={{ color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), fontSize: 24, fontFamily: "MetricHPE-Web-Regular" }}>Sessions</Text>
        <Box style={props.botResponse ? { pointerEvents: 'none', opacity: 0.4 } : {}} direction='row'>
          {isSearchOpen ? (
            <Up style={{marginRight: '10px', marginTop: '7px', cursor: 'pointer'}} onClick={()=>setIsSearchOpen(false)}></Up>
          ) : (
            <Search style={{marginRight: '10px', marginTop: '7px', cursor: 'pointer'}} onClick={()=>setIsSearchOpen(true)}></Search>
          )}
          <DropdownMenu botResponse={props.botResponse} setAppId={props.setAppId} AppMode={props.AppMode} newChat={props.newChat} setChatHistory={props.setChatHistory} setActive={setActive}/>
        </Box>
      </Box>
      <Collapsible open={isSearchOpen}>
      <SearchComponent isSearchOpen={isSearchOpen} botResponse={props.botResponse} setSessions={setSessions} allSessions={allSessions} fullSession={fullSession} setFullSession={setFullSession} setFilteredSession={setFilteredSession}/> 
      </Collapsible>
      {/* <Box pad="none" margin="none" height={{ max: '70%' }} overflow="auto"> */}
      <Box flex justify='space-between' style={{ margin: 0, padding: 0 }}>
        {
          showNoSearchResults && (
            <p style={{fontFamily: "MetricHPE-Web-Regular"}}>No results found</p>
          )
        }
        <Box style={props.botResponse ? { pointerEvents: 'none', opacity: 0.4 } : {}} pad="none" margin="none" height={{ max: '100%' }} overflow="auto">
          {open && (
            <Layer
              id="hello world"
              position="center"
              onClickOutside={onClose}
              onEsc={onClose}
              className={`${theme} deletePane`}
              style={{ borderRadius: '12px', border: 'none' }}
            >
              <Box className={`${theme} deletePane`} pad="small" gap="small" width="medium">
                <Heading level={3} margin="none">
                  Delete session
                </Heading>
                <Text>Are you sure you want to delete this chat session?</Text>
                <Box
                  as="footer"
                  gap="small"
                  direction="row"
                  align="center"
                  justify="end"
                  pad={{ top: 'medium', bottom: 'small' }}
                >
                  <Button
                    className={`${theme} feedbackBoxCancelButton`} style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Bold" }}
                    label={
                      <Text>
                        Cancel
                      </Text>
                    }
                    onClick={onClose}
                    // plain
                    color="status-critical"
                  />
                  <Button
                    className={`${theme} deletePaneDeleteButton`}
                    style={{ border: 'none', color: '#FFFFFF' }}
                    label={
                      <Text color="white">
                        <strong>Delete</strong>
                      </Text>
                    }
                    onClick={() => deleteSession(getSessionID)}
                    primary

                  />
                </Box>
              </Box>
            </Layer>
          )}
        <Box>
          {sessionTypes.map((type) => (
              <SessionListItems title={type.title} listArray={type.listArray} indexTitle={type.indexTitle} />
           ))}
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default SessionList;