import { appSecrets } from "./service/SecretManager";

export function setApiUrl(region) {
    switch(region) {
        case 'emea.cpqcorp.net':
            return process.env.REACT_APP_EMEA_API_URL;
        case 'americas.cpqcorp.net':
            return process.env.REACT_APP_AMERICAS_API_URL;
        case 'asiapacific.cpqcorp.net':
            return process.env.REACT_APP_APAC_API_URL;
        default:
            return process.env.REACT_APP_DEFAULT_API_URL;
    }
}

// export const API_URL = 'http://localhost:8080';  
export const DEL_MSG = '/del_cht_msg';
export const DEL_SESS = '/del_sess';
export const UPLOAD_FILES = '/upload';
export const RENAME_SESS = '/mod_sess';
export const LOGOUT = '/logout';
export const OLD_SESS = '/old_sess';
export const START_CHAT = '/chat';
export const NEW_CHAT = '/app';
export const MOD_SESS = '/mod_sess';
export const LOGIN = '/login';
export const AGREEMENT = '/preferences';
export const MODE = '/preferences';
export const STREAM = '/preferences';
export const APP_MODE = '/app';
export const PROCESS_DOCUMENTS = '/process_documents';
export const CREATE_EMBEDDING = '/create_embedding';
export const DOC_CHAT = '/chat';      
export const QUOTA = '/quota';
// export const CLIENT = "242079eb-f1f5-4bf9-afde-836bb4615a2e"
export const CLIENT = process.env.REACT_APP_CLIENT_ID
// export const LOGIN_SCOPE = 'api://242079eb-f1f5-4bf9-afde-836bb4615a2e/userimpersonationchathpe';
/*
defining the scope and graph url to fetch the profile image
 */
export const USER_READ_SCOPE = 'User.Read';
export const USER_READALL_SCOPE = 'User.Read.All';
export const USER_IMAGE_GRAPH_URL = 'https://graph.microsoft.com/v1.0/me/photo/$value';
export const USER_ONPREMISE_DOMAIN_GRAPH_URL = 'https://graph.microsoft.com/v1.0/me?$select=onPremisesDomainName';
// export const USER_GUIDE = "https://hpe.sharepoint.com/:b:/r/teams/Gen_AI/Shared%20Documents/User%20guide/ChatHPE%20User-Guide.pdf?csf=1&web=1&e=3zyI5c"
// export const AD_GROUP = 'chathpeADSecurityGroup'
export const {AD_GROUP,USER_GUIDE,LOGIN_SCOPE,CLIENT_ID,TENANT_ID} = appSecrets;
// 15MB in Bytes
export const MAX_FILE_SiZE = 15000000
export const CHATHPE_VERSION_NUMBER = "2.2.1" 