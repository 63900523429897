import React, { useImperativeHandle } from 'react';
import { useTheme } from '../contexts/ThemeContext';

import { Box, Button, Layer, Spinner, Text } from 'grommet';

const LoadingIcon = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState();
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const { theme } = useTheme();

  const LoadingStart = (e) => {
    setOpen(e);
  };

  const LoadingStop = () => {
    setOpen(undefined);
  }

  useImperativeHandle(ref, (e) => ({
    LoadingStart(e) {
      LoadingStart(e)
    }
  }))

  return (
    <>
      {open && (
        <Layer className={`${theme} loadingPane`} style={{borderRadius: '12px', boxShadow: (`${theme}` === 'light' ? '0px 6px 12px #0000001F' : '0px 6px 12px #0000005C')}}>
          <Box
            style={{borderRadius: '12px'}}
            className={`${theme} loadingPane`}
            align="center"
            justify="center"
            gap="small"
            direction="row"
            alignSelf="center"
            pad="large"
          >
            <Spinner />
            <Text style={{fontSize: 24, fontFamily: "MetricHPE-Web-Regular"}}>Loading...</Text>
          </Box>
        </Layer>
      )}
    </>
  );
});

export default LoadingIcon;