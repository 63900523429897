import React, { useImperativeHandle } from "react";
import { useTheme } from "../contexts/ThemeContext";
import { Box, Button, Layer, Meter, Text } from "grommet";

const ProgressBar = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState();
  const { theme } = useTheme();

  const ProcessingStart = (e) => {
    setOpen(e);
  };

  useImperativeHandle(ref, (e) => ({
    ProcessingStart(e) {
      ProcessingStart(e);
    },
  }));
  const getText = () => {
    if (props.progress >= 0 && props.progress <= (1 / 3) * 100) {
      return 'Uploading'
    }
    else if (props.progress > (1 / 3) * 100 && props.progress <= (2 / 3) * 100) {
      return 'Processing'
    }
    else {
      return props.type === 'summarize' ? "Summarizing" : 'Embedding'
    }
  }
  return (
    <>
      {open && (
        // class and theme can match loading since they show the same detail
        <Layer
          className={`${theme} loadingPane`}
          style={{ borderRadius: "12px", boxShadow: (`${theme}` === 'light' ? '0px 6px 12px #0000001F' : '0px 6px 12px #0000005C')}}
        >
          <Box
            style={{ borderRadius: "12px" }}
            className={`${theme} loadingPane`}
            align="center"
            justify="center"
            gap="small"
            direction="column"
            alignSelf="center"
            pad="large"
          >
            <Box style={{ fontSize: 24, fontFamily: "MetricHPE-Web-Regular",display:'flex',flexDirection:'row' }}>
             <div>{getText()}</div> <Button label='loading' busy={true} style={{border:'none',paddingInline:2}} />
            </Box>
            <Meter
              className={`${theme} meter`}
              values={[
                {
                  value: props.progress,
                  color: '#7630EA',
                },
              ]}

            />
          </Box>
        </Layer>
      )}
    </>
  );
});

export default ProgressBar;