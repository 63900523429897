import React, { useRef, useState, useEffect, useImperativeHandle } from 'react';
import { Box, Text, Button, Heading, Layer, Stack, Markdown, Tip } from 'grommet';
import { Checkmark, Copy, Dislike, DislikeFill, Hpe, Like, LikeFill, Trash, Refresh } from 'grommet-icons';
import UpVoting from '../dialog/UpVoting';
import LoadingIcon from '../dialog/LoadingIcon';
import * as constClass from '../Constants';
import { useNavigate } from "react-router-dom";
import { checkTokenExpired } from '../service/TokenService';
import useRegenerateToken from '../components/RenegerateToken';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark, materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Code change for ChatHPE-170
import { useTheme } from '../contexts/ThemeContext';
import DefaultUserImg from '../defaultpromptimg.jpg';
import { renderToString } from 'react-dom/server'
import { convert } from 'html-to-text';
import styled, { keyframes } from 'styled-components';
// import { fetchWithRetry } from '../service/FetchWithRetry';



const blink = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
`;

const BlinkingCursor = styled.span`
    animation: ${blink} 0.8s step-end infinite;
    font-size: 20px;
    color: #000; 
`;

const StatusNotification = React.forwardRef((props, ref) => {
    var showInitials = true
    const myRef = useRef();
    const spinnerRef = useRef();
    const [getChatHistory, setChatHistory] = useState([]);
    const [open, setOpen] = React.useState();
    const [getSessionID, setCurrentSessionId] = React.useState();
    const [getMessageId, setCurrentMessageId] = React.useState();
    const [chatBotData, setChatBotData] = useState(props.chatHPE_bot_data);
    const { getNewToken } = useRegenerateToken();
    const navigate = useNavigate();
    const bottomRef = useRef();
    const [copy, setCopy] = useState(null);
    const [initials, setInitials] = useState('');
    const { theme } = useTheme();

    // const [tempChatHistory, setTempChatHistory] = useState(getChatHistory);
    // useEffect(()=>{
    //     console.log('getChatHistory: ', getChatHistory);
    //     setTempChatHistory(getChatHistory);
    // }, [getChatHistory]);

    // useEffect(() => {
    //     const first = localStorage.getItem('first');
    //     const last = localStorage.getItem('last');
    //     profileImage();
    //     setInitials(first.substring(0, 1) + last.substring(0, 1));
    // }, []);


    // useEffect(() => {
    //     const name = localStorage.getItem('name'); 
    //     if (name) {
    //         const [last, first] = name.split(',').map(part => part.trim()); 
    //         if (first && last) {
    //             const lastNameInitial = last.split(' ')[0].substring(0, 1); 
    //             const firstNameInitial = first.substring(0, 1);
    //             setInitials(firstNameInitial + lastNameInitial);
    //         }
    //     }
    //     profileImage();
    // }, []);


    // useEffect(() => {
    //     profileImage()
    // }, [initials]);

    useEffect(() => {
        props.onNotificationLoadHandler();
    });

    const scrollBottom = () => {
        bottomRef.current?.scrollIntoView({ behaviour: 'smooth' });
    };

    useImperativeHandle(ref, () => ({
        scrollBottom() {
            scrollBottom()
        }
    }));

    const style = theme === 'dark' ? a11yDark : materialLight; // Code change for ChatHPE-170
    const [copiedIndices, setCopiedIndices] = useState({});
    const customWrapStyle = { whiteSpace: 'pre-wrap', wordBreak: 'break-word' };

    useEffect(() => {
        setChatHistory(props.chatHistory);
    }, [props])

    const refreshChatHistory = (chatHist) => {
        setChatHistory(chatHist);
    };

    const getAllData = (resp) => props.sessionChatHistoryCallback(resp);

    const vote = (e, val, id) => myRef.current.onOpen(e, val, id);
    const loading = (e) => spinnerRef.current.LoadingStart(e);

    const onOpen = (sess_id, msg_id) => {
        setOpen(true);
        setCurrentSessionId(sess_id);
        // console.log("Session tied to message: ", sess_id)
        setCurrentMessageId(msg_id);
        // console.log("Message selected for voting: ", msg_id)
    };

    /*
    created under JIRA story : CHATHPE-113
    This bloock will try to fetch the image url of the user from the registered
    Azured active directory that the application is connecting to and pull the 
    image im the blob format. We are using the route provided by the graph api 
    of microsoft to get this data. In case the image is not available in the AD
    the default image will be loaded as a block and used for rendering.
    */
    // const profileImage = () => {
    //     fetch(constClass.USER_IMAGE_GRAPH_URL, {
    //         method: "GET",
    //         headers: {
    //             // "Content-Type": "image/*",
    //             "responseType": "blob",
    //             "Authorization": `${localStorage.getItem('graphToken')}`,
    //             "Connection": 'keep-alive'
    //         }
    //     }).then((resp) => {
    //         if (resp.status === 200) {
    //             resp.blob().then((imgdata) => {
    //                 showInitials = false
    //                 localStorage.setItem("promptBackUrl", URL.createObjectURL(imgdata));
    //             });

    //         } else {
    //             // console.log("Loading default Image");
    //             localStorage.setItem("promptBackUrl", DefaultUserImg);
    //         }
    //     })
    // };
    const onClose = () => setOpen(undefined);
    const deleteMessage = async (sessId, msgId) => {
        loading(true);
        if (checkTokenExpired()) {
            if (await getNewToken()) {
                callDeleteChatApi()
            }
        } else {
            callDeleteChatApi();
        }
        async function callDeleteChatApi() {
            try {
                const chat_data = JSON.parse(localStorage.getItem('chatBotData'))
                // TEMP REMOVAL OF 30 SEC TIMEOUT
                const response = await fetch(constClass.setApiUrl(localStorage.getItem('region')) + constClass.DEL_MSG, {
                    // const response = await fetch(constClass.DEL_MSG, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                        "Client-ID": constClass.CLIENT,
                        "Connection": 'keep-alive'
                    },
                    body: JSON.stringify({ sess_id: sessId, msg_id: msgId, chatHPE_bot_data: chat_data })
                });

                const newData = await response.json();

                //Added condition for Authentication to fix the uncaught runtime error. Issue 146
                if (response.status === 200) {
                    props.sessionChatHistoryCallback(newData);
                    // localStorage.removeItem('chatBotData')
                    // const chatBotDataString = JSON.stringify(newData.chatHPE_bot_data);
                    // localStorage.setItem('chatBotData', chatBotDataString);
                    loading(undefined);
                    onClose();
                }
                else {
                    navigate('/error', { state: { errorMessage: newData['message'] } });
                }
            } catch (error) {
                console.error('An error occurred:', error)
                navigate('/error')
            }
        }
    }
    // START CODE       CHATHPE-269     15/02/2024
    //Used convert from html-to-text library.
    const copyMessage = (msg, id) => {
        setCopy(id)
        const text = convert(msg);
        navigator.clipboard.writeText(text);
        setTimeout(() => {
            setCopy(null)
        }, 3000)
    }
    //End Code

    // const doRegenerate = () => {
    //     (getChatHistory.map((chat) => {
    //         if (chat.role.toLowerCase() === "user"){
    //             props.messageToRegenerate(chat.message);
    //         }
    //     }))
    // }
    return (
        <Box>
            <UpVoting ref={myRef} callbackHandler={refreshChatHistory} chatHPE_bot_data={chatBotData} getAllDataHandler={getAllData} />
            <LoadingIcon ref={spinnerRef} />
            {open && (
                <Layer
                    id="hello world"
                    position="center"
                    onClickOutside={onClose}
                    onEsc={onClose}
                    style={{ borderRadius: '16px', fontFamily: "MetricHPE-Web-Regular", fontSize: '14', color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF'), backgroundColor: (`${theme}` === 'light' ? '#FFFFFF' : '#1C1C1C') }}

                >
                    <Box pad="medium" gap="small" width="medium">
                        <Heading level={3} margin="none">
                            Delete Message
                        </Heading>
                        {/* Start of code change ChatHPE-174 2/9/2024 */}
                        <Text style={{ fontSize: '14', color: (`${theme}` === 'light' ? '#555555' : '#FFFFFF') }}>Are you sure you want to delete this message? All conversation following this message will also be deleted. This action cannot be undone.</Text>
                        {/* End of code change ChatHPE-174 2/9/2024 */}
                        <Box
                            as="footer"
                            gap="small"
                            direction="row"
                            align="center"
                            justify="end"
                            pad={{ top: 'medium', bottom: 'small' }}
                        >
                            <Button
                                className={`${theme} feedbackBoxCancelButton`} style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Bold" }}

                                label={
                                    <Text>
                                        Cancel
                                    </Text>
                                }
                                onClick={onClose}
                                // plain
                                color="status-critical"
                            />
                            <Button
                                style={{ color: (`${theme}` === 'light' ? '#FFFFFF' : '#FFFFFF'), fontFamily: "MetricHPE-Web-Bold", background: (`${theme}` === 'light' ? '#FC5A5A' : '#D04F4E'), border: 'none', fontSize: '16px' }}
                                label={
                                    <Text>
                                        Delete
                                    </Text>
                                }
                                onClick={() => deleteMessage(getSessionID, getMessageId)}
                                primary
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
            {(getChatHistory.map((chat) => {
                const msg_id = chat.messageId;
                const sess_id = chat.sessionId;
                // const message = chat.message ? chat.message : "Assistant is not able to generate response for this query.";  //this is for appid 2 , for 3 --> new message required.
                let message;
                if (localStorage.getItem('appIdValue') === '2') {
                    message = chat.message ? chat.message : "ChatHPE is not able to retrieve the user prompts";
                }
                else {
                    message = chat.message;
                }
                const display_message = message.split('```').map((section, index) => {
                    if (index % 2 === 1) {
                        // Code section
                        const language = section.split('\n')[0].trim();
                        const code = section.slice(language.length + 1);
                        return (
                            <Box key={`code-${index}`} alignContent='center' flex style={{ backgroundColor: theme === 'light' ? '#0000000A' : '#FFFFFF0F', borderRadius: '12px' }}>
                                <Box style={{ marginTop: '6px', display: 'flex', justifyContent: "space-between" }} direction='row'>
                                    <Text style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Regular", marginLeft: '12px' }}>{language}</Text>
                                    <Button
                                        style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Regular", marginRight: '12px' }}
                                        plain={true}
                                        label={copiedIndices[index] ? "Copied!" : "Copy Code"}
                                        onClick={() => {
                                            navigator.clipboard.writeText(code)
                                                .then(() => {
                                                    setCopiedIndices(prevState => ({ ...prevState, [index]: true }));
                                                    setTimeout(() => {
                                                        setCopiedIndices(prevState => ({ ...prevState, [index]: false }));
                                                    }, 1000);
                                                })
                                                .catch(err => {
                                                    console.error('Failed to copy text: ', err);
                                                    // Consider adding user feedback here
                                                });
                                        }}
                                    />
                                </Box>
                                <SyntaxHighlighter language={language} style={{ ...style, ...customWrapStyle }} wrapLongLines={true}>
                                    {code}
                                </SyntaxHighlighter>
                            </Box>
                        );
                    } else {
                        return (
                            <Markdown style={{ ...style, ...customWrapStyle }} wrapLongLines={true}>{section.trim()}</Markdown>
                        );
                    }
                });
                return (
                    <>
                        {(chat.role.toLowerCase() === "assistant") ? (
                            <Stack className='chat-history' alignSelf="start" anchor="bottom-right" key={chat.id} style={{ marginLeft: '20px', width: 'auto', marginBottom: '30px', maxWidth: '85%' }}>
                                <Box direction='row' margin={{ bottom: '10px' }}>
                                    <Button icon={<Hpe color="#01A982" />} className={`${theme} systemUserIcon`} alignSelf="start" style={{ width: '50px', height: '50px', padding: 0, borderRadius: '50%', marginRight: '10px', cursor: 'auto' }} />
                                    {/* <Button icon={<Hpe color="#ffffff" />} className={`${theme} systemUserIcon`} alignSelf="start" style={{ width: '50px', height: '200px', padding: 0, borderRadius: '50%', marginRight: '10px', cursor: 'auto' }} /> */}

                                    <Box pad="small" alignContent='start' round="small" className='systemChatBubble' wrap={true} wrapLongLines={true}>
                                        <Text style={{ wrap: true, wrapLongLines: true, fontSize: 16, fontFamily: "MetricHPE-Web-Regular" }} size='small' textAlign='start'>{display_message}</Text>
                                    </Box>

                                </Box>
                                <Box style={props.botResponse ? { pointerEvents: 'none', opacity: 0.4 } : {}} margin={{ bottom: '-25px', right: '10px' }} direction="row" gap='xsmall'>
                                    {/* <Button pad='none' onClick={() => doRegenerate()} icon={ <Refresh size='16px' />} type="button" tip={{ content: <Text size='10px' className={`${theme} tooltip`}></Text> }} /> */}

                                    {/* START CODE  CHATHPE=-441    28/02/2024 */}
                                    <Tip dropProps={{ style: { backgroundColor: (`${theme}` === 'light' ? '#F7F7F7' : '#222222'), size: 'small', borderRadius: '16px', border: 'none', overflow: 'hidden', } }} content={<Text size='10px' className={`${theme} tooltip`}>{(copy === chat.messageId) ? 'Copied' : 'Copy Message'}</Text>}>
                                        <Button style={{ 'z-index': '3' }} pad='none' id={chat.messageId} name={chat.role} icon={(copy === chat.messageId) ? <Checkmark size='15px' /> : <Copy size='15px' />} type="button" onClick={(copy === chat.messageId) ? () => { } : () => copyMessage(message, chat.messageId)} />
                                    </Tip>
                                    <Tip dropProps={{ style: { backgroundColor: (`${theme}` === 'light' ? '#F7F7F7' : '#222222'), size: 'small', borderRadius: '16px', border: 'none', overflow: 'hidden', } }} content={<Text size='10px' className={`${theme} tooltip`}>Delete Message</Text>}>
                                        <Button style={{ 'z-index': '3' }} pad='none' id={chat.messageId} name={chat.role} icon={<Trash size='15px' />} type="button" onClick={() => onOpen(sess_id, msg_id)} />
                                    </Tip>
                                    <Text style={{ fontSize: 14 }}>|</Text>
                                    <Tip dropProps={{ style: { backgroundColor: (`${theme}` === 'light' ? '#F7F7F7' : '#222222'), size: 'small', borderRadius: '16px', border: 'none', overflow: 'hidden', } }} content={<Text size='10px' className={`${theme} tooltip`}>{(chat.isUpvoted) ? 'Liked' : 'Like'}</Text>}>
                                        <Button style={{ 'z-index': '3' }} pad='none' icon={(chat.isUpvoted === 1) ? <LikeFill size='16px' /> : <Like size='16px' />} type="button" value={chat.isUpvoted} onClick={(chat.isUpvoted) ? () => { } : (e) => vote(e, 'like', chat.id)} />
                                    </Tip>
                                    <Tip dropProps={{ style: { backgroundColor: (`${theme}` === 'light' ? '#F7F7F7' : '#222222'), size: 'small', borderRadius: '16px', border: 'none', overflow: 'hidden', } }} content={<Text size='10px' className={`${theme} tooltip`}>{(chat.isDownvoted) ? 'Disliked' : 'Dislike'}</Text>}>
                                        <Button style={{ 'z-index': '3' }} pad='none' icon={(chat.isDownvoted === 1) ? <DislikeFill size='16px' /> : <Dislike size='16px' />} type="button" value={chat.isDownvoted} onClick={(chat.isDownvoted) ? () => { } : (e) => vote(e, 'dislike', chat.id)} />
                                    </Tip>
                                    {/* END CODE */}

                                </Box>
                            </Stack>
                        ) : (
                            <Stack className='chat-history' alignSelf="start" key={chat.id} style={{ marginLeft: '20px', width: 'auto', marginBottom: '10px', maxWidth: '85%' }}>
                                <Box direction='row' margin={{ bottom: '10px' }}>
                                    <Button alignSelf="start" style={{ backgroundImage: "url('" + localStorage.getItem("promptBackUrl") + "')", backgroundSize: "50px 50px", width: '50px', height: '50px', padding: '25px', borderRadius: '50%', color: '#fff', marginRight: '10px', cursor: 'auto' }}>{initials ? showInitials : ''}</Button>
                                    <Box flex='grow' pad="small" alignContent='start' round="small" className='userChatBubble' wrap={true} wrapLongLines={true}>
                                        <Text style={{ wrap: true, wrapLongLines: true, fontSize: 16, fontFamily: "MetricHPE-Web-Regular" }} size='medium' textAlign='start'>{display_message}</Text>
                                    </Box>
                                    {/* <Button alignSelf="start" style={{backgroundImage:"url('https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Everest_North_Face_toward_Base_Camp_Tibet_Luca_Galuzzi_2006.jpg/1280px-Everest_North_Face_toward_Base_Camp_Tibet_Luca_Galuzzi_2006.jpg')",backgroundSize: "50px 50px", width: '50px', height: '50px', padding: '13px', borderRadius: '50%', color: '#fff', marginLeft: '10px', cursor: 'auto' }}>{initials}</Button> */}

                                    {/* {(props.profilePic === 'Profile Picture') ? (
                                        <Button alignSelf="start" style={{backgroundImage:"url('"+localStorage.getItem("promptBackUrl")+"')",backgroundSize: "50px 50px", width: '50px', height: '50px', padding: '13px', borderRadius: '50%', color: '#fff', marginLeft: '10px', cursor: 'auto' }}>{initials?showInitials:''}</Button>

                                    ) : (
                                            <Button 
                                                alignSelf="start" 
                                                className={`${theme} userIcon`} 
                                                style={{ 
                                                    fontFamily: "MetricHPE-Web-Medium", 
                                                    fontWeight: 700,
                                                    width: '50px', 
                                                    height: '50px', 
                                                    marginLeft: '10px',
                                                    padding: '13px', 
                                                    borderRadius: '50%', 
                                                    cursor: 'auto',
                                                    backgroundColor: '#f2d8db' // added this line
                                                }}
                                            >
                                                {initials}
                                            </Button>
                                    )} */}
                                    {/* #f2d8db */}
                                </Box>
                            </Stack>
                        )}
                    </>
                )
            }))}
            {props?.botResponse ? <>
                <div className='chat-history' direction='column' style={{ marginLeft: '20px', width: 'auto', marginBottom: '10px', maxWidth: '85%' }}>
                    <Box direction='row' margin={{ bottom: '10px' }}>
                        <Button alignSelf="start" style={{ backgroundImage: "url('" + localStorage.getItem("promptBackUrl") + "')", backgroundSize: "50px 50px", width: '50px', height: '50px', padding: '25px', borderRadius: '50%', color: '#fff', marginRight: '10px', cursor: 'auto' }}>{initials ? showInitials : ''}</Button>
                        <Box pad="small" alignContent='start' round="small" className='userChatBubble' wrapLongLines={true} wrap={true}>
                            <Text style={{ wrap: true, wrapLongLines: true, fontSize: 16, fontFamily: "MetricHPE-Web-Regular" }} size='medium' textAlign='start'>{props.userInput?.text}</Text>
                        </Box>
                    </Box>

                </div>
                <div>
                    {props.streamMessage.length === 0 ? (
                        <div style={{ display: 'block' }}>
                            <Stack className='chat-history' alignSelf="start" anchor="bottom-right" style={{ marginLeft: '20px', width: 'auto', marginBottom: '30px', maxWidth: '85%' }}>
                                <Box direction='row' margin={{ bottom: '10px' }}>
                                    <Button icon={<Hpe color="#01A982" />} className={`${theme} systemUserIcon`} alignSelf="start" style={{ width: '50px', height: '50px', padding: 0, borderRadius: '50%', marginRight: '10px', cursor: 'auto' }} />
                                    <Button label="Loading" busy={true} style={{ outline: 'none', border: 'none' }} />
                                </Box>
                            </Stack>
                        </div>
                    ) : (
                        <Stack className='chat-history' alignSelf="start" anchor="bottom-right" style={{ marginLeft: '20px', width: 'auto', marginBottom: '30px', maxWidth: '85%' }}>
                            <Box direction='row' margin={{ bottom: '10px' }}>
                                <Button icon={<Hpe color="#01A982" />} className={`${theme} systemUserIcon`} alignSelf="start" style={{ width: '50px', height: '50px', padding: 0, borderRadius: '50%', marginRight: '10px', cursor: 'auto' }} />
                                <Box pad="small" alignContent='start' round="small" className='systemChatBubble' wrapLongLines={true} wrap={true}>
                                    <Text style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Regular" }} size='small' textAlign='start'><Markdown style={customWrapStyle} wrap={true} wrapLongLines={true}>{props.streamMessage}</Markdown> {props.botResponse && <BlinkingCursor style={{ fontSize: '20px', fontWeight: 'bold' }}>|</BlinkingCursor>}</Text>
                                </Box>
                            </Box>
                        </Stack>
                    )}
                </div>
            </> : ""}
            <div ref={bottomRef}></div>
        </Box>
    );
});

export default StatusNotification;