import React, { useState, useImperativeHandle, } from 'react';
import {
    Box,
    Button,
    Layer,
    Text,
    Heading
} from 'grommet';
import { Close } from 'grommet-icons';
import { useTheme } from '../contexts/ThemeContext';

const Confirmation = React.forwardRef((props, ref) => {
    const [open, setOpen] = React.useState();
    const [sessId, setSessId] = useState();
    const { theme } = useTheme();

    useImperativeHandle(ref, (val, sessionId) => ({
        onOpen(val, sessionId) {
            setOpen(val);
            setSessId(sessionId);
        }
    }))

    const onClose = () => {
        setOpen(undefined);
        props.cancelHandler();
    };
    return (
        <>
            {open && (
                <Layer
                    position="center"
                    style={{boxShadow: (`${theme}` === 'light' ? '0px 6px 12px #0000001F' : '0px 6px 12px #0000005C')}}
                    className={`${theme} confirmationPopup`
}
                >
                    <Box pad="small" gap="small" width="medium">
                        <Heading level={3} margin="none">
                            Confirm Changes
                        </Heading>
                        <Text size='16px'>Would you like to save the new session name?</Text>
                        <Box
                            as="footer"
                            gap="small"
                            direction="row"
                            align="center"
                            justify="end"
                            // pad={{ top: 'medium', bottom: 'small' }}
                        >
                            <Button
                                className={`${theme} feedbackBoxCancelButton`}
                                style={{ fontSize: 16, fontFamily: "MetricHPE-Web-Bold" }}
                                label="Revert Changes"
                                onClick={onClose}
                            />
                            <Button
                                disabled={props.newVal.length===0}
                                style={{ background: '#01A982', border: 'none', fontSize: 16, fontFamily: "MetricHPE-Web-Bold", color: '#fff' }}
                                label={
                                    <Text color="white">
                                        <strong>Update</strong>
                                    </Text>
                                }
                                onClick={() => {
                                    props.saveHandler(sessId);
                                    onClose();
                                }}
                                primary

                            />
                        </Box>
                    </Box>
                </Layer>
            )}
        </>
    );
});

export default Confirmation;