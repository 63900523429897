import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { Box, Button, Grommet, Heading, Text } from "grommet";
import { Hpe } from "grommet-icons";
import { useNavigate } from "react-router-dom";

export const LoginHelper = (props) => {
    
    const { instance } = useMsal();
    const navigate = useNavigate();

    const handleLogin = () => {
        localStorage.removeItem('activeItem');
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }

    useEffect(() => {
        handleLogin();
    }, []);

    const handleLogOut = () => {
        navigate('/')
    }

    const btnName = (props.name === 'signIn');

    return (
        <>
        </>
    );
}